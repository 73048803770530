// dmedia mvp - copyright (c) 2021 ossip kaehr / algorithmic creations llc


import axios from 'axios'
import _ from 'lodash';
import Vue from 'vue'

var Emitter = require('tiny-emitter');

const ApiURL = process.env.VUE_APP_API_URL

const ChainNetwork = process.env.VUE_APP_CHAIN_NETWORK || 'telos_main'
const VarexContract = process.env.VUE_APP_VAREX_CONTRACT
const OreIdPermission = process.env.VUE_APP_OREID_PERMISSION
const CaptchaKey = process.env.VUE_APP_CAPTCHA_KEY
const WithdrawAddress = process.env.VUE_APP_WITHDRAW_ADDRESS
const WithdrawSymbol = process.env.VUE_APP_WITHDRAW_SYMBOL

const api = axios.create({ baseURL: ApiURL })
console.log('API_URL', ApiURL, process.env)

import { OreId } from 'oreid-js'
import { WebPopup } from 'oreid-webpopup'

const oreId = new OreId({
    appId: process.env.VUE_APP_OREID_APPID,
    oreIdUrl: process.env.VUE_APP_OREID_URL,
    plugins: { popup: WebPopup() }
})
oreId.init()


var state = new Vue({
    data: {
        user: {username: '', email: '', skillLevel: 1, notify: []},
        accessToken: null,
        featured: [],  // learn[] order:0 is public info
        learn: [],
        earn: [],
        info: {},
        rounds: [], entries: [], winners: [],
        token: null,
        showAdmin: false,
        loading: false,
        ii: 0,
    }
})
// var msg = new Vue({})
var msg = new Emitter();

async function signTx(mode, tx) {
    console.log('sign..', mode, state.user.accountName, tx, JSON.stringify(tx,null,2))

    if (!tx || !tx.actions || tx.actions.length == 0) {
        console.log("sign..empty tx")
        return "already joined"
    }

    console.log('auth?', oreId.auth.accessToken, oreId.auth.isLoggedIn, state.user.provider)
    if (! oreId.auth.isLoggedIn && state.user.type != 'custodial') {
        console.log("not logged in?", oreId.auth.isLoggedIn, state.user.type)
        await oreStartLogin(state.user.provider) // provider?
    }

    // ping server to see if needs to refill ram. (other resources are taken care of? TBD)
    await checkResources(state.user.accountName, mode)

    // let u1 = await oreId.getUser()
    // console.log('** getUser', u1)

    let d = await oreId.auth.user.getData()
    console.log('** getData', d)

    let txRes = await oreId.createTransaction({
        account: state.user.accountName,
        chainAccount: state.user.accountName,
        transaction: tx,
        chainNetwork: ChainNetwork,
        signOptions: {
            broadcast: true,
            // returnSignedTransaction: false,
            // preventAutosign: true,
        }
    });
    console.log('create tx', txRes)

    let signRes = await oreId.popup.sign({ transaction: txRes })
        .then(r => { console.log("sign", r); return r })
        .catch(err => { console.log('sign-err', err); return err })
    console.log('signed', signRes)

    if (signRes && signRes.transactionId) {
        setLoading(true)
        return api.get(`tx-signed/${signRes.transactionId}/${mode}`, { headers:{ token: state.token }})
            .then(res2 => {
                setLoading(false)
                return signRes
            })
            .catch(err => {
                console.log('tx-signed err', signRes.transactionId, mode, ':', err)
                setLoading(false)
                return signRes
            })
    }
    else {
        const data = _.cloneDeep({
            tx, //: JSON.stringify(tx),
            txRes: txRes.data,
            signRes: signRes.toString(),
            accountName:state.user.accountName,
            getData: d,
        })
        // const data = JSON.stringify(data1, null, 2)
        console.log("log data", data)
        api.post(`tx-error`, { data }, { headers:{ token: state.token }})
            .then(res2 => res2)
            .catch(err2 => console.log('tx-err send-err', err2))
    }

        // alert('error:' + signRes)
    console.log('err?', signRes, typeof signRes)
    if (signRes) {
        if (signRes.toString().match(/sign_transaction_cancelled_by_user/)) return ""

        let m = signRes.toString().match(/"assertion failure with message:(.*?)"/)
        if (m && m[1]) return m[1]

        m = signRes.toString().match(/error_message=(.*?)\[/)
        return m && m[1] ? m[1] : signRes
    }

    return signRes

    // let signUrl = await txRes.getSignUrl()
    // console.log('sign url', signUrl)
    // window.location = signUrl
}

function setLoading(i) {
    state.loading = i
    state.ii += 1
    console.log('loading', i, state.ii)
    msg.emit('loading', i)
}

const isAdmin = () => state.user && state.user.role === 'admin'
const isMentor = () => state.user && state.user.role === 'mentor'
const isVerifier = () => state.user && !!state.user.verifier

function loginSuccess(user, token) {
    state.user = user

    if (user && token) {
        state.token = token
        if (localStorage && state.token) localStorage.t = state.token
    }
    calcBalanceTotals(state.user)
    loadFeatures()
    console.log('emit user', state.user)

    msg.emit('user', state.user)
}

function login(username, pin, captcha) {
    setLoading(true)
    return api.post('login', {username, pin, captcha})
        .then(res => {
            setLoading(false)
            console.log('login res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                loginSuccess(res.data.user, res.data.token)
                return res.data
            }
            return { status:'error', error: res.data ? res.data.error : 'error' }
        })
        .catch(e => setLoading(false))
}

function loginPhone(phone, code, captcha) {
    return api.post('login-phone', {phone, code, captcha})
        .then(res => {
            console.log('login-phone res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                // state.user = res.data.user

                if (res.data.success && code) {
                    window.location = ApiURL + '/ore_login/phone?phone=' + encodeURIComponent(phone) + '&code='+code
                }
                // if (res.data.user && res.data.token) {
                //     state.token = res.data.token
                //     if (localStorage && state.token) localStorage.t = state.token
                // }
                // console.log('emit user', state.user)
                // msg.emit('user', state.user)
                return res.data
            }
            return { status:'error', error: res.data ? res.data.error : 'error' }
        })
}


async function pinReset() {
    let u = state.user
    console.log('pinReset', u)
    if (!u || !u.authProvider || (!u.idToken && !u.accessToken && !state.accessToken)) return

    let r = await oreId.popup.auth({ provider: u.authProvider, idToken: u.idToken,
        accessToken: u.accessToken || state.accessToken })
    console.log("ore-auth got", r && r.account, r, u)

    if (r && r.account) {
        state.loading = true
        return api.get('account-pin-reset/' + r.account, {headers: {token: state.token}})
            .then(res => {
                state.loading = false
                if (res && res.data && res.data.status == 'ok') return true
            })
            .catch(e => state.loading = false)
    }
}

async function glogin(response, code) {
    console.log('glogin token', response.credential, code)
    setLoading(true)

    if (! state.info || ! state.info.rates) await introContent()

    // on server -
    // oreId.auth.newUserWithToken({ idToken: response.credential }).then(console.log).catch(console.log)
    // oreId.auth.loginWithToken({ idToken: response.credential }).then(console.log).catch(console.log)

    // login or new user - in new user case sets next to /ore-register/<accountName>/
    return api.post('glogin/google', response)
        .then(async res => {
            setLoading(false)
            console.log('glogin res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                if (res.data.auth && res.data.auth.accessToken) {
                    res.data.user.accessToken = res.data.auth.accessToken
                    state.accessToken = res.data.auth.accessToken

                    await oreId.init()
                    oreId.auth.setAuthResult(res.data.auth)
                    await oreId.auth.user.getData()
                        .then(r => console.log('get data', res.data.auth, r))
                }
                if (res.data.user) {
                    loginSuccess(res.data.user, res.data.token)
                    if (code && res.data.user.status) return addMentorSkill(code)
                }
                return res.data
            }
            return { status:'error', error: res.data ? res.data.error : 'error' }
        })
        .catch(e => { console.log('glogin err', e); setLoading(false) })
}


    // TODO
    // -- call method to add code and possibly do existing user autoEnroll:
    //   credit tokens and send to frontend auto-enroll page
    // check specific gw autoenroll mode. q: just "code" parameter or also encrypted param..?

    // if (d.user && _.find(d.user.autoEnroll, r => r.autoEnrollExisting)) {


function loadUser(token, show) {
    state.token = token
    console.log('reload?', token, localStorage.t)
    if (!token) return setLoading(false)
    if (show) setLoading(true)

    return api.get('user-info', { headers:{ token: token }})
        .then(res => {
            setLoading(false)
            console.log('user-info res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok' && res.data.user) {
                // if (res.data.user.status === 0) return console.log('invalid user', res.data.user)

                loginSuccess(res.data.user, res.data.token)

                if (state.user.notify && state.user.notify.length)
                    notifyUser(state.user.notify)

                return state.user
            } else if (res.data && res.data.error == 'invalid token') {
                console.log('*invalid token')
                localStorage.removeItem('t')
                state.token = null
                msg.emit('logout')
            }
        })
        .catch(e => setLoading(false))
}

async function reloadUser(f) {
    // if (state.token && localStorage && ! localStorage.t) localStorage.t = state.token  // here?

    if (!oreId.auth.isLoggedIn) {
        let d = await oreId.auth.user.getData()
            .catch(e => console.log("error with oreId getData", e))
        console.log('** getData', d)
    }

    if (!f && state.token && state.user && state.user.username) {
        console.log('have user.', state.user.username)
        return true
    }

    return loadUser(state.token || localStorage.t, false)
}

function loadFeatures() {
    if (state.user && (state.user.features == 'beta' || state.user.features == 'wallet')) {
        state.showWallet = true
        store.config.showLanguageToggle = true
    }
}

// lookup if account exists - for transfers
function checkAccount(a) {
    state.loading = true
    return api.get('check-account/' + a, {headers: {token: state.token}})
        .then(res => {
            state.loading = false
            // console.log('r', res)
            if (res && res.data && res.data.status == 'ok') return true
        })
        .catch(e => setLoading(false))
}

function getCodeKey(code) {
    state.loading = true
    return api.get('code-checksum/' + code, {headers: {token: state.token}})
        .then(res => {
            state.loading = false
            return res.data
        })
        .catch(e => setLoading(false))
}


// lookup if account exists - for transfers
function checkResources(a, mode) {
    state.loading = true
    console.log('checkResources..', a, mode)
    return api.get(`check-resources/${a}/${mode}`, {headers: {token: state.token}})
        .then(res => {
            state.loading = false
            if (res && res.data && res.data.status == 'ok') return true
        })
        .catch(e => setLoading(false))
}

function accountHistory(a, pos, off, spin) {
    if (a == '' || a == 'undefined') return
    if (spin) state.loading = true
    return api.get(`account-history/${a}/${pos || 0}/${off || 20}`, {headers: {token: state.token}})
        .then(res => {
            state.loading = false
            // console.log('r', pos,off, res)
            if (res && res.data && res.data.status == 'ok') {
                state.info.rates = res.data.rates

                return res.data
            }
        })
        .catch(e => setLoading(false))
}

function notifyUser(n) {
    msg.emit('notify', n)
}

function notifyClosed(id) {
    if (id) return api.get(`notify-closed/${state.user.id}/${id}`, { headers:{ token: state.token }})
}

function logout() {
    return api.delete('logout', { headers:{token: state.token }})
        .then(res => {
            console.log('logout res', res.status)
            state.token = ''
            state.user = {}
            state.accessToken = null
            localStorage.removeItem('t')
            msg.emit('logout')
            // msg.emit('user', null)
            oreId.auth.logout()
            oreId.logout()

            if (google && google.accounts && google.accounts.id)
                google.accounts.id.disableAutoSelect()

            return { status: res.statusText }
        })
}

function register(user) {
    return api.post('register', user )
        .then(res => {
            console.log('register res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {

                // (we don't get this here)
                if (res.data.token) state.token = res.data.token
                if (localStorage && state.token) localStorage.t = state.token

                state.user = res.data.user
                msg.emit('user', state.user)
                return res.data
            }
            return { status:'error', error:res.data? res.data.error || 'error' : 'error' }
        })
}

function oreRegister(user) {
    return api.post('ore-register', user , { headers:{token: state.token }})
        .then(async res => {
            console.log('ore-register res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                // (we don't get this here)
                if (res.data.token) state.token = res.data.token
                if (localStorage && state.token) localStorage.t = state.token

                state.user = res.data.user
                calcBalanceTotals(state.user)

                console.log('emit user', state.user)
                msg.emit('user', state.user)

                // if (state.user.type == 'custodial') await pinReset()
                if (state.user.type == 'custodial') res.data.next = 'pin-reset'

                return res.data
            }
            return { status:'error', error:res.data? res.data.error || 'error' : 'error' }
        })
}


function profile(user) {
    return api.put('profile/' + user.id, user, { headers:{token: state.token }} )
        .then(res => {
            console.log('profile res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:res.data? res.data.error || 'error' : 'error' }
        })
}

function verifyCode(code) {
    return api.post('verify-phone', {id: state.user.id, phone: state.user.phone, code}, {})
        .then(res => {
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                state.token = res.data.token
                if (res.data.user) state.user = res.data.user
                calcBalanceTotals(state.user)

                console.log('verify ok', res.data.user)
                if (localStorage && state.token) localStorage.t = state.token
                msg.emit('user', state.user)
                return true
            }
            // else return res.data ? res.data.error : 'error'
        })
}
function resendCode() {
    return api.put('resend-code', {id: state.user.id, phone: state.user.phone}, { headers:{token: state.token }})
        .then(res => {
            console.log('resend-code res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:res.data? res.data.error || 'error' : 'error' }
        })
}

// ------- content -------

function _contentResponse(res) {

    // state.learn = all ? res.data.learn : _.filter(res.data.learn, l => l.order > 0)
    state.learn = _.filter(res.data.learn, l => l.order > 0)
    state.featured = _.filter(res.data.learn, {order: 0})
    state.earn = res.data.earn

    state.info = res.data.info
    initGroups()
    state.taskCodes = res.data.taskCodes

    state.rounds = res.data.rounds
    // state.entries = res.data.entries
    state.winners = res.data.winners
    // _.each(state.rounds, r => r.entries = _.filter(state.entries, {round_id: r.round_id}))
    // userRounds()

    state.next = res.data.next
    state.autoEnroll = res.data.autoEnroll

    console.log('content:', state.user ? state.user.accountName : '-', state)

    return state
}
function contentResponse(res) {
    setLoading(false)
    console.log('content res', res.status, res.data)
    if (res.status === 200 && res.data && res.data.status === 'ok') {
        // if (res.data.tokenStatus) return msg.emit('logout')
        // throw { status:'error' }

        return _contentResponse(res)
    }
    return msg.emit('logout')

}

function content(all, skill, reload) {
    console.log('content..', all||'', skill||'', reload||'')

    if (!reload && state.earn && state.earn.length && state.rounds && state.rounds.length)
        return new Promise((res,rej) => res(state));

    setLoading(true)
    return api.get('content' +
        (isAdmin() ? '?' + (all ? 'all=1&' : '') + (skill ? 'skill='+skill : '') : ''),
        { headers:{ token: state.token || null }})
        .then(contentResponse)
        .catch(e => setLoading(false))
}

function loadVarexData(reload, show) {
    // console.log('varex data..', reload)
    if (!state.token) return console.log('varex-data - no token')
    if (show) setLoading(true)
    return api.get('varex-data/' + reload,
        { headers:{ token: state.token || null }})
        .then(res => {
            setLoading(false)
            console.log('varex data', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                state.rounds = res.data.rounds
                // state.entries = res.data.entries
                state.winners = res.data.winners
                // _.each(state.rounds, r => r.entries = _.filter(state.entries, {round_id: r.round_id}))
                // userRounds()

                state.user.balances = res.data.balances
                state.user.varexBalances = res.data.varexBalances
                calcBalanceTotals()

                console.log('varex content:', state.user ? state.user.accountName : '-', state.rounds)
                msg.emit('user', state.user)

                if (res.data.notify && res.data.notify.length)
                    msg.emit('notify', res.data.notify)

                return state
            }
        })
        .catch(e => setLoading(false))
}

function calcBalanceTotals() {
    let t = {}, liquid={}, user=state.user, usd=0
    _.each(user.balances, b => liquid[b.symbol] = t[b.symbol] = b.balance)
    _.each(user.varexBalances, v => {
        let b1 = parseBalance(v.liquid_balance)
        let b2 = parseBalance(v.staked_balance)
        t[b1.symbol] = (t[b1.symbol]||0) + b1.balance + b2.balance
        liquid[b1.symbol] = (liquid[b1.symbol]||0) + b1.balance
    })
    const getUSDRate = k => {
        let b = _.find(user.balances, {symbol: k})
        return b ? b.usdRate :
            (state.info && state.info.rates ? getRate(k, 'USDT').rate : 0)
    }
    user.balanceTotals = _.map(t, (v,k) => ({symbol:k, balance:v }))
    user.liquidBalance = _.map(liquid, (v,k) =>
        ({symbol:k, balance:v, usdRate: getUSDRate(k) }))

    let pusdt = _.find(user.liquidBalance, {symbol: 'PUSDT'})
    let usdt = _.find(user.liquidBalance, {symbol: 'USDT'})
    // for now let's treat them 1:1 and just add.
    user.liquidUSDT = (pusdt ? pusdt.balance ||0 : 0) + (usdt ? usdt.balance||0 : 0)

    _.each(user.balances, b => usd += (liquid[b.symbol] * b.usdRate) || 0)
    user.liquidUSD = usd

    console.log('calc', user)
}


function introContent(admin, code, skill) {
    console.log('intro content..')
    setLoading(true)
    return api.get(`intro-content?code=${code || ''}&skillLevel=${skill || ''}`, { headers:{ token: state.token || null }})
        .then(res => {
            setLoading(false)
            console.log('intro-content res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok' && res.data.featured) {
                state.featured = admin ? res.data.featured : _.filter(res.data.featured, { status:1})
                state.info = res.data.info
                initGroups()
                return state
            }
            return { status:'error', error:'error' }
        })
        .catch(e => setLoading(false))
}
function userLearnStatus(ls) {
    console.log('ls', ls)
    return api.post('user-status', { learnStatus: ls }, { headers:{ token: state.token }})
}
function userEarnStatus(ls) {  // obsolete
    console.log('es', ls)
    return api.post('user-status', { earnStatus: ls }, { headers:{ token: state.token }})
}

function userJoinStatus(code, join) {
    console.log('join - earn', code, join)
    return api.put(`join-status/${code}/${join}`, {}, { headers:{ token: state.token }})
}

async function withdrawal(amount) {
    console.log('withdrawal', amount)
    state.user.withdrawal = amount

    let amt = parseBalance(amount)
    let accountName = state.user.accountName
    const WithdrawAddr = process.env['VUE_APP_WITHDRAW_ADDRESS_' + state.user.country ] || WithdrawAddress

    console.log('withdraw', accountName, state.user.country, amount, state.user , WithdrawAddr, WithdrawAddress, amt.balance)
    if (!accountName || !WithdrawAddr || !amt.balance) return console.log('withdrawal - missing data')

    let t = _.find(store.state.info.tokens, {code: amt.symbol || WithdrawSymbol })

    let have = userBalanceGet(amt.symbol) || {balance:0}
    let need = have.balance < amt.balance ? amt.balance - have.balance : 0
    console.log('withdrawal', accountName, WithdrawAddr, amt, '/', have, need, t.digits)

    let actions = []
    if (need > 0) {
        actions.push({
            account: VarexContract,
            name: 'withdraw',
            data: {
                from: state.user.accountName,
                quantity: need.toFixed(t.digits) + ' ' + amt.symbol,
            },
            authorization: [{
                actor: state.user.accountName,
                permission: OreIdPermission,
            }],
        })
    }

    actions.push({
        account: t.contract,
        name: 'transfer',
        data: {
            from: state.user.accountName,
            to: WithdrawAddr,
            quantity: amt.balance.toFixed(t.digits) + ' ' + amt.symbol,
            memo: 'Withdrawal'
        },
        authorization: [{
            actor: state.user.accountName,
            permission: OreIdPermission,
        }],
    })

    api.put('withdrawal/'+state.user.id, {
        action:'withdraw', accountName: state.user.accountName,
        withdrawal:amount, amount,  }, { headers:{ token: state.token }})

    return await signTx('withdrawal', { actions })
}

function _withdrawAction(amount, t, dest) {
    return {
        account: t.contract,
        name: 'transfer',
        data: {
            from: state.user.accountName,
            to: dest,
            quantity: amtToToken(amount, t),
            memo: 'Withdrawal'
        },
        authorization: [{
            actor: state.user.accountName,
            permission: OreIdPermission,
        }],
    }
}
async function withdrawalUSD(amount) {
    console.log('withdrawalUSD', amount)
    let accountName = state.user.accountName
    const WithdrawAddr = process.env['VUE_APP_WITHDRAW_ADDRESS_' + state.user.country ] || WithdrawAddress

    if (!accountName || !WithdrawAddr || !amount) return console.log('withdrawal - missing data')

    state.user.withdrawal = amount

    let usdt = (_.find(state.user.liquidBalance, b => b.symbol === 'USDT') || { balance: 0 }).balance
    let pusdt = (_.find(state.user.liquidBalance, b => b.symbol === 'PUSDT') || { balance:0 }).balance

    // let usd = (usdt ? usdt.balance : 0) + (pusdt ? pusdt.balance : 0)
    if (pusdt && pusdt > amount) pusdt = amount
    if (usdt) usdt = amount - pusdt

    let t_pusdt = _.find(store.state.info.tokens, {code: 'PUSDT' })
    let t_usdt = _.find(store.state.info.tokens, {code: 'USDT' })

    let actions = withdrawIfNeeded(amtToToken(pusdt, t_pusdt), t_pusdt)
        .concat(withdrawIfNeeded(amtToToken(usdt, t_usdt), t_usdt))

    if (pusdt) actions.push(_withdrawAction(pusdt, t_pusdt, WithdrawAddr))
    if (usdt) actions.push(_withdrawAction(usdt, t_usdt, WithdrawAddr))

    console.log('withdraw', amount, pusdt, usdt, amtToToken(pusdt, t_pusdt), amtToToken(usdt, t_usdt), actions)

    api.put('withdrawal/'+state.user.id, {
        action:'withdraw', accountName: state.user.accountName,
        withdrawal:amount, amount,  }, { headers:{ token: state.token }})

    return await signTx('withdrawal', { actions })
}

function stake(code, amount, bonus) {
    console.log('stake', code, amount, bonus)
    if (!state.user.stakes) Vue.set(state.user, 'stakes', {})
    state.user.stakes[code] = amount
    return api.put('stake/'+state.user.id, { code, amount, bonus }, { headers:{ token: state.token }})
}

// doesn't make sense, they have mult tokens
// function getBalance() {
//     return _.sumBy(state.user.balances, 'balance')
// }

function getVarexBalances() {
    return _.map(state.user.varexBalances, 'liquid_balance')
}

function parseBalance(b) {
    if (b && typeof b === 'object' && b.symbol) return b // already parsed

    let s = b && b.split(' ')
    return s && s[1] ? {balance: parseFloat(s[0]), symbol: s[1], s:b} : { s:b }
}

function getSymbol(c) {
    let t = _.find(store.state.info.tokens, {code: c || 'PUSDT' })
    return t ? t.symbol : c
}
function getAmountCodeAdd(b, txt, txt2) {  // pass amount, return code if != symbol
    let bal = parseBalance(b)
    let code = b && typeof b == 'string' ? b : bal && bal.symbol
    if (code) {
        let t = _.find(store.state.info.tokens, {code: code})
        if (t && t.symbol != t.code) return (txt || '') + t.code + (txt2 || '')
    }
    return ''
}

function fmtAmount(amt, symbol, html, symbOnly) {
    if (amt === undefined) return '-'
    let t = _.find(store.state.info.tokens, {code: symbol || 'PUSDT' }) || {}
    let fbal = parseFloat(amt || 0).toLocaleString('en-US',
        { minimumFractionDigits: 0, maximumFractionDigits: t.displayDigits || t.digits })

    // add more digits to show small amounts.
    if (fbal == "0" && amt > 0)
        fbal = parseFloat(amt || 0).toLocaleString('en-US',
            { minimumFractionDigits: 0, maximumFractionDigits: t.digits })

    let s = getSymbol(symbol)
    if (html) s = `<span style="font-size:smaller">${s}</span>`

    if (symbOnly && s == symbol) return fbal
    return t.before ? s + fbal : fbal + ' ' + s
}

function fmtBalance(b, zeroDash, html) {
    // let bal = typeof b === 'object' && b.symbol ? b : parseBalance(b)
    let bal = parseBalance(b)

    if (bal && bal.symbol) {
        if (zeroDash && parseFloat(bal.balance.toFixed(6) == 0)) return '-'

        return fmtAmount(bal.balance, bal.symbol, html)

        // let t = _.find(store.state.info.tokens, {code: bal.symbol || 'PUSDT' }) || {}
        //
        // let fbal = bal.balance.toLocaleString('en-US',
        //     { minimumFractionDigits: 0, maximumFractionDigits: t.displayDigits || 2 })
        //
        // // if (showAsUSD(bal)) return '$' + fbal // consolidate?
        // // if (CurrencySymbols[bal.symbol]) return CurrencySymbols[bal.symbol] + fbal
        //
        // // show '-' if all 0
        // if (zeroDash && parseFloat(bal.balance.toFixed(t.digits || 6) == 0))
        //     return '-'
        //
        // let s = getSymbol(bal.symbol)
        // if (html) s = '<span style="font-size:smaller">' + s + '</span>'
        //
        // return t.after ? fbal + ' ' + s : s + fbal
    }
    return b
}
function fmtBalances(list) {
    return _.map(list, i => fmtBalance(i)).join(' · ')
}

function checkUserBalance(test){
    let t = parseBalance(test)
    let f = userBalanceGet(t.symbol)
    console.log('checkUserBal', t.balance, t.symbol, ':', f, f && t ? f.balance >= t.balance : false)
    return f && t ? f.balance >= t.balance : false
}
function userBalanceGet(s){
    if (state.user && state.user.balances)
        return _.find(state.user.balances, b => b.symbol === s)
}
function varexBalanceGet(t) {
    let f = _.find(state.user.varexBalances, b => parseBalance(b.liquid_balance).symbol === t.symbol)
    if (f) return parseBalance(f.liquid_balance)
}
function checkVarexBalance(test){
    let t = parseBalance(test)
    let f = varexBalanceGet(t)
    // let f = _.find(state.user.varexBalances, b => parseBalance(b.liquid_balance).symbol === t.symbol)
    // console.log('checkVarexBalance', test, ':', f, f && t ? parseBalance(f.liquid_balance).balance >= t.balance : false)
    return f && t ? f.balance >= t.balance : false
}
function checkLiquidBalance(test) {
    // return true

    let t = parseBalance(test)
    let f = _.find(state.user.liquidBalance, b => b.symbol === t.symbol)
    console.log('check liquid', t.balance, f)
    return f && t ? f.balance >= t.balance : false
}

function checkUSDBalance(test) {
    let t = parseBalance(test)
    let f1 = _.find(state.user.liquidBalance, b => b.symbol === 'USDT')
    let f2 = _.find(state.user.liquidBalance, b => b.symbol === 'PUSDT')
    console.log('check liquid', t.balance, f1, f2)
    let usd = (f1 ? f1.balance : 0) + (f2 ? f2.balance : 0)
    return usd >= t.balance
}

function nonZero(test) {
    return test && !test.match(/^0\.0+ \w+/)
}

// withdraws 'need' from Varex. NOTE: does not check varex balance, assumes avail!
function withdrawIfNeeded(amount, token) {
    console.log('tx', state.user.accountName, amount)
    let amt = parseBalance(amount)
    let have = userBalanceGet(amt.symbol) || {balance:0}
    let need = have.balance < amt.balance ? amt.balance - have.balance : 0
    console.log('withdraw?', amount, amt, '/', have.balance, have, need, token.digits)

    if (need > 0) {
        return [{
            account: VarexContract,
            name: 'withdraw',
            data: {
                from: state.user.accountName,
                quantity: need.toFixed(token.digits) + ' ' + amt.symbol,
            },
            authorization: [{
                actor: state.user.accountName,
                permission: OreIdPermission,
            }],
        }]
    }
    return []
}

async function transfer(recipient, amount, token, memo) {
    // let amt = amount.toFixed(4) // already fmt
    console.log('tx', state.user.accountName, amount)
    let amt = parseBalance(amount)
    let have = userBalanceGet(amt.symbol) || {balance:0}
    let need = have.balance < amt.balance ? amt.balance - have.balance : 0
    console.log('transfer', recipient, amount, amt, '/', have, need, token.digits)

    let actions = withdrawIfNeeded(amount, token)

    actions.push({
        account: token.contract,
        name: 'transfer',
        data: {
            from: state.user.accountName,
            to: recipient,
            quantity: amount,
            memo: memo || ''
        },
        authorization: [{
            actor: state.user.accountName,
            permission: OreIdPermission,
        }],
    })

    api.put('transfer/'+state.user.id, { recipient, amount, memo }, { headers:{ token: state.token }})

    return await signTx('transfer', { actions })

    // return api.put('transfer/'+state.user.id, { recipient, amount, memo }, { headers:{ token: state.token }})
}

const mkToken = () => Math.random().toString(36).substr(2)

// we first send amount to our wallet and then convert via ptokens server-side.
async function sendBTC(recipient, amount, token) {
    console.log('sendBTC', state.user.accountName, amount, recipient)
    let amt = parseBalance(amount)
    let have = userBalanceGet(amt.symbol) || {balance:0}
    let need = have.balance < amt.balance ? amt.balance - have.balance : 0

    // use this as txId to coordinate with backend. in URL and also in memo
    let txId = state.user.accountName + ':' + recipient + ':' + amt.balance + ':' + mkToken()
    console.log('sendBTC..', recipient, amount, amt, '/', have, need, txId)

    let BTCrelay = process.env.VUE_APP_BTC_RELAY
    let actions = withdrawIfNeeded(amount, token)

    actions.push({
        account: token.contract,
        name: 'transfer',
        data: {
            from: state.user.accountName,
            to: BTCrelay,
            quantity: amount,
            memo: 'sendBTC:' + txId
        },
        authorization: [{
            actor: state.user.accountName,
            permission: OreIdPermission,
        }],
    })

    await api.put('send-btc-start/'+state.user.id, {
        action: 'send-btc',
        accountName: state.user.accountName, recipient, amount, txId },
        { headers:{ token: state.token }})

    return await signTx('send-btc/'+ txId, { actions })
    // return 1
}

function getRateVia(from, to, via) {
    let rate = state.info.rates[ from +':'+ via] * state.info.rates[ via +':' + to ]
    let path = state.info.swaps[ from +':'+ via] + '-' + state.info.swaps[ via + ':' + to ]
    return { path, rate }
}
function getRate(from, to) {
    if (from == to) return { path: '', rate: 1 }

    let path = state.info.swaps[ from +':'+ to]
    let rate = state.info.rates[ from +':'+ to ]
    if (!rate) {
        ({path, rate} = getRateVia(from, to, 'TLOS'))
    }
    if (!rate) {
        ({path, rate} = getRateVia(from, to, 'USDT'))
    }
    if (!rate) console.log('no swap?', from, to)

    console.log('getRate', from, to, ':', path, rate)

    return { path, rate }
}

const amtToToken = (amount, token) => amount.toFixed(token.digits) + ' ' + token.code

async function convert(amount, token, toToken) {
    console.log('convert', amount, token, toToken)
    let amt = amtToToken(amount, token) // amount.toFixed(token.digits) + ' ' + token.code

    // let id = state.info.swaps[ token.code +':'+ toToken.code]
    // let rate = state.info.rates[ token.code +':'+ toToken.code ]
    let { path, rate } = getRate(token.code, toToken.code)
    if (!path || !rate) return

    let expect = amount * rate
    let minOut = expect * (1 - store.config.MaxSlippage)
    let minOutFmt = Math.trunc(minOut.toFixed(toToken.digits) * 10 ** toToken.digits)

    let swap = `swap,${minOutFmt},${path}`
    console.log('swap', state.user.accountName, amount, token.code, toToken.code, store.config.MaxSlippage, expect, minOut, ':', swap)

    let actions = withdrawIfNeeded(amt, token)

    actions.push({
        account: token.contract,
        name: 'transfer',
        data: {
            from: state.user.accountName,
            to: 'amm.swaps',
            quantity: amt,
            memo: swap
        },
        authorization: [{
            actor: state.user.accountName,
            permission: OreIdPermission,
        }],
    })

    await api.put('convert/'+state.user.id, {
            action: 'convert',
            accountName: state.user.accountName, fromToken:token.code, amount, toToken:toToken.code,
            swap },
        { headers:{ token: state.token }})

    return await signTx('convert', { actions })
    // return 1
}

async function withdrawStake(amount) {
    console.log('withdraw', amount)

    console.log('tx w', state.user.accountName, amount)
    return await signTx('withdraw', {
        actions: [{
            account: VarexContract,
            name: 'withdraw',
            data: {
                from: state.user.accountName,
                quantity: amount,
            },
            authorization: [{
                actor: state.user.accountName,
                permission: OreIdPermission,
            }],
        }]
    })

    // return api.put('transfer/'+state.user.id, { amount, mode:'withdraw' }, { headers:{ token: state.token }})
}
function balString(b) {
    return b.balance.toFixed(4) + ' ' + b.symbol
}
async function withdrawStakeAll() {
    console.log('withdraw all')

    let todo = _.map(state.user.varexBalances, b => {
        let bal = parseBalance(b.liquid_balance)
        return {
            account: VarexContract,
            name: 'withdraw',
            data: {
                from: state.user.accountName,
                quantity: balString(bal),
            },
            authorization: [{
                actor: state.user.accountName,
                permission: OreIdPermission,
            }],
        }
    })
    api.put('transfer/'+state.user.id, { amount:0, mode:'withdraw' }, { headers:{ token: state.token }})

    return await signTx('withdraw', { actions: todo })
}

async function stakeVarexMulti(ids, amounts, pg) {
    let accountName = state.user.accountName
    console.log('stake varex', ids, accountName, amounts)
    if (!state.user.stakes) Vue.set(state.user, 'stakes', {})

    let actions = []
    for (let i in ids) {
        let id = ids[i]
        let amount = amounts[i]

        state.user.stakes[id] = amount
        let amt = parseBalance(amount)
        let token = _.find(store.state.info.tokens, {code: amt.symbol})

        let have = varexBalanceGet(amt) || {balance: 0}
        let need = have.balance < amt.balance ? amt.balance - have.balance : 0
        let depositAmount = need.toFixed(token.digits) + ' ' + amt.symbol
        console.log('stake', accountName, amt, have, need, depositAmount)

        if (need > 0)
            actions.push({
                account: token.contract,
                name: 'transfer',
                data: {
                    from: accountName,
                    to: VarexContract,
                    quantity: depositAmount,
                    memo: 'add balance'
                },
                authorization: [{
                    actor: accountName,
                    permission: OreIdPermission,
                }],
            })

        actions.push({
            account: VarexContract,
            name: 'enterround',
            data: {
                round_id: id,
                participant: accountName
            },
            authorization: [{
                actor: accountName,
                permission: OreIdPermission,
            }],
        })
    }
    api.put('stake/'+state.user.id, { id: ids[0], amount: amounts[0] }, { headers:{ token: state.token }})

    return await signTx((pg || 'stake') + '/' + ids.join(','),{ actions })

    // TODO pass multiple
    // return api.put('stake/'+state.user.id, { id: ids[0], amount: amounts[0] }, { headers:{ token: state.token }})
}
async function stakeVarex(id, amount, pg) {
    return await stakeVarexMulti([id], [amount], pg)
}

// not needed
async function claimVarex(roundId, entryId) {
    let accountName = state.user.accountName
    console.log('claim varex', roundId, entryId, accountName)

    await signTx('stake',{
        actions: [{
            account: VarexContract,
            name: 'claimreturn',
            data: {
                entry_id: entryId,
                // participant: accountName
            },
            authorization: [{
                actor: accountName,
                permission: OreIdPermission,
            }],
        }]
    })

    // return api.put('stake/'+state.user.id, { id, }, { headers:{ token: state.token }})
}


function verifyCodes() {
    console.log('verify codes..')
    setLoading(true)
    return api.get(`user-entries-verify-list`, { headers:{ token: state.token }})
        .then(res => {
            setLoading(false)
            console.log('verify codes res', res.status, res.data)
            if (res.status === 200 && res.data) {
                return res.data
            }
            return { status:'error', error:'error' }
        })
        .catch(e => { setLoading(false); return e })
}

function verifyList(code, pg, all, itemsPerPage, sortBy, sortDesc) {
    if (!code) return console.log('no code')

    let limit = itemsPerPage > 0 ? itemsPerPage : 0
    let sort = sortBy && sortBy.length ? sortBy[0] : ''
    let dir = sortDesc && sortDesc.length ? (sortDesc[0] ? -1 : 1) : 1
    setLoading(true)
    console.log('user-entries-verify-list..', code, pg, all, limit, sortBy, sort, sortDesc)
    return api.get(`user-entries-verify/${code}/${ pg * limit }/${limit}/${all}?sort=${sort}&dir=${ dir }`,
        { headers:{ token: state.token }})
        .then(res => {
            setLoading(false)
            console.log('verify list res', res.status, res.data)
            if (res.status === 200 && res.data) {
                return res.data
            }
            return { status:'error', error:'error' }
        })
        .catch(e => { setLoading(false); return e })
}

function setVerifierStatus(code, id, status, comment) {
    setLoading(true)
    // let verifier = store.state.user.accountName
    return api.post(`user-entry-verify/${code}/${id}/${status}`, { comment },
        { headers:{ token: state.token }})
        .then(res => {
            setLoading(false)
            console.log('verify status', code, id, status, ':', res.status, res.data)
            return res.data
        })
        .catch(e => { setLoading(false); return e })
}


function adminPayUserEntry(code, id, pay) {
    setLoading(true)
    // let verifier = store.state.user.accountName
    return api.get(`userentry-completed/${code}/${id}/${pay || 0}`,
        { headers:{ token: state.token }})
        .then(res => {
            setLoading(false)
            console.log('pay user-entry', code, id, pay, ':', res.status, res.data)
            return res.data
        })
        .catch(e => { setLoading(false); return e })
}

function adminDeleteUserEntry(code, id, del) {
    setLoading(true)
    // let verifier = store.state.user.accountName
    return api.delete(`userentry/${code}/${id}/${del}`,
        { headers:{ token: state.token }})
        .then(res => {
            setLoading(false)
            console.log('del user-entry', code, id, del, ':', res.status, res.data)
            return res.data
        })
        .catch(e => { setLoading(false); return e })
}

// ------- mentor ------
function mentorUsers(offset) {
    console.log('mentor-users..', offset)
    return api.get('mentor-users/'+offset, { headers:{ token: state.token }})
        .then(res => {
            console.log('mentor users res', res.status, res.data)
            if (res.status === 200 && res.data) {
                return res.data
            }
            return { status:'error', error:'error' }
        })
}
function newMentorCode(code, skillLevel, welcomePage) {
    console.log('mentor-code..', code)
    return api.post('mentor-code', { code, skillLevel, welcomePage }, { headers:{ token: state.token }})
        .then(res => {
            console.log('mentor code res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status=='ok') {
                if (!store.state.user.mentorCodes) store.state.user.mentorCodes = []
                store.state.user.mentorCodes.push({code, skillLevel, status:1})
                return res.data
            }
            return { status:'error', error: (res.data && res.data.error) || 'error' }
        })
}
function editMentorCode(code1, code, skillLevel, status, welcomePage) {
    console.log('mentor-code..', code1, ':', code, skillLevel, status, welcomePage)
    return api.put('mentor-code/'+code1, { code, skillLevel, status, welcomePage }, { headers:{ token: state.token }})
        .then(res => {
            console.log('mentor code res', res.status, res.data)
            if (res.status === 200 && res.data) {
                let m = _.find(store.state.user.mentorCodes, {code: code1 })
                if (m) {  // when in mentor.
                    m.skillLevel = skillLevel
                    m.status = status
                }
                return res.data
            }
            return { status:'error', error:'error' }
        })
}
function deleteMentorCode(code) {
    console.log('del mentor-code..', code)
    return api.delete('mentor-code/'+code, { headers:{ token: state.token }})
        .then(res => {
            console.log('del mentor code res', res.status, res.data)
            if (res.status === 200) {
                _.remove(store.state.user.mentorCodes, {code: code})
                return code
            }
            return { status:'error', error:'error' }
        })
}

function editSkill(id, group, name) {
    console.log('skill..', id, ':', group, name)
    return api.put('skill-level/'+id, { group, name }, { headers:{ token: state.token }})
        .then(res => {
            console.log('skill-level res', res.status, res.data)
            if (res.status === 200 && res.data) {
                state.info.SkillLevels = res.data.SkillLevels
                initGroups()

                return res.data
            }
            return { status:'error', error:'error' }
        })
}

function addMentorSkill(code) {
    console.log('add-mentor-skill..', code)
    setLoading(true)
    return api.put(`add-mentor-skill/${code}`, {}, {headers: {token: state.token}})
        .then(res => {
            setLoading(false)
            console.log('content res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return _contentResponse(res)
            }
            return res.data // for error
        })
        .catch(e => setLoading(false))
}

function initGroups() {
    let groups = _.uniq(_.map(state.info.SkillLevels, s => s.group || 'default'))
    state.info.GroupsSkillLevels =
        _.sortBy(_.map(groups, g => ({ id:g, group:g, name:'*' })).concat(state.info.SkillLevels),
            e => e.group)

    // let's also sort them
    state.info.SkillLevels = _.sortBy(state.info.SkillLevels, s => s.group)

    console.log('groups', groups, state.info.GroupsSkillLevels)
}

function deleteSkill(id) {
    console.log('del skill..', id)
    return api.delete('skill-level/'+id, { headers:{ token: state.token }})
        .then(res => {
            console.log('del skill res', res.status, res.data)
            if (res.status === 200) {
                state.info.SkillLevels = res.data.SkillLevels
                initGroups()
                return id
            }
            return { status:'error', error:'error' }
        })
}


// ------- admin -------

function moveMentorCode(code, mentor) {
    console.log('admin mv mentor-code..', code, mentor)
    return api.put(`admin/move-mentor-code/${code}/${mentor}`, {},{ headers:{ token: state.token }})
        .then(res => {
            console.log('mv mentor code res', res.status, res.data)
            if (res.status === 200) return res.data
            return { status:'error', error:'error' }
        })
}

function saveContent(type, e) {
    console.log('save-content..',type, e)
    setLoading(true)

    return api.post('save-content/'+type, e, { headers:{token: state.token }})
        .then(res => {
            setLoading(false)
            console.log('save-content res', type, res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:'error' }
        })
}
function deleteContent(type, id) {
    console.log('delete-content..',type, id)

    return api.delete(`content/${type}/${id}`, { headers:{token: state.token }})
        .then(res => {
            console.log('delete-content res', type, res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                _.pull(state[type], {id:id})
                return { status:'ok'}
            }
            return { status:'error', error:'error' }
        })
}

function adminUpdateUser(id, u) {
    console.log('admin-save-user..',id, u)

    return api.post('admin-save-user/'+id, u, { headers:{token: state.token }})
        .then(res => {
            console.log('admin-save-user res', id,u, res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:res.data ? res.data.error : 'error' }
        })
}

function adminUpdateEarnCount(userId, earn, count, pay) {
    console.log('admin-update-earn..',userId, earn, count, pay)
    // admin-update-earn
    return api.get(`/task-completed/${state.user.username}/${userId}/${earn}/${count}/${pay ? 1:0 }`,{ headers:{token: state.token }})
        .then(res => {
            console.log('admin-update-earn res', userId, earn, count,':', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:res.data ? res.data.error : 'error' }
        })
}

function adminUsers(pg, itemsPerPage, sortBy, sortDesc, filter, skillLevel, country, mentorCode) {
    let limit = itemsPerPage > 0 ? itemsPerPage : 0
    let sort = sortBy && sortBy.length ? sortBy[0] : ''
    let dir = sortDesc && sortDesc.length ? (sortDesc[0] ? -1 : 1) : 1

    console.log('admin-users..', pg, limit, sortBy, sort, sortDesc, filter)
    return api.get(`admin-users/${ pg * limit }/${limit}?sort=${sort}&dir=${ dir }&filter=${ encodeURIComponent(filter)}&skillLevel=${skillLevel}&country=${country}&mentorCode=${mentorCode}`,
        { headers:{ token: state.token }})
        .then(res => {
            console.log('users res', res.status, res.data)
            if (res.status === 200 && res.data) {
                return res.data
            }
            return { status:'error', error:'error' }
        })
}
function deleteUser(id) {
    console.log('admin delete user..', id)
    return api.delete('admin/user/'+id, { headers:{ token: state.token }})
}
function blockUser(id, block) {
    console.log('admin un/block user..', id, block)
    return api.put(`admin/user/status/${id}/${block}`, {},{ headers:{ token: state.token }})
}

async function ipCountry() {
    return axios.get('https://api.country.is')
        .then(r => { console.log('ip', r); return r && r.data ? r.data.country : '' })
}

function adminMentorCodes() {
    return api.get('admin-mentor-codes',
        { headers:{ token: state.token }})
        .then(res => {
            console.log('admin-mentor-codes', res.status, res.data)
            return res.status === 200 && res.data ? res.data : {} // .mentorCodes
        })
}
function saveDefaultMentorCodes(d) {
    return api.post(`admin-save-default-mentor-codes`, d, { headers:{token: state.token }})
        .then(res => {
            console.log('admin-save-default-mentor-codes res', d, ':', res.status)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:res.data ? res.data.error : 'error' }
        })
}

function adminStats(country) {
    console.log('admin-stats..', country)
    return api.get(`admin-stats/${country || '-'}`,
        { headers:{ token: state.token }})
        .then(res => {
            console.log('users stats', res.status, res.data)
            if (res.status === 200 && res.data) {
                return res.data
            }
            return { status:'error', error:'error' }
        })
}

function adminGiveawaysAccess() {
    return api.get('admin-giveaway-access',
        { headers:{ token: state.token }})
        .then(res => {
            console.log('admin-giveaway-access', res.status, res.data)
            return res.status === 200 && res.data ? res.data.accessList : null
        })
}
function adminUpdateGiveawayAccess(id, a) {
    console.log('admin-giveaway-access update .. ',id, a)
    // ${id}
    return api.post(`admin-update-giveaway-access`, a,{ headers:{token: state.token }})
        .then(res => {
            console.log('admin-update-giveaway-access res', id, a,':', res.status)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return res.data
            }
            return { status:'error', error:res.data ? res.data.error : 'error' }
        })
}
function deleteGiveawayTemplate(id) {
    console.log('admin-giveaway-template delete .. ',id)

    return api.delete(`admin-giveaway-template/${id}`, { headers:{token: state.token }})
        .then(res => {
            console.log('delete admin-giveaway-template res', id, ':', res.status)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                return true
            }
            return false
        })
}


function adminCreateGiveaways(templateId, num, counter) {
    return api.get(`admin-create-giveaways/${templateId}/${num}/${counter}`,
        { headers:{ token: state.token }})
        .then(res => {
            console.log('admin-create-giveaways', templateId, num, counter, ':', res.status, res.data)
            return res.status === 200 && res.data ? res.data : null
        })
}



// ---------------- ---------------
const fmtPhone = phone => {
    if (phone.startsWith('1')) return phone.replace(/^1(\d\d\d)(\d{3})(\d{4})(.*)$/, '1-$1-$2-$3$4')
    else return phone.replace(/^(\d\d\d)(\d{3})(\d{4})(.*)$/, '+$1-$2-$3$4')
}
const fmtDateTime = date => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
    }).format(new Date(date)).replace(/, /, ' ')
}

const oreLoginInfo = async (d, provider) => {
    console.log('ore-login', d, oreId.accessToken)

    oreId.auth.accessToken = oreId.accessToken

    // const userData = await oreId.auth.user.getData()
    // console.log('user data', userData)

    return api.post('client-ore-login/' + provider, d)
        .then(res => {
            setLoading(false)
            console.log('client-ore-login res', res.status, res.data)
            if (res.status === 200 && res.data && res.data.status === 'ok') {
                state.accessToken = oreId.accessToken
                loginSuccess(res.data.user, res.data.token)
                return res.data
            }
            // alert('Error ' + (res.data ? res.data.error.toString() : 'error'))
            oreId.auth.logout()
            oreId.logout()
            return res.data || 'error'
        })
        .catch(e => setLoading(false))
}

const oreStartLogin = (provider) => {
    console.log('oreLogin..', provider)
    // v1
    // window.location = ApiURL + '/ore_login/' + (provider || 'facebook')

    if (!provider) provider = "google"

    setLoading(true)
    return oreId.popup.auth({ provider, isTestUser: true  })
        .then(d => oreLoginInfo(d, provider))
        .catch(e => {
            setLoading(false)
            console.log('oreLogin err', provider, e)
            let m = e.toString().match(/error_message=(.*)/)
            if (m && m[1] && m[1].match(/cancel/i)) return { error: '' }

            return { error: m && m[1] ? m[1] : e }
        })
}

const oreLogout = () => {
    oreId.auth.logout()
    oreId.logout()

}


// --------
async function saveUserEntry(code, entry) {
    // const info = await FileSystem.getInfoAsync(file, {}) .catch(err => console.log('**errinfo', file, err))
    // const f64 = await FileSystem.readAsStringAsync(file, { encoding: FileSystem.EncodingType.Base64 }).catch(err => console.log('**err', file, err))
    setLoading(true)

    let f = new FormData()
    // _.each(['id', 'text','textBox', 'social', 'videoUrl', 'status'], k => { if (entry[k]) f.append(k, entry[k]) })

    // save image + rest of entry as json , incl imageName
    if (entry.image) {  // actual image added below.
        delete entry.imageName  // otherwise dup
        // f.append('imageName', entry.image.name)
        entry.imageName = entry.image.name
        f.append('image', entry.image)
    }
    // _.each(Object.keys(entry), k => f.append(k, entry[k]))
    f.append('entry', JSON.stringify(_.omit(entry, 'image')))

    // actually same as above..
    return api.post(`/user-entry/${code}`, f, { headers: { token: state.token }})
        .then(res => {
            setLoading(false);
            console.log('save user-entry ', entry.id, res ? res.status : '-')
            return res && res.data ? res.data.entry : null
        })
        .catch(err => {
            setLoading(false)
            console.log('save user-entry err', entry.id, err)
        })
}

async function loadUserEntries(code) {
    setLoading(true)
    return api.get(`/user-entries/${code}`, { headers: { token: state.token }})
        .then(res => {
            setLoading(false)
            if (res.status === 200 && res.data && res.data.userEntries) {
                console.log('loaded user-entries', code, res.data.userEntries)
                return res.data.userEntries || []
            }
        })
        .catch(err => {
            console.log('user-entries err', code, ':', err)
            setLoading(false)
        })
}
async function userEntryStatus(code, id, status) {
    setLoading(true)
    return api.get(`/user-entry-status/${code}/${id}/${status}`, { headers: { token: state.token }})
        .then(res => {
            setLoading(false)
        })
        .catch(err => {
            console.log('user-entry-status err', code,id,status, ':', err)
            setLoading(false)
        })
}

async function userEntryRaffle(code) {
    setLoading(true)
    return api.get(`/user-entry-raffle/${code}`, { headers: { token: state.token }})
        .then(res => {
            setLoading(false)
            console.log("user-entry-raffle", code, res.data)
            return res.data
        })
        .catch(err => {
            console.log('user-entry-raffle err', code, ':', err)
            setLoading(false)
        })
}


function skillLevelName(l) {
    if (l == 0) return "ALL"
    if (!state.info) return l
    let s = _.find(state.info.SkillLevels, { id: parseInt(l) || l })

    return s ? s.group + ' - ' + s.name : l
    // return s ? (s.group && s.group != 'default' ? s.group + ' - ' : '') + s.name : l
}

const learnId = id => _.find(state.learn, {id: id}) || _.find(state.featured, {id: id})
const earnId = id => _.find(state.earn, {id: id})

const lookupCountry = code => _.find(state.info.phonePrefixes, {code})

const userVal = k => state.user ? state.user[k] : ''
const toHtml = txt =>
    (txt || '')
        .replaceAll(/{{name}}/g, userVal('name'))
        .replaceAll(/{{phone}}/g, userVal('phone'))
        .replaceAll(/{{email}}/g, userVal('email'))
        .replaceAll(/{{wallet}}/g, userVal('accountName'))
        .replaceAll(/([a-z]+:\/\/.*?)\s/g, "<a href=\"$1\" target='_blank'>$1</a>")

const userEntryImage = e => e.imageFile ? '/static/user-entries-media/' + e.imageFile : ''

const shortVideoUrl = url => {
    let m = url && url.match(/[/=]([^/=]+)\/?$/)
    return m && m[1] ? m[1] : url
}

// tiktok video thumbnails have an x-expires parameter, refresh oembed if necessary
const checkItemVideoThumb = async item => {
    let m = item.video && item.video.thumbnail_url && item.video.thumbnail_url.match(/x-expires=(\d+)/)
    if (m && m[1] && new Date().getTime() > parseInt(m[1] + '000'))
        await loadVideoInfo(item)
}

const loadVideoInfo = async (i) => {
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    let m = i.videoUrl.match(/(https?:\/\/.*?\/)(.*)$/)

    console.log('vid oembed?', i.videoUrl)
    if (m && m[1]) {
        let q = m[1] + 'oembed?url=' + encodeURIComponent(i.videoUrl)
        return axios.get(q)
            .then(r => {
                console.log(i, r, r.data)
                if (r.data) {
                    i.video = {}
                    for (let k of Object.keys(r.data)) i.video[k] = r.data[k]
                }
                console.log("vid", i, q, r.data)
                return true
            })
            .catch(e => {
                console.log("video oembed err", i, e, e.status)
                delete i.video

                // only require oembed for listed sites..
                if (i.videoUrl.match(/tiktok|youtube/)) {
                    let err = e.toString()
                    return err == 'Error: Network Error' ? 'Video not found' : err
                }
            })
    }
}


// const CurrencySymbols = {  // WETH etc..
//     TLOS: '$',  // TEST
//     PUSDT: '$', 'USDT': '$', 'USDC': '$',
//     ETH: 'Ξ', BTC: '฿', SOL: '◎',
//     // BENR: '฿',
// }

// -----------------
var store = {
    state,
    msg,
    rules: {
        required: value => !!value || 'Required.',
        minPin: v => (v && v.length >= 6) || 'Min 6 characters',
        minUsername: v => v.length >= 4 || 'Min 4 characters',
        minCode: v => v.length >= 4 || 'Min 4 characters',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
        },
        phone: value => (/^[\d+-. ]{7,}$/).test(value) || 'invalid phone'  // TODO: per country length??
    },
    SkillLevels: [],

    // WinGroups: [ { group:1, name:'Monthly', days:30 }, { group:2, name:'Weekly', days:7 }, {group:3, name:'Daily', days:1 }],

    // TODO: use json config or just .env ?
    config: {
        useEmail: true,
        oreAuth: process.env.VUE_APP_OAUTH_METHODS.split(','),
        // oreAuth: ['google', 'apple', 'facebook', 'instagram', 'line', 'twitch', 'twitter', 'kakao', 'email'],
        // 'github', 'linkedin',  'phone',
        usePhoneAuth: parseInt(process.env.VUE_APP_USE_PHONE_AUTH),

        // phonePrefixes1: [{ code:'US', name:'USA', value:'+1' }, { code:'GT', name:'Guatemala', value:'+502'} ],
        // phonePrefixes1: [{code: 'GT', name: 'Guatemala', value: '+502'}],
        // currencySymbols: CurrencySymbols,

        captchaKey: CaptchaKey,  // NOTE: site_key also in public/index.html , secret in server/.env
        showParticipants: false,
        showBeneficiary: true,
        showWinners: false,
        showLevels: false,

        showUsername: false,
        showAccountName: false,
        allowChangePhoneNumber: false,
        allowChangeEmail: false,
        allowPin: false,
        showOreLogin: parseInt(process.env.VUE_APP_SHOW_ORELOGIN || 0),

        showWallet: true,
        WithdrawalCountries: ['GT', 'SV'],  // US for testing.
        TokenBTC: process.env.VUE_APP_TOKEN_BTC || 'PBTC',

        Languages: process.env.VUE_APP_LANGUAGES.split(',') || ['en'],

        allowChangeLevel: false, // in Profile
        showLanguageToggle: parseInt(process.env.VUE_APP_SHOW_LANGUAGES || 0),
        // otherwise uses first configured language VUE_APP_LANGUAGES

        WithdrawalMin: parseFloat(process.env.VUE_APP_WITHDRAW_MIN || 10.0),
        SendBTCMin: parseFloat(process.env.VUE_APP_SEND_BTC_MIN || 0.0001),
        landingOnly: parseInt(process.env.VUE_APP_LANDING_ONLY),
        welcomePage: parseInt(process.env.VUE_APP_LANDING_ONLY) ? '/welcome' : '/learn',
        termsUrl: process.env.VUE_APP_TERMS_URL || "https://empleosdigitales.com/terms.html",
        ShowUserSubmit: parseInt(process.env.VUE_APP_USER_SUBMIT || 0),

        WelcomePages: [
            '', 'welcome', 'learn', 'earn', 'giveaways', 'wallet',
        ],

        TransferMin: 0,
        MaxSlippage: process.env.VUE_APP_MAX_SLIPPAGE || 0.025,  // 2.5%
    },

    logoutURI: () => `https://service.oreid.io/logout?app_id=${ process.env.VUE_APP_OREID_APPID }&providers=google&callback_url=${ encodeURI(document.location.href.replace(/(\w+)$/, '')) }`,

    isAdmin, isMentor, isVerifier, login, loadUser, reloadUser, register, profile, logout, verifyCode, resendCode,
    introContent, content, userLearnStatus, userEarnStatus, userJoinStatus,
    withdrawal, withdrawalUSD, stake, transfer, convert, getRate, sendBTC, withdrawStake, withdrawStakeAll,
    loadVarexData, getVarexBalances, checkUserBalance, checkVarexBalance, stakeVarex,  stakeVarexMulti,
    checkAccount, accountHistory, checkResources,
    checkLiquidBalance, checkUSDBalance,
    nonZero, parseBalance, fmtBalance, fmtBalances, getSymbol, fmtAmount, getAmountCodeAdd,
    WithdrawSymbol,
    notifyUser, notifyClosed,
    lookupCountry, ipCountry, toHtml, shortVideoUrl, loadVideoInfo, checkItemVideoThumb,

    saveUserEntry, loadUserEntries, userEntryStatus, verifyList, verifyCodes, userEntryImage,
    setVerifierStatus, adminPayUserEntry, adminDeleteUserEntry, userEntryRaffle,

    mentorUsers, newMentorCode, editMentorCode, deleteMentorCode, moveMentorCode,
    editSkill, deleteSkill, addMentorSkill,
    adminMentorCodes, saveDefaultMentorCodes, adminStats,
    adminGiveawaysAccess, adminUpdateGiveawayAccess, adminCreateGiveaways, deleteGiveawayTemplate,
    adminUsers, saveContent, deleteContent, adminUpdateUser, adminUpdateEarnCount, deleteUser, blockUser,
    oreStartLogin, oreRegister, skillLevelName, loginPhone,
    ChainNetwork,
    learnId, earnId,
    mkToken, getCodeKey,
    glogin, oreLogout, pinReset,

    learnTitle(id) {
        let l = learnId(parseInt(id))
        return l ? l.title : id
    },

    fmtPhone, fmtDateTime,

    emitUser()  {
        console.log('user?', state.user)
        msg.emit('user', state.user && state.user.username ? state.user : null)
    },
}

export default store
