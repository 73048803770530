<template>
    <div class="page">
      <div class="page-top">
        <h3 class="page-header" v-translate>Enter Giveaway</h3>
        <div class="page-subhead" v-translate="{step:3}">Step %{step} of 3</div>
      </div>

      <br>
      <v-card class="first-card">
        <v-card-text v-if="user" :key="i" class="round-card">
          <v-icon class="refresh-logo primary--text" @click="reload(1)">mdi-refresh</v-icon>

          <h4><translate>Wallet balance:</translate>
            <router-link class="primary--text header-link" to="/wallet">
              {{ store.fmtAmount(user.liquidUSD, 'PUSDT') }}</router-link>
          </h4>&nbsp;
          <br/>

          <span v-if="user.liquidBalance && user.liquidBalance.length > 1">
<!--            <span v-for="b in user.liquidBalance" :key="b.symbol">{{ fmtBalance(b) }}</span>-->
            ({{ store.fmtBalances(user.liquidBalance) }})
          </span>
          <br>

          <div v-for="b in user.varexBalances" :key="b.symbol">
            <span v-if="store.nonZero(b.staked_balance)">
              <span v-translate>Your Deposit:</span>
              {{ fmtBalance(b.staked_balance) }}
            </span>
          </div>

          <br/>

          <div>
            <translate>If you need more funds go to</translate> &nbsp;
            <router-link to="/earn" v-translate>Complete Tasks</router-link>.
            <span v-if="showWithdraw">
              <translate>Or you can </translate>&nbsp;
              <router-link to="/withdraw" v-translate>Withdraw</router-link>&nbsp;
              <translate>the funds</translate>
            </span>
          </div>

<!--          <v-btn @click="withdrawAll()">withdraw to wallet</v-btn>-->

          <div  v-if="store.config.showAccountName"><translate>account name:</translate> {{accountName}}</div>
          <br>

          <v-btn v-if="store.config.showWinners" dense @click="showWinners=!showWinners" >
            <translate>Past Winners</translate>
          </v-btn>
        </v-card-text>
      </v-card>

      <v-row class="filter-btn-row">
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="roundState" @change="stateTab(roundState)" dense style="margin:12px 0px">
          <v-btn class="gw-filter" value="acceptentrys"><translate>Open</translate></v-btn>
          <v-btn class="gw-filter" value="won"><translate>Won</translate></v-btn>
          <v-btn class="gw-filter" value="staking"><translate>Participating</translate></v-btn>
          <v-btn class="gw-filter" value="other"><translate>Other</translate></v-btn>
<!--          <v-btn value="complete">Ended</v-btn>-->
<!--          <v-btn value="rndtimedout">Timed Out</v-btn>-->
<!--          <v-btn value="all" v-if="debug">All</v-btn>-->
        </v-btn-toggle>
      </v-row>


<!--      <div v-if="error" class="error-box" @click="error=null">-->
<!--        <v-alert dense prominent type="error" dismissible transition="v-slide-y-transition" >{{error}}</v-alert>-->
<!--      </div>-->


      <v-card v-for="d in roundsShow" :key="d.id" class="giveaway-card">
<!--          <v-card-title>#{{d.round_id}} {{d.round_name}}</v-card-title>-->
          <v-card-text  class="round-card">
            <v-row>
              <v-col class="round-header col-md-8 col-sm-8 col-12"
                     :class="{'round-coming-soon':roundIsComing(d)}">
<!--                <span class="round-name primary&#45;&#45;text">{{d.round_name}}</span>-->
                <span class="round-id primary--text">#{{d.round_id}}</span>
                <h3>{{d.round_name}}</h3>

                <span class="coming-soon" v-if="roundIsComing(d)" v-translate>Coming Soon</span>

                <div class="round-description" v-if="d.description">
                  {{ d.description}}
                </div>
              </v-col>
              <v-col v-if="hasBeneficiary(d)" class="round-ben col-md-4 col-sm-4 col-12">
                <span v-translate class="ben-label">Beneficiary:</span>
<!--                {{ d.terms.beneficiary_perc_x100000 / 100000 }}%-->
                {{ fmtBalance(beneficiaryCalc(d)) }}
                <translate>to</translate>
                {{ d.beneficiary_name || d.terms.term_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-4 col-sm-4 col-6">
<!--                <div class="round-id">#{{d.round_id}} {{d.round_name}}</div>-->
                <div class="round-label" v-translate>Deposit refunded in: </div>
                <div class="stake primary--text">{{ formatPeriod(d.staking_period) }}</div>
                <br>

<!--                <span v-if="roundIsOpen(d)">-->
<!--                  <div class="round-label">enrollment period: </div>-->
<!--                  <div class="enrollment">{{ formatPeriod(d.enrollment_time_out) }}</div>-->
<!--                </span>-->
<!--                <br>-->

                <div class="round-label" v-translate>Deposit needed to enter:</div>
                <div class="stake primary--text" v-html="fmtBalanceSm(d.entry_stake)"></div>
                <br>

                <div class="round-label" v-translate>Giveaway:</div>
                <div class="win-amount primary--text" v-html="fmtBalanceSm(prizeCalc(d))" ></div><br>
              </v-col>

              <v-col class="col-md-4 col-sm-4 col-6">
                <div class="round-label" v-translate>Min Bonus:</div>
                <div class="min-payout primary--text" v-html="fmtBalance(minPayout(d))"></div><br>

                <div class="round-label" v-translate>Participants:</div>
                <div class="players primary--text">{{d.num_participants }}</div>
                <br>

                <span v-if="d.current_state=='acceptentrys' && !roundIsComing(d)">
                  <div class="round-label" v-translate>Entered:</div>
                  <div class="players primary--text">{{ d.num_participants_entered || 0 }}</div>
                </span>
              </v-col>

              <v-col class="col-md-4 col-sm-4 col-8">
                <div v-if="roundIsComing(d)" key="enrollment-countdown">
                  <div class="round-label">
                    <span key="enrollment" v-translate>Round Opening in:</span>
                  </div>
                  <countdown :end-time="new Date(d.start)" class="countdown">
                    <template v-slot:process="{ timeObj }">

                      <span>{{ countdownStr(timeObj) }}</span>
                    </template>
                    <template v-slot:finish>
                      <span v-translate>opened!</span>
                    </template>
                  </countdown>
                  {{ store.fmtDateTime(new Date(d.start)) }}
                </div>


                <div v-else-if="roundIsOpen(d)" key="enrollment-countdown">
                  <div class="round-label">
                    <span key="enrollment" v-translate>Time Left to Enter:</span>
<!--   enrollment duration:  {{ formatPeriod(d.enrollment_time_out) }}-->
                  </div>
                  <countdown :end-time="addPeriod(d.created_date, d.enrollment_time_out)" class="countdown">
                    <template v-slot:process="{ timeObj }">
                      <span>{{ countdownStr(timeObj) }}</span>
                    </template>
                    <template v-slot:finish>
                      <span v-translate>closed!</span>
                    </template>
                  </countdown>
                  {{ store.fmtDateTime( addPeriod(d.created_date, d.enrollment_time_out)) }}
                </div>

                <div v-else-if="d.current_state === 'roundclosed'" key="staking-countdown">
                  <div class="round-label"><span key="staking" v-translate>Deposit returned:</span>
<!--                    {{ formatPeriod(d.staking_period) }}-->
                  </div>
                  <countdown :end-time="addPeriod(d.staked_time, d.staking_period)" class="countdown">
                    <template v-slot:process="{ timeObj }">
                      <span>{{ countdownStr(timeObj) }}</span>
                    </template>
                    <template v-slot:finish>
                      <span v-translate>ended!</span>
                    </template>
                  </countdown>
                  {{ store.fmtDateTime( addPeriod(d.staked_time, d.staking_period)) }}
                </div>

                <div v-else key="updated-countdown" class="smaller-text">
                  <span v-translate>updated:</span>
                  {{store.fmtDateTime(d.updated_date + 'Z')}}
                </div>

                <v-row  v-if="!roundIsComing(d)">
                  <v-col class="col-6 col-md-5">
                    <div v-if="roundIsOpen(d)" class="round-label" key="entered" v-translate>Entered:</div>
                    <div v-else class="round-label" key="participating" v-translate>Participating:</div>

                    <v-icon v-if="roundStaked(d)" class="primary--text">mdi-check</v-icon>
                    <v-icon v-else class="secondary--text">mdi-close</v-icon>
                  </v-col>

                  <v-col  class="col-6 col-md-7">
                    <div class="round-label" v-translate>Active:</div>

                    <v-icon v-if="isWinner(d)" style="color:green">fa-check-circle</v-icon>
                    <v-icon v-else-if="roundIsOpen(d)" style="color:green">far fa-circle</v-icon>
                    <v-icon v-else-if="roundIsComplete(d)" style="color:red">fa-check-circle</v-icon>
                    <v-icon v-else-if="roundTimedOut(d)" style="color:red">fa-times-circle</v-icon>
                    <v-icon v-else style="color:red">far fa-circle</v-icon>
                    &nbsp;
                    <span v-if="d.current_state==='rounddrawing'" class="drawing" key="r-drawing" v-translate> Drawing Winner...</span>
                    <span v-else-if="roundTimedOut(d)" class="timed-out" key="r-timedout" v-translate>Timed Out</span>
                    <span v-else-if="isWinner(d)" class="you-won" key="r-won">
                      <span v-translate>YOU WON!</span> {{ fmtBalance(d.winner.prize) }}
                  </span>
                  </v-col>
                </v-row>
                <br>

<!--                <v-btn v-if="canClaim(d)" @click="claimReturn(d)">claim</v-btn>-->
<!--                <v-btn v-if="showDetailInfo(d)" @click="sel=d; showDetails=true">Details</v-btn>-->
                <v-btn v-if="canStake(d)" @click="openStake(d)" color="primary">
                  <translate>Enter</translate>
                </v-btn>
<!--                <span style="color:black">{{d.current_state}}</span>-->
              </v-col>

            </v-row>
          </v-card-text>
       </v-card>


      <v-card v-if="!roundsShow.length">
        <v-card-text v-translate>- None - </v-card-text>
      </v-card>

        <br>


      <v-dialog v-model="showStake" max-width="500px">
        <v-card v-if="sel">
          <v-card-title>
            <translate>Enter</translate> &nbsp;
            #{{ sel.round_id }} {{ sel.round_name }}
          </v-card-title>
          <v-card-text>
            <translate>Deposit Amount:</translate> {{ fmtBalance(sel.entry_stake) }}<br>
            <translate>Deposit Period:</translate> {{ formatPeriod(sel.staking_period) }}<br>
            <br>

            <div v-if="store.config.showBeneficiary">
              <div v-if="sel.terms && sel.terms.beneficiary">
<!--                Term: {{sel.terms.term_name}}<br/>-->
                <translate>Beneficiary:</translate>
<!--                {{ sel.terms.beneficiary_perc_x100000 / 100000 }}%-->
                {{ fmtBalance(beneficiaryCalc(sel)) }}
                <translate>to</translate>
                {{ sel.beneficiary_name || sel.terms.term_name }}

<!--                <translate>Beneficiary Reward</translate>:-->
<!--                {{ fmtBalance(sel.beneficiary_reward) }}<br>-->
              </div>
              <!--
              <div v-if="store.nonZero(sel.total_early_exit_reward)">
                <translate>Early Exit Reward:</translate>
                {{ fmtBalance(sel.total_early_exit_reward) }}
              </div>
              <div v-if="store.nonZero(sel.total_early_exit_stake)">
                <translate>Early Exit Stake:</translate>
                {{ fmtBalance(sel.total_early_exit_stake) }}
              </div>
              -->
              <br>
            </div>

            <div v-if="sel.user && sel.user.entry_status === 'entrystaked'" error v-translate>
              Already Deposited!
            </div>

            <v-checkbox v-model="stakeTandC" :label="$gettext('I accept the Terms and Conditions')"></v-checkbox>

            <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
            <v-alert dense prominent type="success" transition="v-slide-y-transition" v-if="message">{{message}}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="showStake = false"><translate>
              Close
            </translate></v-btn>
            <v-btn color="primary" :disabled="!stakeTandC" @click="stake(sel)"><translate>
              Deposit
            </translate></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    <!-- curently not used -->
      <v-dialog v-model="showDetails" max-width="500px">
        <v-card v-if="sel">
          <v-card-title>
            #{{ sel.round_id }} {{ sel.round_name }} - <translate>Details</translate>
          </v-card-title>
          <v-card-text>
            <div v-if="sel.terms && sel.terms.beneficiary">
              Term: {{sel.terms.term_name}}<br/>
              Beneficiary: {{ sel.beneficiary_name || sel.terms.term_name }}: {{ sel.terms.beneficiary_perc_x100000 / 100000 }}%<br>
              Beneficiary Reward: {{ fmtBalance(sel.beneficiary_reward) }}<br>
            </div>
            <div v-if="store.nonZero(sel.total_early_exit_reward)">
              Early Exit Reward: {{ fmtBalance(sel.total_early_exit_reward) }}
            </div>
            <div v-if="store.nonZero(sel.total_early_exit_stake)">
              Early Exit Stake: {{ fmtBalance(sel.total_early_exit_stake) }}
            </div>
            <br>

            <div v-if="sel.winners.length">
              <h3>Winners</h3>
              <div v-for="w in sel.winners">
                {{w.entry_position}}. {{ w.participant}}: {{ fmtBalance(w.prize) }}
              </div>
            </div>
            <br>

            <div v-if="store.config.showParticipants">
              <h3>Participants</h3>
              <div v-for="e in sel.entries" >
                {{e.participant}}: {{ fmtBalance(e.entry_stake) }} / {{ fmtBalance(e.return_amount) }}
                    ({{e.entry_status}})
              </div>
            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="showDetails = false"><translate>Close</translate></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- curently not used -->
      <v-dialog v-model="showWinners" max-width="500px">
        <v-card>
          <v-card-title>Past Winners</v-card-title>
          <v-card-text>
            <div v-for="r in winners">
              #{{r.round_id}} {{r.round_name}}<br>
<!--              {{ new Date(r.staked_time).toLocaleDateString() }}-->
              {{ store.fmtDateTime(r.staked_time + 'Z')}}
              {{ formatPeriod(r.staking_period) }}: {{ fmtBalance(r.entry_stake) }},
              {{r.winners.length}} / {{r.num_participants}}
              <div v-for="w in r.winners">
                * {{ w.participant}}: {{ fmtBalance(w.prize) }}
              </div>
              <br>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="showWinners = false"><translate>Close</translate></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>

<!--  </transition>-->
</template>


<script>
import Vue from 'vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import _ from "lodash";

Vue.use(vueAwesomeCountdown, 'vac') // Component name, `countdown` and `vac` by default

export default {
  name: 'Giveaway',
  components: {
  },
  props: {
    round: Number,
  },
  data: () => ({
    error: '',
    message: '',

    showStake: false,
    showDetails: false,
    showWinners: false,
    stakeAmount: 0,
    sel: null,
    stakeTandC: false,
    showWithdraw: false,

    roundState: 'acceptentrys',
    rounds: [],
    winners: [],
    stakes: {},
    i: 0,
    user: {},
    debug: false,
  }),
  methods: {
    async load() {
      await this.store.content()
    },

    openStake(d) {
      this.showStake = true
      this.error = null
      this.sel = d

      this.stakeAmount = this.stakes[ d.round_id ] || 0
    },

    async stake() {
      if (this.sel) {
        let amt = this.sel.entry_stake  // w/ symbol
        console.log('stake:', this.sel, amt)

        // if (! this.store.checkVarexBalance(amt)) {
        //   if (! this.store.checkUserBalance(amt))
        if (! this.store.checkLiquidBalance(amt))
            return this.error = this.$gettext('insufficient balance, need') + ' ' + this.fmtBalance(amt)

        this.stakes[this.sel.round_id] = amt

        let tx = await this.store.stakeVarex(this.sel.round_id, amt)
        if (!tx || !tx.transactionId) return this.error = tx

        this.message = this.$gettext("Transaction pending")
        this.stakeAmount = 0
        this.stakeTandC = false
        this.error = false
        // show spinner?

        this.$gtag.event('enter-giveaway', this.sel.round_id)

        this.user = await this.store.reloadUser(true)
        await this.reload(1)

        let txt = this.$gettext('Staked to round')
          + ' #' + this.sel.round_id + ' - ' + this.sel.round_name
        this.store.notifyUser([{ text: txt, id:0, type:'success' }])

        this.message = null
        this.showStake = false
      }
    },

    withdrawAll() {
      if (confirm('withdraw to wallet')) this.store.withdrawStakeAll()
    },


    formatDuration(sec) {
      if (isNaN(sec)) return '-'
      let d = sec / 86400
      if (sec === 3600) return this.$gettext('1 hour')
      if (d === 1) return this.$gettext('1 day')
      if (d === 7) return this.$gettext('1 week')
      if (d < 1) return (sec / 3600) + ' ' + this.$gettext('hours')
      if (d % 7 === 0) return (d / 7) + ' ' + this.$gettext('weeks')
      // return Math.floor(d) + ' days' + (d - Math.floor(d)) ? ...
      return d + ' ' + this.$gettext('days')
    },
    formatPeriod(key) {
      return key && key._count ? this.formatDuration(key._count / 1000000) : '-'
    },
    fmtBalance(b) {
      return this.store.fmtBalance(b, 1, 0)
      // return b && typeof b == 'string' && b.match(/^0.0+ /) ? '-' : this.store.fmtBalance(b)
    },
    fmtBalanceSm(b) {
      return this.store.fmtBalance(b, 1, 1)

      // return b && b.match(/^0.0+ /) ? '-' : this.store.fmtBalance(b)
      // let bal = typeof b === 'object' && b.symbol ? b : this.store.parseBalance(b)
      // if (bal && bal.symbol) {
      //   console.log('bal', bal, bal.balance.toFixed(4))
      //   // if (parseFloat(bal.balance).toFixed(4) == '0.0000') return '-'
      //
      //   return this.store.fmtBalance(bal)
      //
      //   // else
      //   //   return bal.balance + ' <span style="font-size:smaller">' + bal.symbol + '</span>'
      // }
      // else return b
    },
    addPeriod(date, key) {
      let t = new Date(date + 'Z').getTime()  // is UTC time without TZ.
      // console.log('t',date)
      let ms = key && key._count ?  t + key._count / 1000 : t
      return new Date(ms)
    },
    countdownStr(timeObj) {
      return (timeObj.d > 0 ? timeObj.d + 'd' : '') + ` ${timeObj.h}:${timeObj.m}:${timeObj.s}`
    },

    minPayout(d) {
      let r = this.store.parseBalance(d.total_reward)
      let tot = r.balance * (d.terms ? d.terms.all_participants_perc_x100000 / 100000 / 100 : 1)
      let userPayout = tot / d.num_participants
      // console.log('min', userPayout, r.symbol)
      return { balance:userPayout, symbol:r.symbol, totalPayout:tot }
    },
    beneficiaryCalc(d) {
      let reward = this.store.parseBalance(d.total_reward)
      let ben = d.terms ? d.terms.beneficiary_perc_x100000 / 100000 / 100 : 0
      return { balance: ben * reward.balance, symbol: reward.symbol }
    },
    prizeCalc(d) {
      let min = this.minPayout(d)
      let reward = this.store.parseBalance(d.total_reward)
      // let ben = d.terms ? d.terms.beneficiary_perc_x100000 / 100000 / 100 : 0
      // let prize = reward.balance - min.totalPayout - (ben * reward.balance)
      let prize = reward.balance - min.totalPayout - this.beneficiaryCalc(d).balance

      // console.log('prize', d.round_id, min, reward, prize, d.num_participants * min.balance, ben * reward.balance, d)
      return { balance: prize, symbol:reward.symbol }
    },

    hasBeneficiary(d) {
      return d.terms && d.terms.beneficiary_perc_x100000
    },
    showDetailInfo(d) {
      // d && d.current_state !== 'acceptentrys'
      return ! this.canStake(d)
    },
    canStake(d) {
      // return d.canStake && new Date(d.datetime) > new Date()
      // return 1

      let e = this.addPeriod(d.created_date, d.enrollment_time_out)
      return d && d.current_state === 'acceptentrys' && new Date() < e && ! this.roundStaked(d) && this.roundStarted(d)
    },

    roundStarted(d) {
      return d && (!d.start || new Date(d.start).getTime() < new Date().getTime())
    },
    roundIsOpenStatus(d) {
      return d && d.current_state.match(/acceptentrys|rounddrawing/)
    },
    roundIsComing(d) {
      return this.roundIsOpenStatus(d) && ! this.roundStarted(d)
    },
    roundIsOpen(d) {
      return this.roundIsOpenStatus(d) && this.roundStarted(d)
    },
    roundIsComplete(d) {
      return d && d.current_state && d.current_state.match(/rndunlocked|roundclosed|rounddrawing|rndunlckdutk/)
    },
    roundEnded(d) { // rndunlocked|roundclosed|rounddrawing
      return d && d.current_state && d.current_state.match(/rndunlckdutk/)
    },
    roundOther(d) { // timed out or I didn't win
      return this.roundTimedOut(d) || this.roundEnded(d)
    },
    didNotWin(d) {
      return d && d.user && !d.winner && d.current_state && d.current_state.match(/rndunlocked|roundclosed|rounddrawing|rndunlckdutk/)
    },
    roundTimedOut(d) {
      return d && d.current_state && d.current_state.match(/rndtmdoututk|rndtimedout/)
    },
    roundIsStaking(d) {
      return d && d.user && d.current_state && d.current_state.match(/roundclosed|rounddrawing/)
    },
    roundStaked(d) {
      return d && d.user
    },
    stateTab(s) {
      this.$gtag.pageview('/giveaways/' + s)
    },

    isWinner(d) {
      // return d && d.winners && _.find(d.winners, { participant: this.user.accountName })
      return d.winner
    },
    canClaim(d) {
      return this.isWinner(d) && d.current_state === 'rndunlocked'
    },

    async reload(show) {
      let s = await this.store.loadVarexData(true, show)
      if (s && s.rounds) {
        this.rounds = s.rounds
        this.winners = _.sortBy(s.winners, 'staked_time').reverse()
      }
    },
  },

  async beforeMount() {
    await this.store.content()

    if (this.store.state.user) this.user = this.store.state.user
    else this.user = await this.store.reloadUser(true)

    if (this.store.state.user) this.stakes = this.store.state.user.stakes || {}
    console.log('stakes', this.stakes, document.location.href)

    this.showWithdraw = this.store.config.WithdrawalCountries.indexOf(this.user.country) != -1

    let pg = (new URL(document.location)).searchParams.get('pg')

    // doesn't work..?!
    console.log('round..', this.round, document.location.href.match(/success/), pg)

    if (document.location.href.match(/success/)) {
      this.user = await this.store.reloadUser(true)
      await this.reload(1)

      let txt = this.$gettext('Staked to round')
      if (this.round) {
        let r = _.find(this.rounds, {round_id: this.round})
        txt += ' #' + this.round + ' - ' + (r ? r.round_name : '')
      }
      this.store.notifyUser([{ text: txt, id:0, type:'success' }])
      history.replaceState({ round:this.round }, 'Giveaway', '/giveaway')

      if (pg) this.$router.push('/' + pg)
    }
    else if (document.location.href.match(/error_message=/)) {
      let m = document.location.href.match(/error_message=(.*?)(&|$)/)
      console.log('error', this.error, m? decodeURIComponent(m[1]) : '-')
      this.error = 'error'
      if (m && m[1]) {
        let s = decodeURIComponent(m[1])
        this.error = s.replace(/(\[|\{).*$/, '')

        let m2 = s.match(/assertion failure with message:(.*?)"/)
        if (m2 && m2[1]) this.error += m2[1]
        console.log('#err', m, s, m2)

        if (this.error == 'undefined') this.error = null
      }

      history.replaceState({ }, 'Giveaway', '/giveaway')
    }
    this.rounds = this.store.state.rounds
    this.winners = _.sortBy(this.store.state.winners, 'staked_time').reverse()

    this.store.msg.on('user', u => this.user = u )

    this.upd = setInterval(this.reload, 30 * 1000)
  },
  beforeDestroy() {
    this.store.msg.off('user')
    clearInterval(this.upd)
  },


  computed: {
    roundsShow() {
      return _.filter(this.rounds, r =>
          this.roundState==='all' ||
          this.roundState === r.current_state ||
          (this.roundState==='me' && r.user) ||
          (this.roundState==='won' && r.winner) ||
          (this.roundState==='staking' && r.user && this.roundIsStaking(r)) ||
          (this.roundState==='complete' && r.user && this.roundIsComplete(r)) ||
          (this.roundState==='other' && r.user && this.roundOther(r))
      ).reverse()
      //rndtmdoututk rndtimedout
    },
  }
}
</script>


<style>
.small-text {
  font-size: small;
}
.smaller-text {
  font-size: smaller;
}
.grecaptcha-badge { visibility: hidden }

.countdown {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px 10px;
  white-space: nowrap;
  margin: 10px 0px;
  font-size: 22px;
  display: block;
  width: 160px;
  text-align: center;
}
.win-amount {
  font-size:22px;
  /*font-weight: bold;*/
}
.min-payout {
  font-size:18px;
}
.players {
  font-size:22px;
}
.stake {
  font-size:22px;
}
.enrollment {
  font-size: 18px;
}
.debug-info {
  color:gray;
}
.round-label {
  color: gray;
}
.you-won {

}
.timed-out {

}

.gw-filter {
  color: gray !important;
  background-color: white !important;
  padding: 0 10px !important;
  margin: 4px 2px !important;
}
.v-btn.gw-filter.v-btn--active {
  color: black !important;
  background-color: white !important;
}
.v-btn.gw-filter.v-btn--active.theme--dark {
  color: white !important;
  background-color: gray !important;
}
.v-btn.gw-filter.theme--dark {
  color: white !important;
  background-color: #666 !important;
}

.refresh-logo {
  position:absolute !important;
  right:5px;
  top: 5px;
  /*margin-left:10px;*/
}

.round-name {
  font-size:22px;
  /*color: #00BCD4;*/
}
.round-id {
  /*font-size:18px;*/
  position: absolute;
  right: 10px;
}
.round-header {
  position: relative;
}
.round-description {
  /*color: lightgray;*/
  font-size: 18px;
}

.round-ben {
  font-size:14px;
  /*color: #00BCD4;*/
}
.ben-label {
  color: gray;
}
.coming-soon {
  font-size: 14px;
  border-radius: 5px;
  color: white;
  background-color: #00BCD4;
  padding: 3px 8px;
  margin-left: 10px;
  position: relative;
  top: -3px;
}
.round-coming-soon {
  color: gray;
}

.round-card {
  /*border: 2px solid #00BCD4;*/
  /*border-radius: 10px !important;*/
  /*margin-bottom: 10px;*/

  /*background: #FFFFFF;*/
  /*box-shadow: 0px 13px 26px #E3DEF8;*/
}
.giveaway-card {
  margin-bottom: 20px;
}

.filter-btn-row {
  margin: 0px;
}

.text-link {
  cursor:pointer;
  /*color: #00bcd4;*/
}
.text-link:hover {
  text-decoration: underline;
}

.error-box {
  position: fixed;
  top: 100px;
  z-index: 100;
  /*width: 90%;*/
  cursor: pointer;
}
</style>
