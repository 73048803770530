<template>
  <div class="page">
    <br><br>

    <div class="page-top" v-translate>
    Login or Join here using one of the below accounts.
    </div>

    <br>

    <v-form @submit="oreLogin(provider)">
      <v-row>
        <v-col class="col-md-6 col-sm-8">
          <span v-for="provider in store.config.oreAuth" >
            <v-btn @click="oreLogin(provider)" class="primary provider-btn">{{ provider }}</v-btn>
          </span>

          <br><br>
          <div v-if="usePhoneAuth">
            <div v-translate>
            Or use phone / text message:
            </div>
            <br>

            <v-row class="phone-row">
              <v-select :items="store.state.info.phonePrefixes"  label="" outlined class="phone-prefix"
                        item-text="name" item-value="value" v-model="prefix"></v-select>

              <v-text-field v-model="phone" outlined :label="$gettext('Phone')" @change="error=''" class="phone"
                            :rules="[store.rules.required, store.rules.phone]"></v-text-field>
            </v-row>

            <div v-if="showCode" v-translate>Please enter your code here:</div>
            <v-text-field v-if="showCode" v-model="code" outlined :label="$gettext('Phone Code')" type="number"
                          @change="error=''"></v-text-field>

            <v-btn primary :disabled="phone==''&&code==''" @click="loginPhone" type="submit"
                   @change="error=''">Login</v-btn>
            <a @click="toggleShowCode" style="margin-left:10px;">have code</a>
          </div>

          <br><br>
          <v-alert dense prominent type="success" transition="v-slide-y-transition" v-if="message">{{message}}</v-alert>
          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>

          <br><br/>
<!--          <router-link to="/login" >Login with pin</router-link> (admin)-->
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>


<script>

export default {
  name: 'OreLogin',
  data: () => ({
    prefix: '+1',
    phone: '',
    code: '',
    showCode:false,
    error: '',
    message: '',
    usePhoneAuth: false,
  }),
  methods: {
    oreLogin(p) {
      this.error = ''
      this.store.oreStartLogin(p).then(r => {
        console.log('r', r)
        if (r && r.next) this.$router.replace(r.next).catch(e => console.log(e))
        if (r && r.error) {
          this.error = r.error
          // this.store.oreId
        }
      })
    },
    loginPhone(e) {
      console.log('login-phone', this.prefix, this.phone)
      e.preventDefault()
      this.message = ''
      this.error = ''

      let phone = this.phone && !this.phone.match(/^\+/) ?
        this.prefix + this.phone : this.phone

      let self = this
      grecaptcha.ready(function() {
        grecaptcha.execute(self.store.config.captchaKey, {action: 'submit'}).then(function (captcha) {
          self.store.loginPhone(phone, self.code, captcha)
              .then(res => {
                self.error = res.error || ''
                console.log('login:', res.error||'', res)

                if (res.success && self.code) {
                  return res
                }
                else if (res.success) {
                  self.message = res.message
                  self.showCode = true
                }
                else {
                  self.error = res.error
                }
              })
        })
            .catch(err => { self.error = err || 'Captcha Error' })
      })
    },
    toggleShowCode() {
      this.showCode = ! this.showCode
      if (!this.showCode) this.code = ''
    }
  },
  beforeMount() {
    this.usePhoneAuth = this.store.config.usePhoneAuth
    if (this.$route.query.error) this.error = this.$route.query.error
    if (this.error == 'user_blocked')
      this.error = this.$gettext('Your account has been blocked. Please contact the QA team.')

    this.store.reloadUser()

    // on login send to welcome page. if not valid user send to finish reg.
    this.store.msg.on('user', u =>
        this.$router.replace(u && u.status>0 ? (u.welcomePage || this.store.config.welcomePage) :
            `/ore-register/${u.accountName}/${this.store.state.token}`).catch(e => console.log(e)) )
  },
  beforeDestroy() {
    this.store.msg.off('user')
  }
}
</script>

<style>
.provider-btn {
  margin: 10px;
  text-transform: capitalize !important;
}

.phone-prefix {
  min-width: 80px;
  margin-right: 5px !important;
}
.phone {
  /*margin-left: 5px;*/
}
.phone-row {
  margin-left: 5px;
  margin-right: 5px;
}
</style>