<template>
  <v-card v-if="actions" class="history-card">
    <v-card-text>

    <h3 v-translate>History</h3>
  <v-row class="tx-header">
    <v-col class="col-md-2 col-3" v-translate>Date</v-col>
    <v-col class="col-md-2 col-3" v-translate>Action</v-col>
    <v-col class="col-md-3 col-3" v-translate>From</v-col>
    <v-col class="col-md-3 col-3" v-translate>To</v-col>
    <v-col class="col-md-2 col-3 hide-sm" v-translate>Amount</v-col>
    <!--            <v-col class="col-md-4 col-12 memo-col" v-translate>Memo</v-col>-->
  </v-row>
      <v-row v-if="!actions || !actions.length">
        <v-col translate>---</v-col>
      </v-row>

  <v-row v-for="a of actions" class="tx-row" :key="'seq' + a.account_action_seq">
    <v-col class="col-md-2 col-3" >
      {{ store.fmtDateTime(a.block_time) }}
      <span style="color:gray">{{ a.account_action_seq }}</span>
    </v-col>


    <v-col class="col-md-2 col-3" v-if="a.name=='transfer'" v-translate>Transfer</v-col>
    <v-col class="col-md-3 col-3" v-if="a.name=='transfer'">{{a.data.from}}</v-col>
    <v-col class="col-md-3 col-3" v-if="a.name=='transfer'">{{a.data.to}}</v-col>

    <v-col class="col-md-2 col-3 memo-col" v-if="a.name=='transfer'">{{ store.fmtBalance(a.data.quantity) }}</v-col>
    <v-col class="col-md-4 col-9 memo-col" v-if="a.name=='transfer'">{{ a.data.memo }}</v-col>

    <v-col class="col-md-2 col-3" v-if="a.name=='swaplog'" v-translate>Swap Tokens</v-col>
    <v-col class="col-md-2 col-3" v-if="a.name=='swaplog'">
      {{ store.fmtBalance(a.data.data ? a.data.data.quantity_in.quantity : a.data.quantity_in.quantity) }}
      <span v-if="store.getSymbol(store.parseBalance(a.data.data ? a.data.data.quantity_in.quantity : a.data.quantity_in.quantity).symbol) == store.getSymbol(store.parseBalance(a.data.data ? a.data.data.quantity_out.quantity : a.data.quantity_out.quantity).symbol)">
                {{ store.parseBalance(a.data.data ? a.data.data.quantity_in.quantity : a.data.quantity_in.quantity).symbol }}
              </span>
    </v-col>
    <v-col class="col-md-2 col-3" v-if="a.name=='swaplog'">
      {{ store.fmtBalance(a.data.data ? a.data.data.quantity_out.quantity : a.data.quantity_out.quantity) }}

      <span v-if="store.getSymbol(store.parseBalance(a.data.data ? a.data.data.quantity_in.quantity : a.data.quantity_in.quantity).symbol) == store.getSymbol(store.parseBalance(a.data.data ? a.data.data.quantity_out.quantity : a.data.quantity_out.quantity).symbol)">
                {{ store.parseBalance(a.data.data ? a.data.data.quantity_out.quantity : a.data.quantity_out.quantity).symbol }}
              </span>

    </v-col>

    <v-col class="col-md-2 col-9 memo-col" v-if="a.name=='swaplog'">
      <translate>Fee</translate>: {{ store.fmtBalance(a.data.data ? a.data.data.fee : a.data.fee) }}
      <!--              <translate>Rate:</translate> {{ a.data.data.trade_price.toFixed(4) }}-->
    </v-col>
  </v-row>

  <!--        <v-btn icon v-if="offset > 0" @click="loadHistory(-2)"><v-icon>mdi-arrow-collapse-left</v-icon></v-btn>-->
  <v-btn icon v-if="offset > 0" @click="loadHistory(-1)"><v-icon>mdi-arrow-left-circle</v-icon></v-btn>
  <v-btn icon v-if="actions.length" @click="loadHistory(1)"><v-icon>mdi-arrow-right-circle</v-icon></v-btn>
  <v-btn text v-if="offset > 0" @click="loadHistory(-2)">{{ offset }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'AccountHistory',
  props: {
    user: Object
  },
  watch: {
    user() {
      console.log('user changed')
      // this.actions = null;
      this.loadHistory(0)
    }
  },
  data: () => ({
    offset: 0,
    actions: null,
  }),
  methods: {
    async loadHistory(dir, spin) {
      if (!this.user || !this.user.accountName) return
      // let len = this.actions ? this.actions.length : 0
      let len = 20

      if (dir == 1) this.offset += len
      else if (dir == -2) this.offset = 0
      else if (dir == -1) this.offset = Math.max(0, this.offset - len)
      // else if (dir == -1) this.offset = Math.max(0, this.offset - (len < 10 ? 20 : len))
      // else this.offset = 0

      console.log('dir', dir, this.offset, len)
      // let res = await this.store.accountHistory(this.user.accountName, 265, 20)
      // let res = await this.store.accountHistory(this.user.accountName, 0, this.offset, spin)
      let res = await this.store.accountHistory(this.user.accountName, this.offset, len, spin)
      if (!res) return

      this.actions = res.actions
      if (res.btcStatus) {
        let txt = this.$gettext('Bitcoin Transfer successful')
        if (res.btcStatus.status == 'error')
          txt = this.$gettext('Bitcoin Transfer failed!')
        if (res.btcStatus.status == 'pending')
          txt = this.$gettext('Bitcoin Transfer pending.. broadcasted')

        this.store.notifyUser([{text: txt, id: 0, type: res.btcStatus.status}])
      }
      // console.log(res)
    },
  },
  beforeMount() {
    this.loadHistory(0, 1)
    this.store.msg.on('history', dir => this.loadHistory(dir))
  }
}


</script>