<template>
  <div class="page">
    <h3 v-translate>Complete Sign Up</h3>
    <br>

    <v-card>
      <v-card-text>

        <div v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <div v-else-if="autoEnroll">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>

          <h4 v-translate>Welcome Giveaway</h4>

          <br/>
          <div v-translate>Enter your pin to join the free Welcome Giveaway! </div>
          <br/>

          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
          <br/>
          <v-btn v-if="error" @click="$router.replace(user.welcome || store.config.welcomePage).catch(e => console.log(e))" v-translate>Next</v-btn>

          <!--          <v-btn @click="clickAutoEnroll" class="primary">Claim</v-btn>-->

        </div>

        <div v-else-if="pinReset">
          <h4 v-translate>Welcome</h4>

          <br/>
          <div v-translate>Please click continue to set your pin code.</div>
          <br/>

          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
          <br/>
          <v-btn v-if="error" @click="$router.replace(user.welcome || store.config.welcomePage).catch(e => console.log(e))" v-translate>Next</v-btn>

          <v-btn @click="resetPin()" class="primary">Continue</v-btn>

        </div>

        <v-form v-else @submit="register">
      <v-row>
        <v-col class="col-md-6 col-sm-6">
          <v-text-field v-model="user.username" v-if="user.username && store.config.showUsername" outlined
                        :label="$gettext('Username')" @change="error=''"></v-text-field>

<!--          <v-row class="phone-row">-->
<!--            <v-select :items="store.config.phonePrefixes"  :label="$gettext('Country')"-->
            <v-select :items="store.state.info.phonePrefixes"  :label="$gettext('Country')"
                      outlined class="phone-prefix" :rules="[store.rules.required]" @change="error=''"
                      :item-text="i => i.name + ' ' + i.value" item-value="code" v-model="countryCode"></v-select>

            <v-text-field v-model="phone" outlined :label="$gettext('Phone')" @change="error=''" class="phone"
                          :rules="[store.rules.required, store.rules.phone]"></v-text-field>
<!--          </v-row>-->


          <v-text-field v-model="user.email" outlined :label="$gettext('Email')" v-if="store.config.useEmail"
                        :disabled="user.email && !store.config.allowChangeEmail"
                        @change="error=''" :rules="[store.rules.required, store.rules.email]" ></v-text-field>

<!--          <v-text-field v-model="user.pin" outlined :label="$gettext('Pin')"-->
<!--                        :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"-->
<!--                        :rules="[store.rules.required, store.rules.minPin]"-->
<!--                        :type="showPin ? 'text' : 'password'"-->
<!--                        @click:append="showPin = !showPin"-->
<!--          ></v-text-field>-->

          <div v-translate>Please enter your mentor's signup code here:</div>
          <v-text-field v-model="user.signupCode" outlined :label="$gettext('Signup Code')"
                        :x-rules="[store.rules.required, store.rules.minCode]" @change="error=''" ></v-text-field>

          <v-row class="terms-row">
            <div style="display:inherit;">
              <v-checkbox v-model="user.t_and_c" :label="$gettext('I accept the')" :rules="[store.rules.required]"></v-checkbox>
              <a :href="store.config.termsUrl" target="_blank" style="margin-top:20px;margin-left:5px;"><translate>Terms and Conditions</translate></a>
            </div>
          </v-row>


          <v-btn class="primary" :disabled="user.username=='' || phone=='' || !user.t_and_c || regLoading" @click="register" type="submit"><translate>Sign Up</translate></v-btn>

          <br/><br/>
          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>

          <!-- Error Messages -->
          <div v-if="0" >
            <translate>Signup code invalid</translate>
            <translate>Please check the phone number</translate>
            <translate>Not supported area</translate>
            <translate>User already exists</translate>
            <translate>Problem validating phone number</translate>
          </div>

          <br><br>
<!--          <div v-if="phonePrefixes.length">* allowed phone numbers: {{ phonePrefixes.join(', ') }} (US numbers use '1' + area code)</div>-->
        </v-col>
      </v-row>
    </v-form>


      </v-card-text>
    </v-card>

  </div>
</template>


<script>

import Vue from 'vue'
import _ from "lodash";

export default {
  name: 'Register',
  props: {
    accountName: String,
    token: String,
    next: String
  },
  data: () => ({
    showPin: false,
    user: {
      username: '',
      email: '',
      phone: '',
      pin: '',
      signupCode: '',
      autoEnroll: null,
    },
    prefix:'+1',
    countryCode: '',
    phone: '',
    error: '',
    pinReset: false,

    autoEnroll: null,
    loading: true,
    regLoading: false,
  }),
  methods: {
    validateCountry() {
      let c = this.countryCode && this.store.lookupCountry(this.countryCode) // || { code:'' }
      if (!this.countryCode || !c) return
      return c
    },

    register(e) {
      this.error = ''
      this.pinReset = false
      console.log('reg', this.user, this.countryCode, this.phone)
      if (e) e.preventDefault()

      // let c = this.countryCode && this.store.lookupCountry(this.countryCode) // || { code:'' }
      let c = this.validateCountry()
      if (!c) return this.error = this.$gettext('Please select country')

      // if (e) this.loading = false
      this.loading = true
      this.regLoading = true

      let self = this
      grecaptcha.ready(function() {
        grecaptcha.execute(self.store.config.captchaKey, {action: 'submit'}).then(function(captcha) {
          self.user.captcha = captcha
          self.user.phone = self.phone
          if (self.user.phone) {
            let prefix = c.value
            self.user.country = c.code.substr(0, 2)

            // if (self.user.phone.match(/^1/))
            //   self.user.phone = self.user.phone.replace(/^1/, '')
            // else if (self.user.phone.length > 8 && self.user.phone.match(/^502/)) // TODO: use self.prefix
            //   true
            // else

            let ph1 = self.user.phone.replace(/[^\d]/g, '')
            let pre1 = prefix && prefix.replace(/[^\d]/g, '')

            if (!self.user.phone.match(/^\+/) && ! ph1.startsWith(pre1))
              self.user.phone = prefix + self.user.phone
          }
          return self.store.oreRegister(self.user)
              .then(async res => {
                self.error = self.$gettext(res.error || '')
                console.log('reg:', res.error||'-', res.status, res.next,
                    res.user && res.user.welcomePage, res.user ? res.user.accountName : '-',
                    res.user ? res.user.type : '-')
                if (res.status === 'ok') {
                  if (res.next == 'pin-reset' || res.user.type == 'pending') {
                    self.pinReset = true
                    self.next = res.next
                  }
                  else if (res.next == 'auto-enroll' && res.user)
                    await self.startAutoEnroll(res.user.autoEnroll, res.user.welcomePage)
                  else
                    self.$router.replace(res.next ? '/' + res.next : res.user.welcomePage || self.store.config.welcomePage).catch(e => console.log(e))
                }
                // self.$router.replace(res.next == 'verifyPhone' ? '/verify-phone' :
                //     res.user.welcomePage || self.store.config.welcomePage)

                self.regLoading = false
                self.loading = false
              })
          .catch(e => {
            console.log('err', e.toString());
            self.regLoading = false
            self.loading = false
            self.error = e.toString()
          })
        })
        .catch(err => {
          self.error = err || 'Captcha Error';
          self.regLoading = false
          self.loading = false
        })
      })
    },

    async startAutoEnroll(autoEnroll, welcomePage) {
      console.log('autoEnroll', this.accountName, autoEnroll)

      // this.store.state.user = this.user = res.user
      this.autoEnroll = autoEnroll // user.autoEnroll
      this.error = ''
      this.loading = false
      if (!this.store.state.user.accountName)
        this.store.state.user.accountName = this.accountName

      for (let e of this.autoEnroll) {
        console.log('auto enroll..', e.round_id, e.entry_stake)
        this.$gtag.event('enter-giveaway', e.round_id)
      }

      let tx = await this.store.stakeVarexMulti(
          _.map(this.autoEnroll, e => e.round_id),
          _.map(this.autoEnroll, e => e.entry_stake), 'stake-auto')
      if (!tx || !tx.transactionId) {
        console.log('autoEnroll err', tx)
        return this.error = tx
      }

      this.$router.replace(welcomePage || this.store.config.welcomePage).catch(e => console.log(e))
    },

    async resetPin() {
      this.loading = true
      console.log('resetPin..', this.next, this.user.autoEnroll)
      await this.store.pinReset()

      console.log('resetPin.', this.next, this.user.autoEnroll)

      if (this.next == 'auto-enroll' && this.user.autoEnroll && this.user.autoEnroll.length)
        await this.startAutoEnroll(this.user.autoEnroll, this.user.welcomePage)
      else
        this.$router.replace(this.user.welcomePage || this.store.config.welcomePage).catch(e => console.log(e))

      console.log('resetPin done')
    }
  },
  async beforeMount() {
    console.log('oreReg', this.accountName, this.token)
    let u = await this.store.loadUser(this.token, true)

    await this.store.introContent(false)
    // this.phonePrefixes = c ? c.info.phonePrefixes : []

    this.loading = false

    let code = Vue.$cookies.get('code')

    if (code) {
      if (code.match(/-/)) code = code.split(/-/)[0]
      this.user.signupCode = code
    }

    console.log('oreUser..?', code, u.status, u.type, this.next, u.phone, u)
    this.user.autoEnroll = u.autoEnroll

    if (u && u.status === 2 && u.phone && u.type == 'pending') {
      this.pinReset = true
      if (u.autoEnroll) this.next = 'auto-enroll'
    }
    else if (u && u.status === 2 && u.phone)
      return this.$router.replace((this.next ? '/' + this.next : '') || u.welcomePage || this.store.config.welcomePage).catch(e => console.log(e))
    else if (u && u.status === 1 && u.phone) { // phone workflow
      return this.$router.replace('/verify-phone').catch(e => console.log(e))
    }
    if (u) {
      this.user.username = u.username1
      if (u.username1 && u.username1.match(/^\+\d+$/)) {
        this.user.username = ''
        this.user.phone = u.username1
      }

      this.user.email = u.email
      this.countryCode = u.country
    }

    let phone = Vue.$cookies.get('phone')
    if (phone) {
      this.phone = phone
      this.user.phone = phone
      Vue.$cookies.remove('phone')
    }

    if (!u || !u.country) {
      let country = Vue.$cookies.get('country') || await this.store.ipCountry()
      if (country) this.countryCode = country
    }

    // -- auto register? show 'pending' instead of form. also what about T&C
    if (u && phone && code && this.validateCountry()) { // code required?
      console.log('auto reg')
      this.loading = true
      this.register()
    }

  }
}
</script>

<style>
.terms-row {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
</style>