<template>
  <div class="page">
    <h3 v-translate></h3>
    <br>

    <v-card>
      <v-card-text>
        <div>
          <h4 v-translate>Welcome Giveaway</h4>
          <br/>
          <div v-translate>Enter your pin to join the free Welcome Giveaway! </div>
          <br/>

          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
          <br/>
          <v-btn v-if="autoEnroll"  @click="doAutoEnroll" dense class="primary">Continue</v-btn>
          <v-btn v-if="error" @click="$router.replace(user.welcome || store.config.welcomePage).catch(e => console.log(e))" v-translate>Next</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>

import _ from "lodash";

export default {
  name: 'Register',
  props: {
    accountName: String,
    token: String,
  },
  data: () => ({
    user: null,
    error: '',

    autoEnroll: null,
  }),
  methods: {

    async doAutoEnroll() {
      for (let e of this.autoEnroll) {
        console.log('auto enroll..', e.round_id, e.entry_stake, this.user)
        this.$gtag.event('enter-giveaway', e.round_id)
      }
      let tx = await this.store.stakeVarexMulti(
          _.map(this.autoEnroll, e => e.round_id),
          _.map(this.autoEnroll, e => e.entry_stake), 'stake-auto')
      if (!tx || !tx.transactionId) {
        console.log('autoEnroll err', tx)
        return this.error = tx
      }

      this.$router.replace(this.user.welcomePage || this.store.config.welcomePage).catch(e => console.log(e))
    }
  },

  async beforeMount() {
    // console.log('auto-enroll', this.accountName, this.token)
    // let u = await this.store.loadUser(this.token, true)
    this.user = this.store.state.user

    this.store.msg.on('user', u => this.user = u )

    console.log('auto-enroll', this.user, this.store.state.autoEnroll)
    this.autoEnroll = this.store.state.autoEnroll || this.user.autoEnroll

    if (!this.autoEnroll || !this.autoEnroll.length)
      this.$router.replace(this.user.welcomePage  || this.store.config.welcomePage).catch(e => console.log(e))
  }
}

</script>
