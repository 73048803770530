<template>
  <div class="page">
    <div v-if="store.isAdmin()" class="admin-btn">
      <v-select :items="SkillLevels"  label="admin: group/skills" dense outlined
                :item-text="i => i.id + '. ' + store.skillLevelName(i.id)" item-value="id"
                v-model="skillLevel" @change="load(1)" style="width:300px;"></v-select>
      <v-btn v-model="showAdmin" @click="showAdmin=!showAdmin" class="toggle-btn"><v-icon >mdi-pencil</v-icon></v-btn>
      <v-btn v-model="showActive" @click="showActive=!showActive; toggleAdmin()" class="toggle-btn">active</v-btn>
      <v-btn class="primary" @click="edit({id:0, order:0 })">new</v-btn>
    </div>

    <div class="page-top">
      <h3 class="page-header" v-translate>Welcome</h3>
    </div>

    <v-card v-for="d in featured" :key="d.id">
      <v-card-title>
        <h4>{{d.title}}</h4>
      </v-card-title>

      <v-card-text>
      <video-embed :video-id="d.videoId" :fullWidth="true" />
      <br>
      <div class="desc" v-html="store.toHtml(d.description)"></div>

      <span v-if="showAdmin"><v-btn @click="edit(d)">edit #{{d.id}}: {{d.title}}</v-btn></span>


      <br>
        <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>

        <div class="page-btn-div">
        <div v-if="user==null || !user.status" >
          <h3 v-translate>Login / Sign Up</h3>
          <span v-if="store.config.showOreLogin">
            <span v-for="provider in store.config.oreAuth" >
              <v-btn @click="oreLogin(provider)" class="primary provider-btn">{{ provider }}</v-btn>
            </span>
          </span>
<br/>
          <div id="googleButtonDiv2"></div>
        </div>

        <v-btn v-else @click="$router.push('/learn')" color="primary" key='learn-btn' class="next-page-btn">
          <translate>Next</translate>
        </v-btn>
      </div>

      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import VideoEmbed from "@/components/VideoEmbed";
import _ from "lodash";
import Vue from 'vue'

export default {
  name: 'Learn',
  components: {
    VideoEmbed
  },
  data: () => ({
    error: '',
    data: [],
    user: null,
    showAdmin: false,
    showActive: true,
    code: '',
    skillLevel: 0,
    SkillLevels: [],
  }),
  methods: {
    load() {
      if (this.store.isAdmin())
        this.store.content(true, this.skillLevel, true)
            .then(data => this.data = _.filter(data.featured, {status: this.showActive ? 1:0 } ))
      else
        this.store.introContent(this.showAdmin, this.code, this.skillLevel).then(data => this.data = data && data.featured)
    },
    edit(e) {
      this.$router.push('/home-edit/' + e.id)
    },
    toggleAdmin() {
      this.store.state.showAdmin = this.showAdmin
      this.load()
    },
    oreLogin(p) {
      this.error = ''
      this.store.oreStartLogin(p).then(r => {
        console.log('r', r)
        if (r && r.error) this.error = r.error
        if (r && r.next) this.$router.replace(r.next).catch(e => console.log(e))
      })
    },
  },
  computed: {
    featured() {
      return _.filter(this.data, d => d.language ? this.$language.current == d.language : true)
    },
  },
  async beforeMount() {
    this.store.msg.on('user', u => this.user = u )

    if (this.$route.query.code) {
      console.log('got code', this.$route.query.code)
      Vue.$cookies.set('code', this.$route.query.code)
    }
    this.code = this.$route.query.code || Vue.$cookies.get('code')

    if (this.$route.query.lang) {
      let lang = this.$route.query.lang.substr(0,2)
      if (this.store.config.Languages.indexOf(lang) != -1) {
        Vue.$cookies.set('lang', lang)
        this.$language.current = lang
        console.log('set lang', lang)
      }
    }
    if (this.$route.query.country) {
      console.log('got country', this.$route.query.country)
      Vue.$cookies.set('country', this.$route.query.country)
    }
    // this.country = this.$route.query.country || Vue.$cookies.get('country')

    if (this.$route.query.phone) {
      console.log('got phone', this.$route.query.phone)
      Vue.$cookies.set('phone', this.$route.query.phone)
    }

    await this.load(this.$route.query.code)
    this.showAdmin = this.store.state.showAdmin
    this.user = this.store.state.user

    this.SkillLevels = this.store.state.info.SkillLevels

    console.log("home before mnt", this.user && this.user.status ? this.user : null)

  },
  mounted() {
    console.log("home mnt", this.user && this.user.status ? this.user : null)
    this.store.msg.emit('glogin')
  }
}
</script>

<style>
.desc {
  white-space: pre-line;
}
.next-page-btn {
  /*text-transform: uppercase;*/
}
.page-btn-div {
  /*align-content: center;*/
}
</style>
