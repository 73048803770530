<template>
  <div class="">
    <v-card>
      <v-card-title style="display:inline-flex;">
        <h3>Verify Tasks <v-icon @click="load()">mdi-refresh</v-icon></h3>

        <v-select :items="codes"  label="task" dense outlined v-model="code" @change="loadTasks(code)"
                    style="width:200px;padding:5px;"></v-select>
        <v-checkbox :value="1" v-model="showAll" label="all" @change="loadTasks(code)" />

<!--        {{ earn.userSubmit.minRequired}}/{{ earn.userSubmit.numValidators }}-->
        &nbsp;
        {{ earn ? earn.title : '' }}
      </v-card-title>
      <v-card-text>
        <div v-if="earn && earn.userSubmit.validatorInfo" style="white-space: pre-line"
             v-html="store.toHtml(earn.userSubmit.validatorInfo)" />
      </v-card-text>

      <v-card-text v-if="store.isAdmin()">
        <v-btn @click="doRaffle()" >Raffle for {{code}}</v-btn>

        <div v-if="raffle">
          Picked: #{{raffle.rnd +1 }} / {{ raffle.num }}<br/>
          User: {{ raffle.entry.userInfo.name }}<br/>
          Phone: {{ raffle.entry.userInfo.phone }}<br/>
          Email: {{ raffle.entry.userInfo.email }}<br/>
          Account: {{ raffle.entry.userId }}<br/>

          <v-icon style="color:gray" @click="loadTasks()">fa-times-circle</v-icon>
        </div>
      </v-card-text>

      <div class="entry-outside" v-if="showEntry" @click="showEntry=null">
        <div class="entry-overlay">
          <img :src="store.userEntryImage(showEntry)" :alt="showEntry.imageName" class="img-overlay" />
        </div>
      </div>

      <v-data-table dense
                    :headers="headers"
                    :items="entries"
                    :items-per-page="10"
                    :footer-props="{ 'items-per-page-options': [10,25,50,100,-1], showFirstLastPage: true }"
                    class="elevation-1 row-clk"
                    :loading="loading"
                    :server-items-length="total"
                    :options.sync="options"
      >

        <template v-slot:item.userInfo="{ item }">
          {{ item.userInfo.name }}<br/>
          {{ item.userInfo.phone }}
        </template>


        <template v-slot:item.image="{ item }">
          <v-img v-if="item.imageFile" :src="store.userEntryImage(item)"
                 :alt="item.imageName" @click="showEntry=item" class="image-thumb" />
        </template>

        <template v-slot:item.videoUrl="{ item }">
          <a :href="item.videoUrl" v-if="item.videoUrl" target="_blank" style="max-width:200px"
             @click="ii++; item.seen=true" :key="item.id + ii">
            {{ item.video && item.video.title ? item.video.title : store.shortVideoUrl(item.videoUrl) }}

            <span v-if="item.video && item.video.thumbnail_url" class="e-image-thumb">
              <v-img :src="item.video.thumbnail_url" :alt="item.video.title" class="video-thumb" />
            </span>
          </a>
        </template>


        <template v-slot:item.status="{ item }">
          <v-icon v-if="item.status=='pending' || item.status=='new'" style="color:red">far fa-circle</v-icon>
          <v-icon v-else-if="item.status=='approved'" style="color:lightgreen">fa-check-circle</v-icon>
          <v-icon v-else-if="item.status=='approved-paid'" style="color:green">fa-check-circle</v-icon>
          <v-icon v-else-if="item.status=='rejected'" style="color:red">fa-times-circle</v-icon>
          <span v-else>{{ item.status}}</span>
<!--          {{ item.status}}-->

          <span style="white-space: nowrap">{{ adminVerifierInfo(item) }}</span>

          <v-btn v-if="store.isAdmin() && item.status == 'approved'"
                 @click="payEntry(item)" text dense class="secondary">pay</v-btn>
          <v-btn v-if="store.isAdmin() && item.status !== 'deleted'"
                 @click="deleteEntry(item)" icon dense >
            <v-icon >fa-trash</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
<!--          <span style="display:flex" v-if="canValidate(item) && isDisabled(item)">-->
<!--            <v-btn icon disabled>-->
<!--              <v-icon :style="{color: item.verifyStatus == 'accept' ? 'green' : 'gray'}">fa-check</v-icon>-->
<!--              <v-icon :style="{color: item.verifyStatus == 'reject' ? 'red' : 'gray'}">fa-thumbs-down</v-icon>-->
<!--            </v-btn>-->
<!--          </span>-->
<!--          :disabled="isDisabled(item)"-->
          <span style="display:flex" v-if="canValidate(item)">
            <v-btn @click="setVerifyStatus(item, 'accept')" icon>
              <v-icon :style="{color: item.verifyStatus == 'accept' ? 'green' : 'gray'}">fa-check</v-icon>
            </v-btn>
            <v-btn @click="setVerifyStatus(item, 'reject')" icon>
              <v-icon :style="{color: item.verifyStatus == 'reject' ? 'red' : 'gray'}">fa-thumbs-down</v-icon>
            </v-btn>
          </span>
          <span style="display:flex" v-else>
            <v-icon v-if="item.verifyStatus=='accept'" style="color:green">fa-check</v-icon>
            <v-icon v-else-if="item.verifyStatus=='reject'" style="color:red">fa-thumbs-down</v-icon>
            <span v-else>{{ item.verifyStatus }}</span>
          </span>
        </template>

      </v-data-table>

<!--      <v-snackbar v-model="showInfo" text @click="showInfo=false" color="green">-->
<!--        {{info}}-->
<!--        <template v-slot:action="{ attrs }">-->
<!--          <v-btn color="white" text v-bind="attrs" @click="showInfo = false"></v-btn>-->
<!--        </template>-->
<!--      </v-snackbar>-->
    </v-card>
  </div>
</template>

<style>

.row-clk { cursor:pointer; }
</style>

<script>
import _ from 'lodash';

export default {
  name: 'VerifyTasks',
  components: {  },
  watch: {
    options: {
      handler () {
        this.loadTasks(0)
      },
      deep: true,
    },
  },
  data: () => ({
    error: '',
    codeStats: {},
    codes: [],
    code: '',
    entries: [],
    total: 0,
    loading: true,

    dialog: false,
    editedIndex: -1,
    showEntry: null,
    showAll: 0,
    earn: null,

    options: {},
    headers: [],
    ii: 0,
    raffle: null,
  }),
  methods: {
    load() {
      // this.store.content(true).then(data => {})
      this.store.verifyCodes().then(data => {
        this.codeStats = data.stats
        this.codes = _.keys(this.codeStats)
        if (!this.code && this.codes.length) this.code = this.codes[0]

        console.log('codes', this.code, this.codeStats, this.codes)
        if (this.code) this.loadTasks(1)
      })
    },

    ymd(dt) { return (dt ? new Date(dt) : new Date()).toISOString().slice(0, 10) },

    // editItem(item) {
    //   console.log('edit', item)
    //   this.editedIndex = this.users.indexOf(item)
    //   this.user = Object.assign({}, item)
    //   this.mentor = this.user.mentor // ? this.user.mentor.username : ''
    //   this.mentorCode = this.user.mentorCode
    //   this.earnCnt = 0
    //   if (this.earnCode) this.earnCnt = this.taskCnt(this.user, this.earnCode)
    //   // this.earnCnt = this.user.earnStatus[this.earnCode] ? this.user.earnStatus[this.earnCode].cnt : 0
    //   this.dialog = true
    // },

    doSearch(s) {
      this.search = s
      this.loadTasks(1)
    },

    async loadTasks(reset) {
      this.loading = true
      this.raffle = null
      if (reset) this.options.page = 1
      let { sortBy, sortDesc, page, itemsPerPage } = this.options

      if (this.code) {
        await this.store.verifyList(this.code, page - 1, this.showAll, itemsPerPage, sortBy, sortDesc).then(data => {
          this.entries = data.entries
          this.total = data.total
          this.loading = false
        })

        if (! this.store.state.earn || !this.store.state.earn.length) await this.store.content(true)

        let e = _.find(this.store.state.earn, { code: this.code })
        this.earn = e
        console.log('earn', this.code, this.showAll, e , this.store.state.earn)

        if (e && e.userSubmit && e.userSubmit.fields) {
          this.headers = [{ text: 'user', value: 'userInfo', sortable: false }]
          _.each(e.userSubmit.fields, f => this.headers.push({ text: e.userSubmit[f], value: f, sortable: false }) )

          this.headers.push({ text: 'status', value: 'status', sortable:false })
          this.headers.push({ text: 'verify', value: 'actions', sortable:false })

          // { text: 'comments', value: 'comments' },

          if (e.userSubmit.fields.indexOf("videoUrl")) {
            for (let i of this.entries) await this.store.checkItemVideoThumb(i)
            this.ii++
            console.log("loaded", this.ii)
          }
        }
      }
    },

    adminVerifierInfo(e) {
      if (e && e.verified && this.store.isAdmin()) {
        let accept = _.filter(e.verified, {status: 'accept'}).length
        let reject = _.filter(e.verified, {status: 'reject'}).length

        return `${e.verified.length} (${accept}:${reject})`
      }
    },

    isDisabled(item) {
      // console.log('dis?', item, this.headers, _.find(this.headers, {text: 'videoUrl'}) )
      return _.find(this.headers, {text: 'videoUrl'}) && ! item.seen
    },

    canValidate(item) {
      if (item.userId == this.store.state.user.accountName) return false
      return item.status == 'new' || item.status == 'pending' || this.store.isAdmin()
    },

    async setVerifyStatus(entry, status) {
      if (this.isDisabled(entry)) return alert("please watch video first!")
      // this.store.notifyUser([{ text:"please watch video first!", type:'error'}])

      let comment
      if (status == 'reject') {
        comment = prompt("Reject entry. Comment:")
        if (!comment) return
      }
      else if (status == 'accept') {
        comment = prompt("Accept entry. Optional comment:")
        if (comment == null) return
      }

      entry.verifyStatus = status

      let d = await this.store.setVerifierStatus(this.code, entry.id, status, comment || '')
      if (d && d.status == 'ok') {
        entry.status = d.userEntry.status
        entry.verified = d.userEntry.verified
      } else if (d && d.error) alert(d.error)
    },

    async payEntry(entry) {
      if (! confirm('pay task: "'+entry.text + '" to '+entry.userInfo.name)) return

      let d = await this.store.adminPayUserEntry(entry.code, entry.id, 1)
      if (d && d.status == 'ok') entry.status = 'approved-paid'
      else if (d && d.error) alert(d.error)
    },
    async deleteEntry(entry) {
      if (! confirm('delete entry: "'+ (entry.text || entry.textBox)+ '" - '+entry.userInfo.name)) return

      let d = await this.store.adminDeleteUserEntry(entry.code, entry.id, 1)
      if (d && d.status == 'ok') entry.status = 'deleted'
      else if (d && d.error) alert(d.error)
    },


    doRaffle() {
      this.loading = true
      this.store.userEntryRaffle(this.code).then(r => {
        console.log("raffle", this.code, r)
        if (r.status == "ok") {
          this.raffle = r
          this.entries = [r.entry]
          this.total = 1
        } else if (r.error) {
          alert(r.error)
        }
          this.loading = false
      })
    }

  },
  async beforeMount() {
    // await this.store.reloadUser()
    if (!this.store.state.info || !this.store.state.info.tokens)
      await this.store.introContent()

    await this.load()
  },
}
</script>


<style>

td.text-start {
  padding: 0px 0px 0px 10px !important;
}
td.text-start:first-child {
  min-width: 120px;
}
th.text-start {
  padding: 0px 0px 0px 10px !important;
  vertical-align: top;
}
</style>
