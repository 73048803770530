<template>
  <div class="page">

    <h1 v-translate>Profile</h1>
    <br>

    <v-card>
      <v-card-text>
    <v-form @submit="save">
      <v-row v-if="user">
        <v-col class="col-md-4 col-sm-6">
          <v-text-field v-if="user.username && store.config.showUsername"
              v-model="user.username" outlined :label="$gettext('Username')" ></v-text-field>

          <!-- TODO: not changable due to the user.phone in disabled!! -->
          <v-text-field v-model="user.phone" outlined :label="$gettext('Phone')"
                        :disabled="user.phone && !store.config.allowChangePhoneNumber"
                        :rules="[store.rules.required, store.rules.phone]" ></v-text-field>

          <v-text-field v-model="user.email" outlined :label="$gettext('Email')"
                        :disabled="user.email && !store.config.allowChangeEmail"
                        :rules="[store.rules.email]" ></v-text-field>

          <!-- not used -->
          <v-text-field v-if="allowPin" v-model="user.pin" outlined :label="$gettext('Pin')"
                        :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[store.rules.required, store.rules.minPin]"
                        :type="showPin ? 'text' : 'password'"
                        @click:append="showPin = !showPin"
          ></v-text-field>

<!--          mentor code: {{ user.mentorCode }}<br/>-->

          <v-select v-if="store.state.info && store.config.allowChangeLevel"
              :items="store.state.info.SkillLevels"  label="change group/skill level (TESTING ONLY)" outlined
                    :item-text="i => store.skillLevelName(i.id)" item-value="id" v-model="user.skillLevel"></v-select>

          <span v-if="store.config.allowChangePhoneNumber || store.config.allowChangeEmail || allowPin || store.config.allowChangeLevel">
            <v-btn :disabled="user.username=='' || user.phone=='' || user.pin==''" @click="save"
                 color="primary" type="submit"><translate>Save</translate></v-btn>
          </span>


          <div @click="showSkill=!showSkill" class="">&nbsp;
            <span v-if="showSkill">
              <b v-translate>skill level</b>:
              <span v-for="s in user.skillLevels">{{ store.skillLevelName(s) }}. </span>
              <br>
            </span>
          </div>

          <v-btn v-if="!user.phoneValidated || user.phoneValidated.phone != user.phone" :disabled="!user.phone"
                 @click="$router.push('/verify-phone')" class="primary"><translate>Verify Phone</translate></v-btn>


          <v-btn color="error" @click="logout"  style="margin:10px;" ><translate>Sign Out</translate></v-btn>

          <v-alert dense prominent type="success" transition="v-slide-y-transition" v-if="msg">{{msg}}</v-alert>
          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>

          <br/>

<!--          <span v-if="user.mentor"><span v-translate>mentor:</span> {{user.mentor.username}}</span><br/>-->
          <!--          ,  <a :href="'tel:+'+ user.mentor.phone">{{user.mentor.phone}}</a>-->
<!--          <span v-translate>skill level</span>: {{ store.skillLevelName(user.skillLevel) }}<br>-->

<!--          account name: {{user.accountName || '-'}}<br/>-->
<!--          provider: {{ user.authProvider||'' }}<br/>-->

<!--          <span style="color:gray">-->
<!--            language: {{user.language}}-->
<!--            role: {{user.role}}<br/>-->
<!--            status: {{user.status}}<br/>-->
<!--          </span>-->
<!--          <router-link v-if="store.isAdmin()" to="/admin-users" >users</router-link>-->

        </v-col>
      </v-row>
    </v-form>

      </v-card-text>
    </v-card>

  </div>
</template>


<script>

export default {
  name: 'Register',
  components: {},
  data: () => ({
    showPin: false,
    allowPin: false,
    user: null,
    error: '',
    msg: '',
    showSkill: false,
  }),
  methods: {
    save(e) {
      e.preventDefault()
      this.error = this.msg = ''
      this.store.profile(this.user)
          .then(res => {
            this.error = res.error || ''
            console.log('profile:', res.error, res.status)
            if (res.status === 'ok') {
              this.msg = 'saved'
              setTimeout(()=>this.msg='', 2000)
            }
              // this.$router.replace('/')
          })
          // .catch(err => this.$router.replace('/'))
    },

    async logout() {
      await this.store.logout()
      this.user = null
      this.$router.replace('/').catch(e => console.log(e))
    }
  },
  beforeMount() {
    this.user = this.store.state.user
    this.allowPin = this.store.config.allowPin || this.store.isAdmin()

    this.store.msg.on('user', u => {
      this.user = u
      if (this.store.isAdmin()) this.allowPin = true
      // if (u.isMentor() || u.isAdmin()) this.allowPin = true
    })

    // if (this.user && (this.user.isMentor() || this.user.isAdmin())) this.allowPin = true
  }
}
</script>
