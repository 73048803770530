import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify);
Vue.use(DatetimePicker)


export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: false,
        themes: {
            // dark: {
            //     primary: '#00BCD4',
            //     secondary: '#333333',
            //     accent: '#82B1FF',
            //     error: '#FF5252',
            //     info: '#2196F3',
            //     success: '#4CAF50',
            //     warning: '#FFC107',
            //
            //     background: '#333333'
            // }

            dark: {
                primary: '#7131FF',
                secondary: '#ae8aff',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',

                background: '#222222'
            },
            light: {
                primary: '#7131FF',
                secondary: '#ae8aff',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',

                background: '#F5F5F9'
            }
        }
    }
});


