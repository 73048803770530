import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Welcome from '@/views/Welcome'
import OreRegister from "@/views/OreRegister";
import OreLogin from "@/views/OreLogin";
import VerifyPhone from "@/views/VerifyPhone"
import Profile from '@/views/Profile'
import Learn from '@/views/Learn'
import Earn from '@/views/Earn'
import Giveaway from '@/views/Giveaway'
import Withdraw from '@/views/Withdraw'
import Wallet from '@/views/Wallet'
import About from '@/views/About'
import VerifyTasks from "@/views/VerifyTasks";
import AutoEnroll from "@/views/AutoEnroll";

// --- imported below in 'admin' chunk ---
// import Login from '@/views/Login'
// import Register from '@/views/Register'
// import Mentor from "@/views/Mentor"
// import AdminUsers from '@/views/AdminUsers'
// import AdminGiveaways from "@/views/AdminGiveaways";
// import EarnEdit from '@/views/EarnEdit'
// import LearnEdit from '@/views/LearnEdit'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/mentor-login',
    name: 'Login',
    // component: Login,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Login.vue'),
    alias: ['/admin-login']
  },
  {
    path: '/register',
    name: 'Register',
    // component: Register
    component: () => import(/* webpackChunkName: "admin" */ '../views/Register.vue'),
  },
  {
    path: '/login',
    name: 'OreLogin',
    component: OreLogin
  },
  {
    path: '/ore-register/:accountName/:token/:next',
    name: 'OreRegister',
    component: OreRegister,
    props: true,
  },
  {
    path: '/ore-register/:accountName/:token',
    name: 'OreRegister',
    component: OreRegister,
    props: true,
  },
  {
    path: '/ore-register/:accountName/',
    name: 'OreRegister1',
    component: OreRegister,
    props: true,
  },
  {
    path: '/verify-phone',
    name: 'VerifyPhone',
    component: VerifyPhone
  },

  {
    path: '/verify-tasks',
    name: 'VerifyTasks',
    component: VerifyTasks
  },
  {
    path: '/auto-enroll',
    name: 'AutoEnroll',
    component: AutoEnroll
  },

  {
    path: '/admin-users',
    name: 'Users',
    // component: AdminUsers,
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminUsers.vue'),
  },
  {
    path: '/admin-mentors',
    name: 'Mentors',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMentors.vue'),
  },
  {
    path: '/admin-skills',
    name: 'Skills',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSkills.vue'),
  },
  {
    path: '/admin-giveaways',
    name: 'Giveaways',
    // component: AdminGiveaways,
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminGiveaways.vue'),
  },
  {
    path: '/admin-tasks',
    name: 'Tasks',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminTasks.vue'),
  }
]

const routesFull = [
    ...routes,
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/learn',
    name: 'Learn',
    component: Learn
  },
  {
    path: '/learn-edit/:id',
    name: 'LearnEdit',
    // component: LearnEdit,
    component: () => import(/* webpackChunkName: "admin" */ '../views/LearnEdit.vue'),
    props: (route) => ({ id: parseInt(route.params.id || 0), mode:'learn' }),
  },
  {
    path: '/home-edit/:id',
    name: 'HomeEdit',
    // component: LearnEdit,
    component: () => import(/* webpackChunkName: "admin" */ '../views/LearnEdit.vue'),
    props: (route) => ({ id: parseInt(route.params.id || 0), mode:'home' }),
  },
  {
    path: '/giveaway',
    name: 'Giveaway',
    component: Giveaway,
    // alias:['/win/:mode', '/win/:mode/:round'],
  },
  {
    path: '/giveaway/:round',
    // name: 'Giveaway Round',
    component: Giveaway,
    props: (route) => ({ round: parseInt(route.params.round || 0) }),
  },
  {
    path: '/earn',
    name: 'Earn',
    component: Earn,
    props: { id: null }
  },
  {
    path: '/earn/:skill/:id',
    name: 'EarnId',
    component: Earn,
    props: (route) => ({
      _skillLevel: parseInt(route.params.skill || 0),
      id: parseInt(route.params.id || 0)
    }),
  },
  {
    path: '/earn-edit/:id',
    name: 'EarnEdit',
    // component: EarnEdit,
    component: () => import(/* webpackChunkName: "admin" */ '../views/EarnEdit.vue'),
    props: (route) => ({ id: parseInt(route.params.id || 0) }),
  },
  {
    path: '/withdraw',
    component: Withdraw,
    alias: ['/withdrawal']
  },
  {
    path: '/wallet',
    component: Wallet,
    alias: ['/transfer', '/convert', '/send-btc']
  },
  {
    path: '/mentor',
    name: 'Mentor',
    // component: Mentor,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Mentor.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: parseInt(process.env.VUE_APP_LANDING_ONLY) ? routes : routesFull
})

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && to.name !== 'Home' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })

export default router
