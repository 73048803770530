<template>
  <v-app>
    <v-app-bar app class="top-bar" elevation="0" >
      <v-app-bar-nav-icon @click="drawer = true" style="color: gray">
        <slot name="default"><img :src="$vuetify.theme.dark ? require('@/assets/menu-icon-white.svg') : require('@/assets/menu-icon.svg')" style="height:20px;color:blue;"/></slot>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="/" class="header-link" exact>
          <v-img v-if="$language.current=='es'"  class="top-logo-es" width="150" :src="require('@/assets/EL_PartnerLogo_11.png')" alt="empleos digitales" />
          <v-img v-else class="top-logo" width="100" :src="$vuetify.theme.dark ? require('@/assets/EarnFi_logo-white.svg') : require('@/assets/EarnFi_logo.svg')" alt="EarnFi" />
        </router-link>
      </v-toolbar-title>

      <span v-if="user && user.balanceTotals && user.balanceTotals.length" class="top-balance primary--text clk"
            :style="{'color': $vuetify.theme.dark ? 'white !important': 'inherit'}" @click="toWallet()">
        {{ store.fmtAmount(user.liquidUSD, 'PUSDT') }}
        <!--      {{ store.fmtBalances(user.balanceTotals) }}-->
      </span>

      <div class="top-right">
        <router-link v-if="store.isAdmin()" to="/admin-users" class="header-link ">
            * Admin *
        </router-link>

        <v-img v-if="user && user.picture" :src="user.picture" @click="$router.push('/profile')"
               contain max-height="50" max-width="50" class="profile-pic" key="profile-pic"></v-img>
        <router-link v-else-if="user" to="/profile" class="header-link" key="username-info">
          {{ user.username }}
        </router-link>
        <router-link v-else-if="store.config.showOreLogin" to="/login" class="header-link" :class="{'header-white': $vuetify.theme.dark}" v-translate key="login-link">
          Login / Sign Up
        </router-link>

        <div v-if="!user">
          <div id="googleButtonDiv"></div>
        </div>

      </div>
    </v-app-bar>


    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group active-class="light-blue--text text--accent-4">

          <router-link to="/" tag="div" class="header-link v-list-item v-list-item--link">
            <v-list-item-title>
              <v-img v-if="$language.current=='es'"  class="top-logo-es" width="150" :src="require('@/assets/EL_PartnerLogo_11.png')" alt="empleos digitales" />
              <v-img v-else class="top-logo" width="100" :src="$vuetify.theme.dark ? require('@/assets/EarnFi_logo-white.svg') : require('@/assets/EarnFi_logo.svg')" alt="EarnFi" />
            </v-list-item-title>
          </router-link>

          <span v-if="user && user.status > 0 && !landingOnly">
            <router-link to="/learn" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="learn">
                Get Started
              </v-list-item-title>
            </router-link>

            <router-link to="/earn" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="earn">
                Complete Tasks
              </v-list-item-title>
            </router-link>

            <router-link to="/giveaway" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="giveaway">
                Enter Giveaway
              </v-list-item-title>
            </router-link>

            <router-link v-if="store.state.showWallet || store.config.showWallet" to="/wallet" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="wallet">
                Wallet
              </v-list-item-title>
            </router-link>

            <router-link to="/withdraw" v-if="showWithdraw" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="withdraw">
                Withdraw
              </v-list-item-title>
            </router-link>


            <router-link to="/about" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="about">
                About
              </v-list-item-title>
            </router-link>

            <router-link v-if="user && store.isMentor()" to="/mentor" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="mentor">
                Mentor
              </v-list-item-title>
            </router-link>

            <router-link v-if="user && store.isVerifier()" to="/verify-tasks" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title v-translate key="verify-tasks">
                Verify Tasks
              </v-list-item-title>
            </router-link>

            <router-link v-if="store.isAdmin()" to="/admin-users" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title key="admin">
                * Admin *
              </v-list-item-title>
            </router-link>

            <!--
            <router-link v-if="store.isAdmin()" to="/admin-giveaways" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title key="admin-giveaways">
                Admin - Giveaways
              </v-list-item-title>
            </router-link>
            <router-link v-if="store.isAdmin()" to="/admin-mentors" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title key="admin-mentors">
                Admin - Mentors
              </v-list-item-title>
            </router-link>
            <router-link v-if="store.isAdmin()" to="/admin-skills" tag="div" class="header-link v-list-item v-list-item--link">
              <v-list-item-title key="admin-skills">
                Admin - Skills
              </v-list-item-title>
            </router-link>
-->

            <br>
            <v-list-item>
              <v-list-item-title @click="logout"  class="header-link" v-translate key="logout">
                Logout
              </v-list-item-title>
            </v-list-item>
          </span>

          <span v-else>
            <router-link v-if="store.config.showOreLogin" to="/login" tag="div" class="header-link v-list-item v-list-item--link ">
              <v-list-item-title v-translate key="login">
                Login / Sign Up
              </v-list-item-title>
            </router-link>


            <router-link to="/about" tag="div" class="header-link v-list-item v-list-item--link" key="about">
              <v-list-item-title v-translate>
                About
              </v-list-item-title>
            </router-link>
          </span>

          <v-list-item v-if="store.config.showLanguageToggle">
            <v-list-item-title>
              <div v-if="$language.current !=='en'" @click="setLanguage('en')">English</div>
              <div v-if="$language.current !=='es'" @click="setLanguage('es')">Spanish</div>
            </v-list-item-title>
          </v-list-item>

          <v-btn icon @click="toggleDarkMode()">
            <v-icon v-if="$vuetify.theme.dark" style="color:gray">fa fa-moon</v-icon>
            <v-icon v-else style="color:gray">fa fa-sun</v-icon>
          </v-btn>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>



    <v-main>
      <v-container>
        <pulse-loader :loading="store.state.loading" color="#7131FF" :key="'loading'+ii"></pulse-loader>

        <div v-for="n in notify">
          <v-alert :type="n.type" :color="notifyColor(n)" :icon="notifyIcon(n)" light dismissible  @input="closeNotify(n)">
            {{n.text}}
          </v-alert>
        </div>

        <transition name="slide-left" mode="out-in" >
<!--          appear  appear-active-class="animated slideInDown" -->

          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Vue from 'vue'
import _ from 'lodash'

import store from '@/store.js'
Vue.prototype.store = store    // makes this available in all Vue components!

import GetTextPlugin from 'vue-gettext'
import translations from '@/lang/translations.json'
Vue.use(GetTextPlugin, {
  translations,
  availableLanguages: store.Languages,
  defaultLanguage: 'en',
  // silent: true,
  muteLanguages: ['en'],
})
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)



// var google

export default {
  name: 'App',

  components: {
    PulseLoader
  },

  data: () => ({
    user: null,
    drawer: false,
    landingOnly: store.config.landingOnly,
    notify: [],
    ii: 0,
    showWithdraw: false,
    isDark: false
  }),
  methods: {
    async logout(e) {
      e.preventDefault()
      await this.store.logout()
      this.user = null
      this.$router.replace('/').catch(e => e)
    },
    setLanguage(l) {
      this.$language.current = l
      this.$cookies.set('lang', l)
      this.store.msg.emit('lang', l)
      this.initGLogin()
    },
    detectLang() {
      let lang = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
      lang = lang.substr(0, 2)
      // console.log('lang?', lang, this.$cookies.get('lang'))
      if (this.store.config.Languages.indexOf(lang) == -1) lang = ''

      // this is not actually saved yet: user.language
      this.$language.current = (this.user && this.user.language) || this.$cookies.get('lang') || lang || this.store.config.Languages[0]
    },
    closeNotify(n) {
      console.log('closed', n)
      this.store.notifyClosed(n._id)
    },
    notifyColor(n) {
      if (n.type == 'success') return '#00BCD4'
      if (n.type == 'winner') return 'success'
      return n.type
    },
    notifyIcon(n) {
      if (n.type == 'winner') return 'fa-glass-cheers'
    },

    toWallet() {
      if ((this.user && this.user.status > 0 && !this.landingOnly) &&
        (this.store.state.showWallet || this.store.config.showWallet))
        this.$router.push('/wallet').catch(e => e)
    },

    toggleDarkMode() {
      this.$vuetify.theme.dark = ! this.$vuetify.theme.dark;
      this.$cookies.set('dark-mode', this.$vuetify.theme.dark ? "1" : "0")
      this.isDark = this.$vuetify.theme.dark
    },

    initGLogin() {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT
      if (!google) return console.log('no google found')
      console.log('init glogin..')
      if (!clientId) return console.log('no google client id')

      let cb = this.gLogin

      const opt = {theme: "outline", size: "medium", width: 175, locale: this.$language.current }

      // return Vue.nextTick(() => {  // delay to make sure the btn div is visible
      setTimeout(() => {
        google.accounts.id.initialize({
          client_id: clientId,
          callback: cb
        });
        google.accounts.id.renderButton(document.getElementById("googleButtonDiv"), opt);
        google.accounts.id.renderButton(document.getElementById("googleButtonDiv2"), opt);

        // google.accounts.id.prompt(); // also display the One Tap dialog
      }, 200)
    },

    async gLogin(resp) {
      let code = this.$route.query.code || Vue.$cookies.get('code')

      let d = await this.store.glogin(resp, code)
      if (!d || d.error) return this.notify.push({ type:'error', text: d ? d.error : 'error' })
      if (d.user) this.user = d.user

      // pending flow: set oreId auth(), then do next / autoEnroll.
      if (this.user.status == 2 && this.user.type == 'pending')
        return this.$router.push(`/ore-register/${this.user.accountName}/${d.token}/${d.next}`).catch(e => e)

      if (d.next) this.$router.replace(d.next).catch(e => console.log(e))

      // not if next is ore-reg
      if (this.user && this.user.status) Vue.$cookies.remove('code')

      //   if (r && r.error) return this.notify.push({ type:'error', text: r.error })

      // TODO/done
      // else -- if d.user, d.user.status and $cookie("code")
      // -- call method to add code and possibly do existing user autoEnroll:
      //   credit tokens and send to frontend auto-enroll page
      // check specific gw autoenroll mode. q: just "code" parameter or also encrypted param..?
    },

    gRevoke() {
      if (!google) return
      google.accounts.id.revoke(this.user.email, done => {
        console.log('consent revoked');
      });
    },

    async updateUser(u) {
      console.log('* user updated', u, u && u.balanceTotals);
      if (u && u.status>0) {
        this.user = u
        if (u.language) this.$language.current = u.language
        this.showWithdraw = this.store.config.WithdrawalCountries.indexOf(this.user.country) != -1

        // workflow with mentor code in url, with key
        let code = this.$route.query.code || Vue.$cookies.get('code')
        if (code && code.match(/-/)) {
          this.store.addMentorSkill(code).then(r => {
            Vue.$cookies.remove('code')
            console.log("code..", code, r.error)
            if (r.error) this.store.notifyUser([{ text: r.error, id:0, type:'error' }])
            else if (this.store.state.next) this.$router.push(this.store.state.next).catch(e => e)
          })
        }
      }
      else this.initGLogin()
    }
  },

  beforeMount() {
    this.detectLang()

    if (this.$cookies.get('dark-mode') == '1') {
      this.$vuetify.theme.dark = true
      this.isDark = true
    }

    this.store.msg.on('glogin', (u) => {
      this.initGLogin()
    })

    // this.store.msg.on('user', (u) => this.updateUser(u))

    this.store.msg.on('logout', () => {
      console.log('* user logout');

      this.store.oreLogout()
      // this.gRevoke()  // NOTE: testing only
      this.user = null
      this.notify = []

      this.initGLogin()

      this.$router.replace('/').catch(e => e)

      // -- needed to clear oauth2 cookies etc.
      // document.location.href = this.store.logoutURI()
    })

    this.store.msg.on('notify', list => {
      console.log('notiyf', list)
      // _.each(list, n => this.$notification.new(n.text || n.type, {  type:'success', timer: 30, showCloseIcn:true }))
      _.each(list, n => {
        console.log('new', n._id, _.find(this.notify, {_id:n._id}))
        if (!_.find(this.notify, {_id:n._id})) {
          n.text = n.text.replace(/YOU WON!/, this.$gettext('YOU WON!'))

          this.notify.push(n)
        }
      })
    })

    if (this.store.state.user && !this.user) this.updateUser(this.store.state.user)
    this.initGLogin()
  },

  mounted() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log('standalone!')
    }
    store.msg.on('loading', l => this.ii++)

    this.store.msg.on('user', (u) => this.updateUser(u))

    this.store.reloadUser()

    window.addEventListener('load', (event) => {
      console.log('page is fully loaded', google, this.user);

      if (this.store.state.user && !this.user) this.updateUser(this.store.state.user)
      this.initGLogin()
    });


  },

  beforeDestroy() {
    // this.store.msg.off('user')
  }
};
</script>

<style>

.top-bar {
  /*background-color: ;*/
}
.top-logo-es {
  /*margin-top: 10px;*/
  background-color: white;
  height: 40px;
  border-radius: 10px;
}
.top-logo {
  /*margin-top: 10px;*/
  /*background-color: #F5F5F9;*/
  /*height: 28px;*/
  margin-left: 20px;
  /*border-radius: 10px;*/
  margin: 5px 20px;
  /*padding: 5px;*/
}


.top-balance {
  position: absolute;
  right: 70px;
  font-size: 18px;
  /*color: lightgrey;*/
}
.top-right {
  position:absolute;
  right:10px;
}

.v-application a.header-link {
  /*color: white; */
  padding: 5px;
}

.grecaptcha-badge { visibility: hidden }
.profile-pic {
  border-radius:25px;
  cursor:pointer;
  white-space: nowrap;
}
.header-link {
  text-transform: uppercase;
  text-decoration: none;
}
.header-link:hover {
  text-decoration: underline;
}

.router-link-active {
  font-weight: bold;
}

.header-white {
  color: white !important;
}

/* fade or slide-left / slide-right */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}



.v-btn {
  border-radius: 20px !important;
  text-transform: none;
  /*font-size: 25px !important;*/
}
.theme--light.v-application {
  font-family: 'Questrial', sans-serif !important;
  font-size: 14px;
  background-color: #F5F5F9 !important;
}
.theme--dark.v-application {
  font-family: 'Questrial', sans-serif !important;
  font-size: 14px;
  background-color: #222 !important;
}


h3 {
  font-family: 'Spartan', sans-serif !important;
  font-weight: 700;
  font-size: 18px;
}
h4 {
  font-family: 'Spartan', sans-serif !important;
  font-weight: 700;
  font-size: 16px;
}
.theme--light.v-btn-toggle {
  background-color: inherit !important;
}
.theme--dark.v-btn-toggle {
  background-color: inherit !important;
}
.v-btn {
  box-shadow: none !important;
  text-transform: none !important;
  margin: 4px;
  padding: 0 16px !important;
}
.v-dialog {
  overflow-y: inherit !important;
  box-shadow: none !important;
}

.v-card {
  border-radius: 16px !important;
  /*box-shadow: 0px 13px 26px #E3DEF8 !important;*/

  margin-bottom: 20px;
}

.theme--light.v-card {
  box-shadow: 0px 13px 26px #E3DEF8 !important;
}
.theme--dark.v-card {
  box-shadow: 0px 13px 26px #666 !important;
}

.v-card__title {
  padding-top: 0px !important;
}
.v-card:before {
  content: '';
  display: inline-block;
  position: relative;
  top: -4px;
  left: -4px;
  width: 14px;
  height: 14px;
  background-color: red;
}

.v-card.first-card:before {
  background-color: #7131FF;
}

.clk {
  cursor: pointer;
}
.clk:hover { opacity:0.8; }

.page {
  margin: 0 10px;
}
.page-top {
  margin: 6px;
}

.container {
  max-width: inherit !important;
}


</style>
