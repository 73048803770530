<template>
  <div v-resize="onResize" v-if="videoId" class="video-embed">
    <youtube v-if="videoType==='youtube'" :video-id="videoId"
           :player-width="videoWidth" :player-height="videoHeight"
           :player-vars="{ rel:0, modestbranding:1 }" ></youtube>

    <div v-else v-html="videoCode" class="video-embed-other"></div>
  </div>


</template>

<script>
export default {
  name: 'VideoEmbed',
  props: {
    videoId: String,
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // windowSize: { x:0, y:0 },
    videoWidth: 600,
    videoHeight: 400,
    videoType: '',
    videoCode: ''
  }),
  methods: {
    onResize () {
      // this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.videoWidth = // this.fullWidth ? window.innerWidth * 0.9 :
          window.innerWidth > 640 ? 640 : window.innerWidth - 70

      this.videoHeight = this.videoWidth * 9 / 16
      // console.log('rez', this.videoId, this.fullWidth, this.videoWidth, this.videoHeight)
    },
  },
  mounted() {
    if (this.videoId) {
      this.videoType = this.videoId.match(/^[\w-]+$/) ? 'youtube' : 'embed'
      this.videoCode = this.videoId

      if (this.videoId.match(/wistia/)) {
        this.videoCode = this.videoId.replace(/<\/?script.*?>/g, '')
      }

      this.onResize()

//    if (this.videoType !== 'youtube') {
//       window._wq = window._wq || [];
//       _wq.push({ id: '90p59sxwo6', onReady: function(video) {
//           console.log("I got a handle to the video!", video);
//         }});
//    }

    }
  },
}
</script>

<style>
.video-embed {
  max-height: 360px;
  max-width: 640px;
}
.video-embed-other {
  border-radius: 20px;
  overflow: hidden;
  max-height: 360px;
  max-width: 640px;
}
</style>