<template>
  <div class="page">
    <div v-if="store.isAdmin()" class="admin-btn">
      <v-checkbox @change="toggleAdmin" v-model="showAdmin" label="admin"/>
    </div>

    <div class="page-top">
      <h3 class="page-header" v-translate>Welcome</h3>
    </div>

    <v-card v-for="d in featured" :key="d.id">
      <v-card-title>
        <h4>{{d.title}}</h4>
      </v-card-title>

      <v-card-text>
        <video-embed :video-id="d.videoId" :fullWidth="true" />
        <br>
        <div class="desc"  v-html="store.toHtml(d.description)"></div>

        <span v-if="showAdmin"><v-btn @click="edit(d)">edit {{d.id}}</v-btn></span>
      </v-card-text>
    </v-card>

    <div v-if="store.isAdmin()">
      <v-btn v-if="showAdmin" @click="edit({id:0, order:0})">new</v-btn>
    </div>

    <br>
    <div>
      <v-btn @click="$router.push('/learn')" color="primary" key='learn-btn' class="next-page-btn">
        <translate>Next</translate>
      </v-btn>
    </div>
  </div>
</template>


<script>
import _ from "lodash";
import VideoEmbed from "@/components/VideoEmbed";

export default {
  name: 'Learn',
  components: {
    VideoEmbed
  },
  data: () => ({
    error: '',
    data: [],
    user: null,
    showAdmin: false,
  }),
  methods: {
    load() {
      this.store.introContent(this.showAdmin).then(data => this.data = data.featured)
    },
    edit(e) {
      this.$router.push('/home-edit/' + e.id)
    },
    toggleAdmin() {
      this.store.state.showAdmin = this.showAdmin
      this.load()
    },
  },
  computed: {
    featured() {
      return _.filter(this.data, d => d.language ? this.$language.current == d.language : true)
    },
  },
  async beforeMount() {
    this.store.msg.on('user', u => this.user = u )

    await this.load()
    this.showAdmin = this.store.state.showAdmin
    this.user = this.store.state.user

    if (!this.user) this.$router.replace('/').catch(e => console.log(e))
  },
}
</script>
