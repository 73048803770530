<template>
  <div class="page">
    <div class="page-top">
    <h3 class="page-header" v-translate>Wallet</h3>
    </div>

    <v-card class="first-card">
      <v-card-text class="round-card" v-if="user">
        <v-icon class="refresh-logo primary--text" @click="reload(1)">mdi-refresh</v-icon>

        <div :key="'x1_'+ii">
<!--          <span v-translate></span>-->
          <span v-translate>Account name:</span> <b>{{ user.accountName }}</b>
        </div>


        <br>
        <h4><translate>Available balance:</translate>
          <span class="primary--text">
            {{ store.fmtAmount(user.liquidUSD, 'USDT') }}
          </span>
        </h4> &nbsp;


        <span v-if="user.liquidBalance && user.liquidBalance.length > 1">
<!--          ({{ store.fmtBalances(user.liquidBalance) }})-->
          <br/>
          <ul>
            <li v-for="b of user.liquidBalance" :key="'bal'+b.symbol">
<!--              <span style="width:200px;">{{ store.fmtBalance(b) }} {{ store.getAmountCodeAdd(b, ' - ') }}</span>-->
              <span style="width:200px;">
                {{ b.symbol }}
                {{ store.fmtAmount(b.balance, b.symbol, false, 1) }}
<!--                {{ store.fmtBalance(b) }} {{ store.getAmountCodeAdd(b, ' - ') }}-->
              </span>
              <span v-if="b.usdRate && b.usdRate != 1"> ({{ store.fmtBalance({balance: b.usdRate * b.balance, symbol:'USDT'}) }})</span>
            </li>
          </ul>
        </span>


        <br>

        <v-btn class="primary btn info-right" @click="openTransfer()" ><translate>Transfer to Telos Wallet</translate></v-btn>
        <v-btn icon @click="openInfo('transfer')"><v-icon size="18">mdi-help-circle</v-icon></v-btn>
        <br/>

        <v-btn class="primary btn info-right" @click="openConvert()" ><translate>Swap Tokens</translate></v-btn>
        <v-btn icon @click="openInfo('swap')"><v-icon size="18">mdi-help-circle</v-icon></v-btn>
        <br/>

        <v-btn class="primary btn info-right" @click="openBTC()" ><translate>Send to Bitcoin Wallet</translate></v-btn>
        <v-btn icon @click="openInfo('bitcoin')"><v-icon size="18">mdi-help-circle</v-icon></v-btn>

<!--        <v-btn class="primary btn" @click="store.pinReset()">pin</v-btn>-->
      </v-card-text>
    </v-card>

    <account-history :user="user" />


    <v-dialog v-model="showTransfer" max-width="540px" >
      <v-card>
        <v-card-title>
          <translate>Transfer to Telos Wallet</translate>

          <v-btn icon class="info-logo" @click="openInfo('transfer')"><v-icon>mdi-help-circle</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="recipient" @change="checkUser(recipient)" :label="$gettext('Recipient Telos Username')"
                        class="input-recipient"></v-text-field>

          <div v-if="token" class="small-text">
            <translate>Available:</translate> {{ store.fmtAmount(userMax || 0, token.code) }}
          </div>

          <v-text-field v-model="amount" type="number" @change="validateTransfer()" :label="$gettext('Transfer Amount')"
                        class="input-amount">
            <span slot="append" v-if="userMax" @click="amount=userMax" class="mx primary--text" v-translate>max</span>
          </v-text-field>

          <v-select :items="tokensTransfer" :item-text="r => r.symbol + store.getAmountCodeAdd(r.code, ' - ')"
                    :item-value="r => r" v-model="token"
                    :label="$gettext('Token')" class="sel-symbol" @change="selectSymbol(token)"></v-select>

          <span v-if="token && rate">({{ store.fmtAmount(parseFloat(amount||0) * rate, 'PUSDT' ) }})</span>


          <v-text-field v-model="memo" :label="$gettext('Optional Memo')" class="input-memo"></v-text-field>

          <v-checkbox v-model="acceptTandC" :label="$gettext('I accept the Terms and Conditions')"></v-checkbox>
          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
          <v-alert dense prominent type="success" transition="v-slide-y-transition" v-if="message">{{message}}</v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showTransfer = false"><translate>
            Close
          </translate></v-btn>
          <v-btn color="primary" :disabled="! isValidTransfer()" @click="transfer()"><translate>
            Transfer
          </translate></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="showBTC" max-width="500px">
      <v-card>
        <v-card-title>
          <translate>Send to Bitcoin Wallet</translate>

          <v-btn icon  class="info-logo" @click="openInfo('bitcoin')"><v-icon>mdi-help-circle</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn toggle color="primary" @click="showQR = !showQR"><v-icon left >mdi-qrcode-scan</v-icon>
            <translate>QR Code</translate></v-btn>
          <qrcode-stream v-if="showQR" @decode="btcDecode"></qrcode-stream>

          <v-text-field v-model="recipient" @change="checkUserBTC(recipient)"
                        :label="$gettext('Recipient Bitcoin Wallet')"
                        class="input-recipient"></v-text-field>

          <v-text-field v-model="amount" type="number" @change="validateTransferBTC()"
                        :label="$gettext('Transfer Amount') + ' ฿'"
                        class="input-amount">
            <span slot="append" v-if="userMax" @click="amount=userMax" class="mx primary--text" v-translate>max</span>
          </v-text-field>

          <span v-if="rate">({{ store.fmtAmount(parseFloat(amount||0) * rate, 'PUSDT' ) }})</span>

          <div v-if="token" class="small-text">
            <translate>Available:</translate> {{ store.fmtAmount(userMax || 0, store.config.TokenBTC) }}
          </div>

          <div class="small-text">
            <span v-translate>Minimum transfer amount:</span> &nbsp;
            {{ store.fmtAmount(store.config.SendBTCMin, store.config.TokenBTC) }}
          </div>

          <v-checkbox v-model="acceptTandC" :label="$gettext('I accept the Terms and Conditions')"></v-checkbox>
          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showBTC = false"><translate>
            Close
          </translate></v-btn>
          <v-btn color="primary" :disabled="! isValidTransfer()" @click="sendBTC()"><translate>
            Send Bitcoin
          </translate></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="showConvert" max-width="600px">
      <v-card>
        <v-card-title>
          <translate>Swap Tokens</translate>
          <v-btn icon  class="info-logo" @click="openInfo('swap')"><v-icon>mdi-help-circle</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="token" class="small-text">
            <div class="r-label" v-translate>Available:</div> {{ store.fmtAmount(userMax || 0, token.code) }}

            <div v-if="rate">
              <div class="r-label" v-translate>Rate:</div>
              {{ store.fmtAmount(rate, toToken.code) }} / {{ store.fmtAmount(1, token.code) }}<br/>
              <div class="r-label" v-translate>Estimate:</div> {{ store.fmtAmount(parseFloat(amount||0) * rate, toToken.code ) }}
            </div>
          </div>

          <v-text-field v-model="amount" type="number" @change="selectSymbol()" :label="$gettext('Convert Amount')"
                        class="input-amount" width="200">
            <span slot="append" v-if="userMax" @click="amount=userMax" class="mx primary--text" v-translate>max</span>
          </v-text-field>

          <v-select :items="tokens" :item-text="r => store.getAmountCodeAdd(r.code, '', ' - ') + r.symbol"
                    :item-value="r => r" v-model="token"
                    :label="$gettext('Token')" class="sel-symbol" @change="selectSymbol()"></v-select>

          <v-select :items="tokens" :item-text="r => store.getAmountCodeAdd(r.code, '', ' - ') + r.symbol"
                    :item-value="r => r" v-model="toToken"
                    :label="$gettext('To Token')" class="sel-symbol" @change="setRate()"></v-select>

          <v-checkbox v-model="acceptTandC" :label="$gettext('I accept the Terms and Conditions')"></v-checkbox>
          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
          <v-alert dense prominent type="success" transition="v-slide-y-transition" v-if="message">{{message}}</v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showConvert = false"><translate>
            Close
          </translate></v-btn>
          <v-btn color="primary" :disabled="!acceptTandC || !toToken || !amount" @click="convert()"><translate>
            Swap Tokens
          </translate></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog scrollable v-model="info.show" max-width="500">
      <v-card>
        <v-card-title>
          {{ info.title }}
          <v-btn class="close-btn primary" @click="info.show = false"><translate>Close</translate>
<!--          <v-icon  style="font-size:18px;width:18px;color:white;" class="clk">fa fa-times</v-icon>-->
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-pre-line" v-html="info.text"></div>

          <v-btn @click="info.show=false" class="primary" translate>Close</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import _ from "lodash";
import { validate as validateBTC } from 'bitcoin-address-validation';

import { QrcodeStream } from 'vue-qrcode-reader'

import AccountHistory from './AccountHistory'

export default {
  name: 'Withdraw',
  components: {
    QrcodeStream,
    AccountHistory
  },
  data: () => ({
    error: '',
    message: '',
    amount: '',
    token: '',
    toToken: '',
    memo: '',
    userMax: 0,
    recipient: '',
    showTransfer: false,
    showConvert: false,
    showBTC: false,
    // TokenBTC: 'PUSDT',
    showQR: false,
    acceptTandC: false,
    user: null,
    tokens: [],
    tokensTransfer: [],
    actions: null,
    rate: 0,
    offset: 0,
    ii:0,

    info: {
      title: '',
      text: '',
      show: false
    },
    // infoTransfer: false,
    // infoSwaps: false,
    // infoSendBTC: false,
  }),
  methods: {
    async reload(spin) {
      this.store.msg.emit('history', 0, spin)

      await this.store.reloadUser(1)
    },



    // ---------- transfer ----------
    openTransfer() {
      this.showTransfer = true
      this.toToken = _.find(this.store.state.info.tokens, { code: 'USDT' })
      this.setRate()

      this.recipient = ''
      this.error = null
    },

    async checkUser(r) {
      this.error = ''
      if (!r) return this.error = this.$gettext('Enter the account name')
      // if (r.length != 12) return this.error = this.$gettext('TELOS account names are 12 characters')

      let ok = await this.store.checkAccount(r)
      if (!ok) this.error = "Account not found"
    },
    validateTransfer() {
      this.error = ''
      if (this.token && this.userMax && this.amount != '') {
        let amt = parseFloat(this.amount)
        console.log('amt', this.amount, amt, this.userMax)
        if (amt == 0 || amt > this.userMax)
          this.error = this.$gettext("Please check the amount")
      }
    },


    isValidConvert() {
      return !! (!this.error && (this.amount > 0) && this.acceptTandC && this.token)
    },
    isValidTransfer() {
      return this.isValidConvert() && this.recipient
    },
    initSymbol() {
      if (!this.token || !this.user) return
      let bal = _.find(this.user.liquidBalance, {symbol: this.token.code })

      this.userMax = bal ? parseFloat(bal.balance.toFixed(this.token.digits)) : ''
      console.log("userMax", this.userMax, this.token.code, this.token.digits, bal)
    },
    selectSymbol() {
      this.initSymbol()
      this.validateTransfer()
      this.setRate()
    },

    async transfer() {
      if (!this.isValidTransfer()) return this.validateTransfer()
      
      let amount = parseFloat(this.amount)
      let amt = amount.toFixed(this.token.digits) + ' ' + this.token.code
      if (! this.store.checkLiquidBalance(amt))
        return this.error = this.$gettext("Insufficient funds")

      // if (amount < this.store.config.TransferMin)
      //   return this.error = this.$gettext("minimum transfer amount:") + this.store.config.TransferMin

      let tx = await this.store.transfer(this.recipient, amt, this.token, this.memo)
      if (!tx || !tx.transactionId) return this.error = tx || this.$gettext("Error sending")

      this.message = this.$gettext("Transfer pending")
      this.$gtag.event('transfer')

      await this.txSuccess(this.$gettext('Transfer completed'))
      this.showTransfer = false
    },

    setRate() {
      this.error = ''
      // if (this.token.code == this.toToken.code)
      //   return this.error = this.$gettext('tokens identical')

      // let s = this.token.code + ':' + this.toToken.code
      // let r = this.store.state.info.rates[s]

      let { path, rate } = this.store.getRate(this.token.code, this.toToken.code)
      this.rate = rate || 0
    },

    openConvert() {
      this.reload()
      this.showConvert = true
      this.error = null
    },

    // ---------- btc -----------
    openBTC() {
      this.reload()

      console.log("btc", this.store.config.TokenBTC)
      this.token = _.find(this.store.state.info.tokens, { code: this.store.config.TokenBTC })
      this.toToken = _.find(this.store.state.info.tokens, { code: 'USDT' })
      this.initSymbol()
      this.setRate()
      this.recipient = ''
      this.showBTC = true
    },

    btcDecode(btc) {
      console.log('btc', btc)
      this.recipient = btc
      if (btc && this.validateTransferBTC()) this.showQR = false
    },
    async checkUserBTC(r) {
      this.error = ''
      if (!r) return this.error = this.$gettext('Enter the Bitcoin address')
      if (! validateBTC(r)) return this.error = this.$gettext("Please check the Bitcoin address!")
      console.log('ok', r)
      return 1
    },
    validateTransferBTC() {
      if (! this.checkUserBTC(this.recipient)) return

      console.log('t', this.token, this.userMax, this.amount)
      // this.error = ''
      if (! this.userMax) return this.error = this.$gettext("No available balance")

      if (this.token && this.userMax && this.amount != '') {
        let amt = parseFloat(this.amount)
        console.log('amt', amt, this.userMax)
        if (amt == 0 || amt > this.userMax)
          return this.error = this.$gettext("Please check the amount")
      }
      return 1
    },

    async sendBTC() {
      let amount = parseFloat(this.amount)
      let amt = amount.toFixed(this.token.digits) + ' ' + this.token.code
      if (! this.store.checkLiquidBalance(amt))
        return this.error = this.$gettext("Insufficient funds")

      if (amount < this.store.config.SendBTCMin)
        return this.error = this.$gettext("Minimum transfer amount:")
            + this.store.getSymbol(this.store.config.TokenBTC) + ' '
            + this.store.config.SendBTCMin

      let tx = await this.store.sendBTC(this.recipient, amt, this.token)
      if (!tx || !tx.transactionId) return this.error = tx || this.$gettext("Error sending")

      this.message = this.$gettext("Transaction pending")

      this.$gtag.event('sendBTC')

      await this.txSuccess(this.$gettext('Bitcoin transfer pending'))
      this.showBTC = false
    },


    // --------- swap ----------

    async convert() {
      if (!this.isValidConvert()) return this.validateTransfer()

      let amount = parseFloat(this.amount)
      let amt = amount.toFixed(this.token.digits) + ' ' + this.token.code
      if (! this.store.checkLiquidBalance(amt))
        return this.error = this.$gettext("Insufficient funds")

      if (this.token.code == this.toToken.code)
        return this.error = this.$gettext('tokens identical')

      if (this.store.config.TransferMin && amount < this.store.config.TransferMin)
        return this.error = this.$gettext("Minimum transfer amount:") + this.store.config.TransferMin

      let tx = await this.store.convert(amount, this.token, this.toToken)
      if (!tx || !tx.transactionId) return this.error = tx || this.$gettext("Error: no conversion rate found")

      this.message = this.$gettext("Conversion pending")

      this.$gtag.event('convert', { fromToken:this.token.code, toToken:this.toToken })
      // this.showWithdraw = false

      await this.txSuccess(this.$gettext('Swap completed'))
      this.showConvert = false
    },

    async txSuccess(txt) {
      this.user = await this.store.reloadUser(true)

      this.store.notifyUser([{ text: txt, id:0, type:'success' }])

      setTimeout(this.reload, 2000)
      setInterval(this.reload, 60000)
      this.message = ''
      this.error = ''
    },

    userChanged() {
      if (!this.user || !this.user.status) return this.$router.replace('/').catch(e => console.log(e))

      // this.loadHistory()  // don't need to await
      this.store.msg.emit('history', 0)

      if (this.user.liquidBalance) {
        this.tokens = _.filter(this.store.state.info.tokens, { convert: true })
        this.tokensTransfer = _.filter(this.store.state.info.tokens, t => t.transfer || t.convert )

        if (!this.token || ! _.find(this.tokens, {code: this.token.code }))
          this.token = this.tokens ? this.tokens[0] : null

        if (!this.toToken || ! _.find(this.tokens, {code: this.toToken.code }))
          this.toToken = this.tokens && this.tokens.length > 1 ? this.tokens[1] : null

        this.initSymbol()
        this.ii++
      }
    },

    openInfo(t) {
      // this.info.title = this.$gettext(t)
      // this.info.text = this.$gettext('info:' + t)

      // this.info.text = require(`raw-loader!../assets/help/en-${t}.html`).default
      this.info.text = require(`raw-loader!../assets/help/wallet-${this.$language.current}.html`).default
      this.info.show = true

      Vue.nextTick(() => document.getElementById('help-' + t).scrollIntoView() )

      console.log('info',t, this.info, this.$language.current)
    },
  },
  async beforeMount() {
    this.symbol = this.store.WithdrawSymbol
    this.user = this.store.state.user
    if (!this.store.state.info || !this.store.state.info.tokens) await this.store.introContent()

    console.log('t', this.store.state.info.tokens, this.store.logoutURI(), this.user)

    if (!this.user) this.user = await this.store.reloadUser(true)

    this.store.msg.on('user', u => {
      console.log('wallet user', u)
      this.user = u
      this.userChanged()
    })

    this.userChanged()

    this.cron = setInterval(this.reload, 180 * 1000)

    // catch success/error msg on return from ore-id.
    // if (document.location.href.match(/success/)) {
    //   this.user = await this.store.reloadUser(true)
    //
    //   let txt = this.$gettext('Transfer completed')
    //
    //   if (this.$route.path.match(/convert/))
    //     txt = this.$gettext('Swap completed')
    //   else if (this.$route.path.match(/send-btc/))
    //     txt = this.$gettext('Bitcoin transfer pending')
    //
    //   this.store.notifyUser([{ text: txt, id:0, type:'success' }])
    //
    //   setTimeout(this.reload, 2000)
    //   setInterval(this.reload, 60000)
    //
    //   history.replaceState({  }, 'Withdrawal', '/wallet')
    // }
    // else if (document.location.href.match(/error_message=/)) {
    //   let m = document.location.href.match(/error_message=(.*?)(&|$)/)
    //   console.log('error', this.error, m? decodeURIComponent(m[1]) : '-')
    //   this.error = 'error'
    //   if (m && m[1]) {
    //     let s = decodeURIComponent(m[1])
    //     this.error = s.replace(/(\[|\{).*$/, '')
    //
    //     let m2 = s.match(/assertion failure with message:(.*?)"/)
    //     if (m2 && m2[1]) this.error += m2[1]
    //     console.log('#err', m, s, m2)
    //   }
    //   if (this.error == 'undefined') this.error = ''
    //
    //   if (this.error) {
    //     if (this.$route.path.match(/convert/)) this.showConvert = true
    //     else if (this.$route.path.match(/send-btc/)) this.openBTC()
    //     else this.showTransfer = true
    //   }
    //   history.replaceState({ error:this.error, showConvert:true }, 'Withdraw', '/wallet')
    // }
  },
  beforeDestroy() {
    this.store.msg.off('user')
    if (this.cron) clearInterval(this.cron)
  }
}
</script>

<style>
.input-amount {
  display: inline-flex;
  margin-right: 10px;
}
.sel-symbol {
  display: inline-flex;
  margin-right: 10px;
  width: 130px;
}

.history-card {
  /*overflow: hidden;*/
}

.tx-header {
  border-bottom: 2px solid lightgrey;
}
.tx-row {
  border-bottom: 1px solid lightgrey;
}


.btn {
  margin: 5px 10px;
}
.faq-text {
  white-space: pre-line;
}

.memo-col {
  padding-top: 0px;
  padding-bottom: 6px;
}
.hide-sm {
  display: none;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.r-label {
  width: 80px;
  display: inline-block;
}
/*.primary-clk {*/
/*  color: #00BCD4;*/
/*  cursor: pointer;*/
/*}*/
/*.primary-clk:hover {*/
/*  !*background-color: ;*!*/
/*  font-weight: bold;*/
/*}*/

.mx {
  /*color: #00BCD4;*/
  cursor: pointer;
  margin-top: 4px;
}

.help-txt a {
  text-decoration: none;
  /*color: white !important;*/
}
.help-txt a:hover {
  color: lightgrey !important;
}

.info-right {
  margin-right: 0px;
  max-width: 270px;
}
.info-right span {
  max-width: 245px;
  overflow: hidden;
}


.info-logo {
  position:absolute !important;
  right: 0px;
  top: 14px;
  /*margin-left:10px;*/
}

@media only screen and (min-width: 960px) {
  .memo-col {
    padding: 12px;
  }

  .hide-sm {
    display: block;
  }
}
.container {
  padding: 4px;
}
@media only screen and (min-width: 640px) {
  .container {
    padding: 12px;
  }
}
</style>
