<template>
  <div class="page">
    <div v-if="store.isAdmin()" class="admin-btn">
      <v-select :items="SkillLevels"  label="admin: group/skills" dense outlined
                :item-text="i => i.id + '. ' + store.skillLevelName(i.id)" item-value="id"
                v-model="skillLevel" @change="load(1)" style="width:300px;"></v-select>

      <v-btn v-model="showAdmin" @click="showAdmin=!showAdmin; toggleAdmin()" class="toggle-btn"><v-icon >mdi-pencil</v-icon></v-btn>
      <v-btn v-model="showActive" @click="showActive=!showActive; toggleAdmin()" class="toggle-btn">active</v-btn>
      <v-btn class="primary" @click="edit({id:0})">new</v-btn>
    </div>

    <div class="page-top">
      <h3 class="page-header" v-translate>Get Started</h3>
      <div class="page-subhead" v-translate="{step:1}">Step %{step} of 3</div>
    </div>

<!--    <v-container>-->

      <v-card v-for="d in langData" :key="d.id">
<!--      <v-row>-->
        <v-card-title class="text-col">
          <h4>{{d.title}}</h4>
        </v-card-title>
        <v-card-text>
<!--          <v-row><v-col class="col-8 sm-12">-->
            <div class="desc" v-html="store.toHtml(d.description)"></div>
            <br/>
<!--          </v-col>-->

<!--          <v-col class="col-4 sm-12">-->
          <video-embed :video-id="d.videoId" />
<!--          </v-col></v-row>-->

          <div v-if="d.videoId"><v-checkbox v-model="learnStatus[d.id]" @change="statusUpdated(d.id)"
                           :label="$gettext('I\'ve seen the video')"/></div>

        <span v-if="showAdmin"><v-btn @click="edit(d)">edit #{{d.id}}: {{d.title}}</v-btn></span>
<!--      <hr class="learn-hr"/>-->
        </v-card-text>

      </v-card>

      <div v-if="store.isAdmin()">
        <v-btn v-if="showAdmin" @click="edit({id:0})">new</v-btn>
      </div>

      <br>
      <v-btn @click="$router.push('/earn')" color="primary" class="next-page-btn">
        <translate>Next</translate>
      </v-btn>

<!--    </v-container>-->
  </div>
</template>


<script>
import VideoEmbed from "@/components/VideoEmbed";
import _ from "lodash";

export default {
  name: 'Learn',
  components: {
    VideoEmbed
  },
  data: () => ({
    error: '',
    showAdmin: false,
    showActive: true,
    skillLevel: 1,
    data: [],
    learnStatus: {},
    SkillLevels: []
  }),
  methods: {
    edit(e) {
      this.$router.push('/learn-edit/' + e.id)
    },
    async load() {
      // this.store.content(this.showAdmin)
      //     .then(data => this.data = data.learn)
      const data = await this.store.content(this.store.isAdmin(), this.skillLevel, true)
      this.data = _.filter(data ? data.learn || [] : [], { status: this.showActive ? 1 : 0 })
    },
    toggleAdmin() {
      this.store.state.showAdmin = this.showAdmin
      this.load()
    },
    statusUpdated(id) {
      this.store.userLearnStatus(this.learnStatus)

      this.$gtag.event('learn', id)
    },
  },
  computed: {
    langData() {
      console.log('d', this.data, this)
      return _.filter(this.data, d => d.language ? this.$language.current == d.language : true)
    },
  },

  async beforeMount() {
    await this.load()
    if (this.store.state.user) this.learnStatus = this.store.state.user.learnStatus || {}
    this.showAdmin = this.store.state.showAdmin

    this.SkillLevels = this.store.state.info.SkillLevels
  },
}
</script>

<style>
.desc {
  white-space: pre-line;
}
.text-col {
  min-width: 150px;
}

.learn-hr {
  border: none;
  border-top: 1px solid gray; /* #00BCD4; */
  margin-bottom: 10px;
}
</style>
