<template>
  <div class="page">
    <div class="page-top">
      <h3 class="page-header" v-translate>Withdraw</h3>
    </div>

    <v-card>
      <v-card-text class="round-card" v-if="user">
        <div v-translate>
          Please click the button below to start the withdrawal
        </div>

        <span v-translate>For more instructions go to:</span> &nbsp;
        <a href="https://empleosdigitales.com/retiros/" target="_blank">empleosdigitales.com/retiros</a>

        <br>
        <br>
        <span v-translate>Minimum withdrawal:</span> &nbsp; ${{ store.config.WithdrawalMin }}<br>
        <span v-translate>Available balance:</span> &nbsp;
<!--        <span v-for="b in user.liquidBalance">{{ store.fmtBalance(b) }}</span>-->
<!--        {{ store.fmtAmount(user.liquidUSD, 'PUSDT') }}-->

        {{ store.fmtAmount(user.liquidUSDT || 0, 'USDT') }}
        <br>

        <div v-if="store.config.showAccountName" style="color:gray"><span v-translate>Account name:</span> {{user.accountName}}</div>


<!--        <div v-if="user.withdrawal" ><span v-translate>Pending withdrawal:</span> {{ user.withdrawal.amount }}</div>-->
        <br><br>

        <v-btn class="primary" @click="showWithdraw=true;error=null" ><translate>Withdraw</translate></v-btn>
      </v-card-text>
    </v-card>


    <v-dialog v-model="showWithdraw" max-width="500px">
      <v-card>
        <v-card-title v-translate>
          Withdraw
        </v-card-title>
        <v-card-text>
          <span style="margin-right:10px;">$</span>
<!--          <span style="margin-right:10px;">{{ token ? store.getSymbol(token.symbol) : '' }}</span>-->
          <v-text-field v-model="amount" type="number" @change="error=''" :label="$gettext('Withdraw Amount')" class="input-amount"></v-text-field>
<!--          <v-text-field v-if="askSymbol" v-model="symbol" type="text" @change="error=''" :label="$gettext('Symbol')"></v-text-field>-->
          <v-checkbox v-model="withdrawTandC" :label="$gettext('I accept the Terms and Conditions')"></v-checkbox>

          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="secondary"  @click="showWithdraw = false"><translate>
            Close
          </translate></v-btn>
          <v-btn class="primary"  :disabled="!withdrawTandC" @click="withdraw(amount)"><translate>
            Withdraw
          </translate></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<div v-if="0" v-translate>Withdraw to Exchange</div>
  </div>

</template>

<script>
import _ from "lodash";

export default {
  name: 'Withdraw',
  data: () => ({
    error: '',
    amount: '',
    symbol: '',
    token: null,
    showWithdraw: false,
    withdrawTandC: false,
    // askSymbol: false,
    user: null,
  }),
  methods: {
    async withdraw(a) {
      let amount = parseFloat(a)
      let amt = amount.toFixed(6) + ' ' + this.symbol   // will adjust for token later.
      if (! this.store.checkUSDBalance(amt))
        return this.error = this.$gettext("insufficient funds")

      if (amount < this.store.config.WithdrawalMin)
        return this.error = this.$gettext("minimum withdrawal amount:") + this.store.config.WithdrawalMin

      let tx = await this.store.withdrawalUSD(amount)
      if (!tx || !tx.transactionId) return this.error = tx || this.$gettext("Error sending")

      this.message = this.$gettext("withdrawal pending")

      this.$gtag.event('withdraw')

      this.user = await this.store.reloadUser(true)
      let txt = this.$gettext('Withdrawal completed')
      this.store.notifyUser([{ text: txt, id:0, type:'success' }])

      this.showWithdraw = false
    },
  },
  async beforeMount() {
    this.symbol = this.store.WithdrawSymbol
    if (! this.store.state.info || !this.store.state.info.tokens) {
      await this.store.introContent()
    }

    if (this.store.state.info) {
      this.token = _.find(this.store.state.info.tokens, {code: this.store.WithdrawSymbol})
    }

    // this.askSymbol = ! this.store.WithdrawSymbol
    this.user = this.store.state.user

    this.store.msg.on('user', u => this.user = u )


    if (document.location.href.match(/success/)) {
      this.user = await this.store.reloadUser(true)
      let txt = this.$gettext('Withdrawal completed')
      this.store.notifyUser([{ text: txt, id:0, type:'success' }])
      history.replaceState({ round:this.round }, 'Withdrawal', '/withdraw')
    }
    else if (document.location.href.match(/error_message=/)) {
      let m = document.location.href.match(/error_message=(.*?)(&|$)/)
      console.log('error', this.error, m? decodeURIComponent(m[1]) : '-')
      this.error = 'error'
      if (m && m[1]) {
        let s = decodeURIComponent(m[1])
        this.error = s.replace(/(\[|\{).*$/, '')

        let m2 = s.match(/assertion failure with message:(.*?)"/)
        if (m2 && m2[1]) this.error += m2[1]
        console.log('#err', m, s, m2)
      }
      if (this.error == 'undefined') this.error = ''
      if (this.error) this.showWithdraw = true

      history.replaceState({ }, 'Withdraw', '/withdraw')
    }
  },
  beforeDestroy() {
    this.store.msg.off('user')
  }
}

</script>

<style>
.input-amount {
  display: inline-flex;
}
</style>
