<template>
  <div class="page">

    <h3 v-translate>Verify your Phone</h3>
    <br>

    <v-card>
      <v-card-text>

    <div v-translate="{username: user ? user.username : ''}">Hi %{username}, we sent you a message, please enter the code here.</div>
    <br>

    <v-form @submit="save">
      <v-row>
        <v-col class="col-md-4 col-sm-6">
          <v-text-field v-model="code" outlined :label="$gettext('Code')" type="number" :rules="[store.rules.required]" ></v-text-field>

          <v-btn class="primary" :disabled="code===''" @click="save" type="submit">
            <translate>Continue</translate></v-btn>

          <v-btn  @click="resend" style="margin:10px;"><translate>Re-Send</translate></v-btn>

          <v-alert dense prominent type="error" transition="v-slide-y-transition" v-if="error">{{error}}</v-alert>

        </v-col>
      </v-row>
    </v-form>

      </v-card-text>
    </v-card>

  </div>
</template>


<script>

export default {
  name: 'VerifyPhone',
  components: {
  },
  data: () => ({
    user: null,
    code: '',
    error: '',
  }),
  methods: {
    async save(e) {
      this.error = ''
      console.log('verify', this.user, this.code)
      e.preventDefault()

      let res = await this.store.verifyCode(this.code)
      if (res) this.$router.replace(this.user.welcomePage || this.store.config.welcomePage).catch(e => console.log(e))
      else {
        this.error = this.$gettext('invalid or expired code')
        this.code = ''
      }
      const dummy = this.$gettext('Welcome! Your auth code is %s.')
    },
    async resend() {
      this.error = ''
      this.store.resendCode()
    }
  },
  beforeMount() {
    this.user = this.store.state.user
    if (!this.user) this.$router.replace('/').catch(e => console.log(e))
  }
}
</script>
