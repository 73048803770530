<template>
  <v-container>
    <h3 class="page-header" v-translate>About</h3>
    <br>
    <v-card >
      <v-card-text>

        <div class="text-pre-line" v-html="aboutText"></div>

<!--
        <div>
          <h4>
          Bienvenidos al portal de Empleos Digitales!
          </h4>
          <br>
          <p>
          Empleos Digitales es una plataforma donde conectamos personas de buena actitud con tareas digitales.
          </p>
          <p>
          NUNCA TENDRAS QUE PAGAR POR PARTICIPAR EN LAS TAREAS.
          </p>

          <p>
          Nuestros clientes son empresas estadounidenses y europeas que necesitan personas con buena actitud para conseguir realizar una tarea basica. Esto permite a nuestros usuarios como tu, ser remunerado por hacer tareas digitales sencillas.
          </p>

          <p>
          IMPORTANTE:
          </p>

          <ul>
          ✅ Puedes trabajar 1, 2, 4 u 8 horas al día. Tú decides cuántas horas y en qué horario.<br><br>

          ✅ Estas oportunidades laborales no tienen ninguna relación con ventas<br><br>

          ✅ No es un call center<br><br>

          ✅ No es un negocio multinivel<br><br>

          ✅ No tienes que tratar con clientes. Son solo tú y tu computadora<br><br>
          </ul>

          <p>
          LAS OPORTUNIDADES SON COMO LOS AMANECERES, SI UNO ESPERA DEMASIADO SE LOS PIERDE. - William Arthur Ward
          </p>

          <p>
          Puedse ver los terminos y condiciones aqui:
          <a href="http://empleosdigitales.com/terms.html">empleosdigitales.com/terms.html</a>
          </p>
        </div>
-->

      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>

import {QrcodeStream} from "vue-qrcode-reader";
import AccountHistory from "@/views/AccountHistory";
import Vue from "vue";

export default {
  name: 'About',
  data: () => ({
    aboutText: '',
  }),
  methods: {
    langChanged() {
      this.aboutText = require(`raw-loader!../assets/help/about-${this.$language.current}.html`).default
    }
  },
  beforeMount() {

    this.langChanged()
    // Vue.nextTick(() => document.getElementById('help-' + t).scrollIntoView() )

    this.store.msg.on('lang', this.langChanged)
  }
}
</script>