<template>
  <div class="page">
    <div v-if="store.isAdmin()" class="admin-btn">
      <v-select :items="SkillLevels"  label="admin: group/skills" dense outlined
                :item-text="i => i.id + '. ' + store.skillLevelName(i.id)" item-value="id"
                v-model="skillLevel" @change="load(1)" style="width:300px;"></v-select>

      <v-btn v-model="showAdmin" @click="showAdmin=!showAdmin; toggleAdmin()" class="toggle-btn"><v-icon >mdi-pencil</v-icon></v-btn>
      <v-btn v-model="showActive" @click="showActive=!showActive; toggleAdmin()" class="toggle-btn">active</v-btn>
      <v-btn class="primary" @click="newTask()">new</v-btn>
    </div>


    <v-container v-if="id && earn" >
      <v-card>   <!-- earn-details card -->
        <v-card-title>
          <h4>{{earn.title}}</h4>

          <span style="position:absolute; right:10px;">
            <v-btn class="secondary" @click="$router.back()"><translate>Close</translate></v-btn>
          </span>
        </v-card-title>

        <v-card-text :key="'card'+ii">
          <span v-if="showAdmin"><v-btn @click="edit(earn)">edit: {{earn.code}}</v-btn></span>
          <div style="color:gray" v-if="showAdmin">
            active:{{earn.status?'Y':'N'}}
          </div>

          <div class="desc" v-html="store.toHtml(earn.intro)"></div>
          <br>

          <v-row>
          <v-col>
            <div class="earn-label" v-translate>Amount:</div>
            <span class="amount primary--text">{{ store.getSymbol(earn.token) }}{{earn.amount}}</span>
          </v-col>
          <v-col>
            <div class="earn-label" v-translate>Complete:</div>
            <v-icon v-if="earnCnt(earn.code)" class="primary--text">mdi-check</v-icon>
            <v-icon v-else-if="didJoin(earn.code)" class="primary--text">mdi-close</v-icon>
            <v-icon v-else class="secondary--text">mdi-close</v-icon>

            <!--            <v-icon v-if="earnCnt(earn.code)" style="color:green">far fa-check-circle</v-icon>-->
<!--            <v-icon v-else :style="{'color': (didJoin(earn.code) ? 'green':'red')}">far fa-circle</v-icon>-->
            <span v-if="earnCnt(earn.code) > 1 || earn.maxPerUser > 1" >
              ({{ earnCnt(earn.code) }} {{ earn.maxPerUser ? ' / ' + earn.maxPerUser : '' }})
            </span>
          </v-col>

            <v-col v-if="earn.userSubmit && numSubmit(earn.code) ">
              <div  class="earn-label" v-translate>Pending</div>
              <v-icon style="color:grey">far fa-circle</v-icon>
              {{ numSubmit(earn.code) }}
            </v-col>

            <v-col v-if="earn.userSubmit && numAccepted(earn.code)">
              <div  class="earn-label" v-translate>Accepted</div>
              <v-icon style="color:lightgreen">fa-check-circle</v-icon>
              {{ numAccepted(earn.code) }}
            </v-col>

            <v-col v-if="earn.userSubmit && numRejected(earn.code)">
              <div  class="earn-label" v-translate>Rejected</div>
              <v-icon style="color:red">fa-times-circle</v-icon>
              {{ numRejected(earn.code) }}
            </v-col>

          </v-row>

          <br>
          <h4 v-translate>Description</h4>
          <div class="desc" v-html="store.toHtml(earn.description)"></div>

          <br>
          <div>
            <video-embed :video-id="earn.videoId" />
          </div>
          <br/>

<!--          <div v-if="earnCnt(earn.code) == 0">-->
<!--            <v-checkbox :disabled="! canJoin(earn.code)" v-model="join"-->
<!--                        @click="setJoinStatus(earn.code, join)"-->
<!--                        :label="$gettext('Join')"></v-checkbox>-->
<!--          </div>-->


          <div v-if="!didJoin(earn.code) && canJoin(earn)">
            <v-btn class="primary" @click="setJoinStatus(earn.code, true)" v-translate>Register</v-btn>
          </div>
          <span v-if="showAdmin"><v-btn @click="edit(earn)">edit: {{earn.code}}</v-btn></span>

          <v-alert dense prominent type="error" v-if="error">{{ error }}</v-alert>
          <div v-if="0" v-translate>task is full</div>
          <div v-if="0" v-translate>total max reached</div>
          <div v-if="0" v-translate>max per user reached</div>

          <div v-if="didJoin(earn.code)" >
            <v-icon class="primary--text">mdi-check</v-icon> <b v-translate>Registered</b>

            <div v-if="earnCnt(earn.code) == 0 && numEntries(earn.code) == 0">
              <v-btn @click="setJoinStatus(earn.code, false)" v-translate>Cancel</v-btn>
            </div>
            <br/><br/>

            {{ store.toHtml(earn.details) }}

            <div>
              <span v-translate>campaign code:</span>
              <span class="primary--text"> {{earn.code}}</span></div>
            <br>
          </div>

          <div v-if="didJoin(earn.code) && earn.userSubmit">
            <h3 v-if="earn.userSubmit.title">{{ earn.userSubmit.title }}</h3>

            <div v-if="earn.userSubmit.description" style="white-space: pre-line"
                 v-html="store.toHtml(earn.userSubmit.description)" />
            <br/>

            <div :key="'entries'+ earn.code + ii" v-if="numEntries(earn.code) > 0">
              <h3><translate>Your Submissions</translate>
                <span v-if="earn.maxPerUser" style="font-size:12px;font-weight:normal;">
                  ({{ numEntries(earn.code) }} / {{ earn.maxPerUser }})

                  <span v-translate>Votes needed:</span>
                  {{ earn.userSubmit.minRequired}} / {{earn.userSubmit.numValidators}}
                </span>
              </h3>

              <div class="entry-outside" v-if="showEntry" @click="showEntry=null">
                <div class="entry-overlay">
                  <img :src="store.userEntryImage(showEntry)" :alt="showEntry.imageName" class="img-overlay" />
                </div>
              </div>

              <div v-for="e of userEntries[earn.code]" :key="'e' + e.id" class="user-entries">
                <span v-if="haveField(earn, 'text')">
                  <div class="e-label">{{ earn.userSubmit.text }}</div>
                  <div class="primary--text"><b>{{ e.text }}</b></div>
                </span>
                <span v-if="haveField(earn, 'textBox')" class="text-box">
                  <div class="e-label">{{ earn.userSubmit.textBox }}</div>
                  <div class="primary--text">{{ e.textBox }}</div>
                </span>
                <span v-if="haveField(earn, 'social')" >
                  <div class="e-label">{{ earn.userSubmit.social }}</div>
                  <div class="primary--text">{{ e.social }}</div>
                </span>
                <span v-if="haveField(earn, 'videoUrl')" >
                  <div class="e-label">{{ earn.userSubmit.videoUrl }}</div>
                  <a :href="e.videoUrl" v-if="e.videoUrl" target="_blank" class="text-link primary--text e-image-thumb">
                    {{ e.video && e.video.title ? e.video.title : store.shortVideoUrl(e.videoUrl) }}

                    <span v-if="e.video && e.video.thumbnail_url" class="e-image-thumb">
                      <v-img :src="e.video.thumbnail_url" :alt="e.video.title" class="video-thumb" />
                    </span>
                  </a>
                </span>

                <span v-if="haveField(earn, 'image') && e.imageFile" class="e-image-thumb">
                  <div class="e-label">{{ earn.userSubmit.image }}</div>
                  <v-img :src="store.userEntryImage(e)" :alt="e.imageName" @click="showEntry=e" class="image-thumb" />
                </span>

                <span class="e-status">
                  <div class="e-label text-label" v-translate>Status</div>

                  <v-icon v-if="e.status=='pending'" style="color:grey">far fa-circle</v-icon>
                  <v-icon v-else-if="e.status=='new'" style="color:black">far fa-circle</v-icon>
                  <v-icon v-else-if="e.status=='approved'" style="color:lightgreen">fa-check-circle</v-icon>
                  <v-icon v-else-if="e.status=='approved-paid'" style="color:green">fa-check-circle</v-icon>
                  <v-icon v-else-if="e.status=='rejected'" style="color:red">fa-times-circle</v-icon>
                  <span v-else>{{ e.status }}</span>
                </span>

                <span v-if="showVotes" style="display:inline-block">
                  <div class="e-label text-label" v-translate>Votes</div>

                  <span v-for="i of verifiedList(e)">
                    <v-icon v-if="e.verified && e.verified[i] && e.verified[i].status == 'accept'" style="color:lightgreen">far fa-check-circle</v-icon>
                    <v-icon v-else-if="e.verified && e.verified[i] && e.verified[i].status == 'reject'" style="color:red">far fa-times-circle</v-icon>
                    <v-icon v-else style="color:grey">far fa-circle</v-icon>

                    <span v-if="e.verified && e.verified[i]" style="padding:5px">{{ e.verified[i].comment }}</span>
                  </span>
                </span>

<!--                <span v-if="e.comments">-->
<!--                  <span v-for="c of e.comments" style="white-space: pre-line">{{ c }}</span>-->
<!--                </span>-->

                <span v-if="e.status=='new'" style="display:inline;">
                  <v-icon @click="delEntry(e.id)" class="clk" style="color:gray">fa-times</v-icon>
                  <v-icon @click="editEntry(e)" class="clk"   style="margin-left:20px;color:gray">mdi-pencil</v-icon>
                </span>
              </div>
            </div>

            <div>
              <v-btn class="primary" :disabled="! canSubmit(earn)" @click="add={}; showAdd=true" v-translate>New Submission</v-btn>

              <span v-if="earn.userSubmit && earn.userSubmit.end">
                <span v-translate>Open Until:</span>
                  {{ store.fmtDateTime(earn.userSubmit.end) }}
              </span>

              <v-icon @click="detail(earn, 1)" style="margin-left:15px; color:grey" class="clk">mdi-refresh</v-icon>

              <v-btn class="secondary" @click="$router.back()"><translate>Close</translate></v-btn>
            </div>


            <v-dialog v-model="showAdd">
              <v-card>
                <v-card-title>
                  <b v-if="add && add.id" v-translate>Edit Submission</b>
                  <b v-else v-translate>New Submission</b>
                  <span @click="showAdd=false" class="clk" style="margin-left:15px;"><v-icon style="color:gray">fa-times</v-icon></span>
                </v-card-title>

                <v-card-text>
                  <v-form v-if="add" ref="form" lazy-validation >
                    <div v-if="0" v-translate>Required</div>

                    <v-text-field v-model="add.text" outlined dense :label="earn.userSubmit.text"
                                  :rules="[submitRules.required]" v-if="haveField(earn, 'text')" />
                    <v-textarea   v-model="add.textBox" outlined dense :label="earn.userSubmit.textBox"
                                  :rules="[submitRules.required]" v-if="haveField(earn, 'textBox')" />

                    <span v-if="add.imageName" ><v-icon>fa-file</v-icon>
                      {{ add.imageName }}
                      <v-btn icon @click="add.imageName=null" ><v-icon style="color:gray">fa-times</v-icon></v-btn>
                    </span>
                    <v-file-input v-model="add.image" chips dense :label="earn.userSubmit.image"
                                  :rules="[submitRules.required, submitRules.imgSize]"
                                  accept="image/png, image/jpeg, image/gif"
                                  v-else-if="haveField(earn, 'image')" />
                    <v-text-field v-model="add.videoUrl" outlined dense :label="earn.userSubmit.videoUrl"
                                  :rules="[submitRules.required, submitRules.videoUrl]"
                      :error-messages="videoUrlError"
                                  v-if="haveField(earn, 'videoUrl')" />
                    <div v-if="0" v-translate>Video not found</div>
                    <v-text-field v-model="add.social" outlined dense :label="earn.userSubmit.social"
                                  :rules="[submitRules.required, submitRules.socialHandle]" v-if="haveField(earn, 'social')" />

                    <v-btn class="primary" @click="submitEntry(add)" :disabled="! canSubmit(earn)">
                      <translate v-if="add.id">Save</translate>
                      <translate v-else>Submit</translate>
                    </v-btn>
    <!--                <v-btn icon @click="showAdd=false" ><v-icon style="color:gray">fa-times</v-icon></v-btn>-->
                      <v-btn @click="showAdd=false" v-translate>Close</v-btn>

                    <pulse-loader :loading="store.state.loading" color="#7131FF" :key="'loadingx'+ii"></pulse-loader>
                  </v-form>
                </v-card-text>

              </v-card>
            </v-dialog>

          </div>
          <div v-else>
            <v-btn class="secondary" @click="$router.back()"><translate>Close</translate></v-btn>
          </div>

<!--          <v-btn class="secondary" @click="$router.back()"><translate>Close</translate></v-btn>-->
        </v-card-text>
      </v-card>
    </v-container>


    <v-container v-else>
      <div class="page-top">
      <h3 class="page-header" v-translate>Complete Tasks</h3>
      <div class="page-subhead" v-translate="{step:2}">Step %{step} of 3</div>

      <div class="intro-text" v-if="!store.state.loading && store.config.showLevels" v-translate="{nextLevel:level + 1}">You are Level %{ level } -- you must complete %{ PerLevel } level %{ level } offers to unlock level %{nextLevel}.</div>
      </div>

      <br>

<!--      <div v-if="store.state.loading">loading..</div>-->
      <div v-if="!store.state.loading && data && data.length == 0 ">
        <h3 v-translate>Sorry there are no offers available at the moment</h3>
      </div>

      <div v-for="dd,l in levels" :key="'level'+l+skillLevel">
<!--        <div v-if="store.config.showLevels"><span :key="'levelh'+l" v-translate="{lev: l}">Offers: Level %{ lev }</span>&nbsp;-->
<!--          <v-icon v-if="l==1 || levelsCompleted[l] >= PerLevel" style="color:green">far fa-check-circle</v-icon>-->
<!--          <span v-else >-->
<!--            <v-icon style="color:red">far fa-circle</v-icon>-->
<!--            <div class="level-text" v-translate="{num: (PerLevel - (levelsCompleted[ l - 1 ] || 0)), lev:l }">-->
<!--              Complete %{ num } more offers to unlock level %{ lev }-->
<!--            </div> <br/>-->
<!--          </span>-->
<!--        </div>-->

        <v-card v-for="d in dd" :key="'r'+d.id" class="earn-entry">
          <v-card-title>
            <h4>{{d.title}}</h4>

            <v-col v-if="showAdmin" class="info-box">
              <span>registered:</span> {{ d.usersJoined || '-'}} / {{ d.maxUsers || '-' }}
              <span>completed:</span> {{ d.totalCompleted || '-' }} / {{ d.numAvailable || '-' }}
              <span v-if="d.totalCompleted != d.usersCompleted">({{ d.usersCompleted}} users)</span>
            </v-col>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col class="col-12 col-sm-6 col-md-8">
                <div class="desc" v-html="store.toHtml(d.intro || d.description)" ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="d.amount">
                <div class="earn-label" v-translate>Amount:</div>
                <div class="primary--text">{{ store.getSymbol(d.token) }}{{d.amount}}</div>
              </v-col>

              <v-col>
                <div>
                  <div class="earn-label" v-translate>Complete:</div>
                  <v-icon v-if="earnCnt(d.code)" class="primary--text">mdi-check</v-icon>
                  <v-icon v-else-if="didJoin(d.code)" class="primary--text">mdi-close</v-icon>
                  <v-icon v-else class="secondary--text">mdi-close</v-icon>

    <!--              <v-icon v-if="earnCnt(d.code)" style="color:green">far fa-check-circle</v-icon>-->
    <!--              <v-icon v-else :style="{'color': (didJoin(d.code) ? 'green':'red')}">far fa-circle</v-icon>-->
                  <span v-if="earnCnt(d.code) > 1" > ({{ earnCnt(d.code) }})</span>
                </div>
              </v-col>

              <v-col v-if="d.userSubmit && numSubmit(d.code)">
                <div  class="earn-label" v-translate>Pending</div>
                <v-icon style="color:grey">far fa-circle</v-icon>
                {{ numSubmit(d.code) }}
              </v-col>

              <v-col v-if="d.userSubmit && numAccepted(d.code)">
                <div  class="earn-label" v-translate>Accepted</div>
                <v-icon style="color:lightgreen">fa-check-circle</v-icon>
                {{ numAccepted(d.code) }}
              </v-col>

              <v-col v-if="d.userSubmit && numRejected(d.code)">
                <div  class="earn-label" v-translate>Rejected</div>
                <v-icon style="color:red">fa-times-circle</v-icon>
                {{ numRejected(d.code) }}
              </v-col>

              <v-col>
                <v-btn @click="detail(d)" v-if="l <= level || showAdmin" color="primary"><translate>Details</translate></v-btn>
                <v-btn @click="edit(d)" v-if="showAdmin">edit #{{d.id}}: {{d.code}}</v-btn>
              </v-col>
            </v-row>

            <v-row  v-if="d.videoId">
                <v-col xstyle="padding-left:20px;min-width:200px;">
                  <video-embed :video-id="d.videoId" />
                </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <!--        <hr class="earn-hr"/>-->
      </div>


      <div v-if="store.isAdmin()" >
        <v-btn v-if="showAdmin" class="error" @click="newTask">new</v-btn>
      </div>

      <br>
      <v-btn @click="$router.push('/giveaway')" color="primary" class="next-page-btn"><translate>Next</translate></v-btn>
      &nbsp;
      <v-btn v-if="haveCompletedTasks" @click="toggleAvailOnly()" v-model="availOnly" class="toggle-btn">
        <span v-if="availOnly" key="show-completed" v-translate>Show Completed Tasks</span>
        <span v-else key="show-avail" v-translate>Show Available Tasks</span>
      </v-btn>
    </v-container>
  </div>
</template>


<script>
import _ from 'lodash'
import VideoEmbed from "@/components/VideoEmbed";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: 'Earn',
  components: {
    VideoEmbed,
    PulseLoader
  },
  props: {
    id: null,
    _skillLevel: 0,
  },
  data: () => ({
    error: '',
    adminBtn: [false, true],
    showAdmin: false,
    showActive: true,
    availOnly: true,
    haveCompletedTasks: false,

    data: [],
    earnStatus: {},
    // joinStatus: {},
    levelsCompleted: {},
    level: 1,
    PerLevel:3,

    skillLevel: 1,
    SkillLevels: [],
    earn: null,
    join: false,

    add: null,
    showAdd: false,
    userEntries: {},
    showEntry: null,
    videoUrlError: null,

    showVotes: 1,

    submitRules: {
      // required: value => !!value || this.$gettext('Required'),
      // imgSize:  value => !value || value.size < 5000000 || 'image size should be less than 5 MB!',
      // imgSizes: values => !values || !values.length || !values[0] || values[0].size < 5000000 || 'image size should be less than 5 MB!',
      // videoUrl: value => !value || !!value.match(/^https?:\/\/[.\-\w]+\.[:.\w]+\/.*/) || 'please check the url',
      // socialHandle: value => !value || !!value.match(/^\@[\w\-]+$/) || 'please check the handle',
    },
    ii: 1,
  }),
  methods: {
    edit(e) {
      // edit id, or new one with -1 * skill
      this.$router.push('/earn-edit/' + (e.id || -1 * e.skillLevels[0]))
    },
    newTask() {
      this.$router.push('/earn-edit/' + (-1 * this.skillLevel))
    },
    async load(upd) {
      const data = await this.store.content(this.store.isAdmin(), this.skillLevel, true)
      if (!data) return console.log("error loading data")
      if (this.store.isAdmin()) {
        this.data = _.filter(data.earn || [], {status: this.showActive ? 1 : 0})
      } else {
        this.data = _.filter(data.earn || [], e => {
            console.log('*', e.code, this.earnStatus[e.code] ? this.earnStatus[e.code].cnt : '-', e.maxPerUser)
            return this.availOnly == (this.earnStatus[e.code] ? this.earnStatus[e.code].cnt < e.maxPerUser : true) })
      }
      this.haveCompletedTasks = this.data.length != data.earn.length && !this.store.isAdmin()
      console.log('loaded:', this.showActive, this.haveCompletedTasks, this.availOnly, this.data.length, data.earn ? data.earn.length : '-', this.earnStatus)

      for (let d of data.earn) {
        if (d.userSubmit)
          this.userEntries[d.code] = await this.store.loadUserEntries(d.code)
      }
      console.log("userEntries", this.userEntries)

      if (upd) this.$router.push('/earn/' + this.skillLevel + '/0')

      this.checkLevels()
    },
    checkLevels() {  // if config.showLevels is set. obsolete?
      this.levelsCompleted = {}
      _.each(this.earnStatus, (s, k) => {
        // console.log('es', k, s)
        let d = _.find(this.data, {code: k})
        if (s.cnt > 0 && d) {  // was s.status , but cnt>0 should be fine.
          let num = (this.levelsCompleted[ d.level ] || 0) + 1
          this.levelsCompleted[ d.level ] = num

          if (num >= this.PerLevel) this.level = d.level
        }
      })
      console.log('completed levels: ', this.levelsCompleted)
    },
    earnCnt(code) {
      return this.earnStatus && this.earnStatus[code] ? this.earnStatus[code].cnt : 0
    },
    didJoin(code) {
      // console.log('#', code, this.join, this.earnStatus[code] && this.earnStatus[code].join, this.earnStatus[code] && this.earnStatus[code].cnt)
      return this.earnStatus[code] ? this.earnStatus[code].join || this.earnStatus[code].cnt > 0 : false
    },
    canSubmit(earn) {
      return this.didJoin(earn.code) && earn.userSubmit &&
          (!earn.maxPerUser || !this.userEntries[earn.code] || earn.maxPerUser > this.numEntries(earn.code))
          && (!earn.userSubmit.end || new Date(earn.userSubmit.end) > new Date())
    },
    haveField(earn, field) {
      return earn && earn.userSubmit && earn.userSubmit.fields && earn.userSubmit.fields.indexOf(field) !== -1
    },
    shortUrl(url) {
      let m = url.match(/\/([^\/]+)$/)
      return m && m[1] ? m[1] : url
    },

    async submitEntry(add) {
      const code = this.earn.code
      // if (! this.store.state.user.entries) this.store.state.user.entries = {}
      // if (! this.store.state.user.entries[code]) this.store.state.user.entries[code] = []
      // let entries = this.store.state.user.entries[code]

      if (! this.$refs.form.validate()) return

      // && add.videoUrl.match(/tiktok/)
      if (add.videoUrl) {
        let ok = await this.store.loadVideoInfo(add)
        console.log("vid.", ok)
        this.videoUrlError = null
        if (ok && ok !== true) {
          this.videoUrlError = this.$gettext(ok)
          return
        }
      }


      if (!add.id) add.id = this.store.mkToken()
      // if (! _.find(entries, {id: add.id})) entries.push(add)
      // if (!add.status) add.status = 'new'

      if (! this.userEntries[code]) this.userEntries[code] = []
      let entries = this.userEntries[code]
      console.log('submit', code, add, entries)

      let res = await this.store.saveUserEntry(code, add)
      console.log('saved', add, res)

      if (res) {
        let ix = _.findIndex(entries, {id: add.id})
        if (ix == -1) entries.push(res)
        else entries[ix] = res

        this.add = null
        this.showAdd = false
      }
      this.ii++
    },


    editEntry(e) {
      this.add = _.cloneDeep(e)
      this.showAdd = true
    },
    async delEntry(id) {
      if (! confirm( this.$gettext('Delete submission?'))) return
      const entries = this.userEntries[this.earn.code]
      const ix = _.findIndex(entries, { id: id })
      console.log('del', this.earn.code, id, ix)
      let e = entries[ix]
      await this.store.userEntryStatus(e.code, e.id, 'deleted')

      if (ix !== -1) entries.splice(ix, 1)
      this.ii++
    },

    toggleAdmin() {
      this.store.state.showAdmin = this.showAdmin
      this.load()
    },
    toggleAvailOnly() {
      this.availOnly = ! this.availOnly
      this.load()
    },
    async setJoinStatus(code, join) {
      console.log('set join', code, join)
      this.error = ''
      if (! this.earnStatus[code]) this.earnStatus[code] = { id:code, join:false, cnt:0 }

      // this.earn.join = join
      let r = await this.store.userJoinStatus(code, join)
      console.log('res', code, join, this.join,  r && r.data && r.data.status)

      if (r && r.data && r.data.status == 'ok')
        this.join = this.earnStatus[code].join = join
      else {
        this.join = this.earnStatus[code].join = false
        this.error = (r && r.data && r.data.error) || this.$gettext("Problem joining task")
      }
      this.ii++

      if (join) this.$gtag.event('earn-join', code)
    },
    canJoin(d) {
      return true;
    },
    numEntries(code) {
      // userEntries[d.code].length
      // console.log('e?',code, _.filter(this.userEntries[code], e => e.status != 'rejected'))

      return _.filter(this.userEntries[code], e => e.status != 'rejected').length
    },
    numSubmit(code) {
      return _.filter(this.userEntries[code], e => e.status == 'new' || e.status == 'pending').length
    },
    numAccepted(code) {
      return _.filter(this.userEntries[code], e => e.status == 'approved' || e.status == 'approved-paid').length
    },
    numRejected(code) {
      return _.filter(this.userEntries[code], e => e.status == 'rejected').length
    },
    verifiedList(e) {
      return _.range(0, Math.max(this.earn.userSubmit.numValidators, e.verified ? e.verified.length : 0))
    },

    async detail(d, force) {
      this.$router.push('/earn/' + d.skillLevels[0] + '/' + d.id)
      this.error = null
      this.earn = d
      this.join = this.didJoin(d.code)

      console.log('detail', d, d.id, this.join)

      if (d.userSubmit && (force || ! this.userEntries[d.code]))
        this.userEntries[d.code] = await this.store.loadUserEntries(d.code)
      this.ii++

      this.$gtag.pageview('/earn-detail/' + d.id)
    },

  },
  async beforeMount() {
    // this.showActive = true

    await this.store.reloadUser()
    if (!this.store.state.info || !this.store.state.info.tokens)
      await this.store.introContent()

    await this.load()

    if (this.store.state.user) {
      this.earnStatus = this.store.state.user.earnStatus || {}
      // this.joinStatus = this.store.state.user.joinStatus || {}
      this.skillLevel = this.store.state.user.skillLevels[0] || 1
    }
    this.showAdmin = this.store.state.showAdmin

    this.SkillLevels = this.store.state.info.SkillLevels
    if (this._skillLevel) this.skillLevel = this._skillLevel

    console.log('e', this.earnStatus, this.store.state.user, this.id, this.SkillLevels)
    if (this.id) {
      this.earn = _.find(this.data, {id: this.id})
      this.detail(this.earn)
      // console.log('?', this.id, this.earn, this.data)
    }

    this.store.msg.on('user', (u) => {
      this.earnStatus = u ? u.earnStatus : {}
      // this.joinStatus = u ? u.joinStatus : {}
      if (u) this.skillLevel = u.skillLevels[0]
    })

    this.submitRules = {
      required: value => !!value || this.$gettext('Required'),
      imgSize:  value => !value || value.size < 5000000 || this.$gettext('Image size should be less than 5 MB!'),
      // imgSizes: values => !values || !values.length || !values[0] || values[0].size < 5000000 || this.$gettext('Image size should be less than 5 MB!'),
      videoUrl: value => !value || !!value.match(/^https?:\/\/[.\-\w]+\.[:.\w]+\/.*/) || this.$gettext('Please check the link'),
      socialHandle: value => !value || !!value.match(/^@[\w-]+$/) || this.$gettext('Please check the social media handle'),
    }
  },

  beforeDestroy() {
    this.store.msg.off('user')
  },

  computed: {
    levels() {
      let levels = {}
      this.data.forEach(d => {
        if (! levels[d.level]) levels[d.level] = []
        levels[ d.level ].push(d)
      })
      return levels
    }
  }
}
</script>


<style>
.desc {
  white-space: pre-line;
}
.header-row {
  color: gray;
}
.admin-btn {
  position: fixed;
  right: 10px;
  border: 1px solid lightgrey;
  background-color: lightcyan;
  opacity: 0.8;
  border-radius: 5px;
  padding: 5px;
  z-index: 100;
}
.level-text {
  font-weight:normal;
  font-size:16px;
  color: gray;
  /*padding-left:10px;*/
}

.grecaptcha-badge { visibility: hidden }

.intro-text {
  color:green;
}

.amount {
  font-size: 22px;
}
.earn-label {
  color: gray;
  padding-right: 10px;
}
.earn-hr {
  border: none;
  border-top: 1px solid gray; /* #00BCD4; */
  margin: 10px 0 5px 0;
}
.earn-entry {
  /*border-bottom: 1px solid gray;*/
  /*margin-bottom: 20px;*/
}

.info-box {
  text-align: right;
  font-weight: normal;
  white-space: nowrap;

  border: 1px solid lightgrey;
  background-color: lightcyan;
  border-radius: 5px;
  padding: 5px;
}
.info-box > span {
  color: gray;
}

.user-entries {
  border-bottom: 1px solid lightgrey;
  padding: 10px 0px;
}
.user-entries > span {
  padding: 5px;
  display: inline-grid;

  min-width: 120px;
  overflow-wrap: anywhere;
  vertical-align: text-top;
  /*font-size: 14px;*/
}
.image-thumb {
  width:150px;
  max-height: 150px;
  border: 1px solid gray;
  border-radius: 4px;
  cursor:pointer;
}
.e-image-thumb {
  width: 160px;
}
.video-thumb {
  max-width: 100px;
  max-height: 300px;
  border: 1px solid gray;
  border-radius: 4px;
}
.e-status {
  min-width: 60px !important;
}
.text-box {
  white-space: pre-line;
  min-width: 200px !important;
}
.e-label {
  color: grey;
  /*font-size: 12px;*/
}

.text-link {
  cursor:pointer;
  /*color: #00bcd4;*/
  color: gray !important;
  text-decoration: none;
}
.text-link:hover {
  text-decoration: underline;
}

.entry-outside {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, .5);
  z-index: 100;
  cursor: pointer;
}
.entry-overlay {
  position: relative;
  top: 30px;
  left: 10px;
  /*border: 2px solid gray;*/
  /*border-radius: 10px;*/
}
.img-overlay {
  max-width: 95%;
  max-height: 90%;
}
</style>
